import { useState } from 'react';
import { Radio } from '@mui/material';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { MultipleAction } from '../../../@types/backgroundActions';
import { actionsDefault } from './data';
import { initialData } from '../data';
import { Procedure } from '../../../@types/model';
import { Submit } from '../Submit';

type MultipleActionsProps = {
  closeModal: () => void;
  procedure?: Procedure;
};

export function Default(props: MultipleActionsProps) {
  const [selected, setSelected] = useState<number>();
  const [selectedActions, setSelectedActions] = useState<MultipleAction[]>([]);
  const selectedItem = (option: number) => {
    setSelected(option);
    const selectedActionsTmp: MultipleAction[] = [];
    const actionSelected = actionsDefault.find((i) => i.index === option);

    actionSelected?.itens.map((item) => {
      const action = initialData?.find((a: MultipleAction) => a.id === item);
      if (action) {
        selectedActionsTmp.push(action);
      }
    });

    setSelectedActions(selectedActionsTmp);
  };

  const renderAction = (item: string, lastItem: boolean) => {
    const action = initialData?.find((a) => a.id === item);
    if (!action) return null;

    return (
      <>
        { action.icon }
        <div className={styles.label}>{ t(action?.label) }</div>
        { !lastItem && <span>{'>'}</span> }
      </>
    );
  };

  return (
    <div className={ styles.container }>
      <div className={styles.content} data-cy={'multiple-actions-default'}>
        { actionsDefault?.map((item) => (
          <div className={styles.item}>
            <Radio
              name={''}
              checked={selected === item.index}
              value={item.index}
              onChange={() => selectedItem(item.index)}
              className={styles.radio}
              data-cy={`radio-${item.index}`}
            />
            { item.itens.map((i, index) => renderAction(i, (item.itens.length - 1 === index)))}
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <Submit
          closeModal={props.closeModal}
          selectedActions={selectedActions}
        />
      </div>
    </div>
  );
}
