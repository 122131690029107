import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { RoundButton } from '../../../RoundButton';
import { OptionType } from '../../../../@types/config';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import Select from '../../../Select';
import styles from './styles.module.scss';

type CopyProps = {
  control: any;
  institutionOptions: any;
  setCopies: any;
};

type List = {
  id: string;
  fieldOptions: OptionType[];
  fieldId?: number;
};

export default function Copy(props: CopyProps) {
  const [list, setList] = useState<List[]>([]);

  const handleAddForm = () => {
    const id = (Math.random() + 1).toString(36).substring(1);
    setList([...list, { id, fieldOptions: [] }]);
  };

  const handleDeleteForm = (id: string) => {
    let newList = [...list];
    newList = newList.filter((el) => el.id !== id);
    setList(newList);
  };

  const handleAddInstitution = (id: string, institutionId: number) => {
    ProcedureBoxService.getSpuFields(institutionId)
      .then((res) => {
        if (res.json) {
          const options = res.json.map((option: any) => ({ label: option[0], value: option[1] }));
          setList(
            list.map((item) => (
              item.id === id ? { id, fieldOptions: options } : item
            )),
          );
        }
      });
  };

  const handleAddField = (id: string, fieldId: number) => {
    setList(
      list.map((item) => (
        item.id === id ? { ...item, fieldId } : item
      )),
    );
  };

  useEffect(() => {
    props.setCopies(list.filter((el) => el.fieldId !== undefined).map((item) => item.fieldId));
  }, [list]);

  return (
    <>
      {
        list.map((el) => (
          <div className={styles.listContainer}>
            <Grid item xs={1} sm={4} md={4}>
              <Select
                dataCy={'cypress-institution-select-copy-spu'}
                label={t('procedureBox.actions.spu.modal.form.institution')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.institutionPlaceholder')}
                options={props.institutionOptions}
                onChange={(event: any) => handleAddInstitution(el.id, event.value)}
                required
              />
            </Grid>
            <Grid item xs={1} sm={4} md={4}>
              <Select
                returnValue
                dataCy={'cypress-field-select-copy-spu'}
                label={t('procedureBox.actions.spu.modal.form.field')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.fieldPlaceholder')}
                options={el.fieldOptions}
                onChange={(event: any) => handleAddField(el.id, event)}
                required
              />
            </Grid>
            <Grid item xs={1} sm={4} md={4}>
            <RoundButton
              icon={<FaTrashAlt />}
              size='small'
              onClick={() => handleDeleteForm(el.id)}
            />
            </Grid>
          </div>
        ))
      }
      <RoundButton
        dataCy={'cypress-copy-spu-round-buttom'}
        icon={<FaPlus />}
        backgroundColor="var(--primary4)"
        size='small'
        onClick={() => handleAddForm()}
      />
    </>
  );
}
