import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { OrderSchedule } from '../../../@types/config';
import { Procedure, ProcedureDeadline } from '../../../@types/model';
import { Moment } from '../../../config/moment';
import { QueryContext } from '../../../context/queryContext';
import Format from '../../../helpers/format';
import { Tooltip } from '../../Tooltip';
import { truncate } from '../../../helpers/strings';
import { Deadline } from '../../Deadline';
import Screen from '../../../helpers/screen';
import styles from './styles.module.scss';
import Show from '../../Show';

type ExpandedProcedureListProps = {
  procedure: Procedure;
  isOpen?: boolean;
  expandend?: boolean;
  type: 'schedule' | 'data';
};

export function ExpandedProcedureList(props: ExpandedProcedureListProps) {
  const {
    orderSchedule,
    orderData,
    orderGeneral,
    box,
  } = useContext(QueryContext);

  const excludedDescriptions = orderGeneral.map((og) => og.description);

  const [hearings, setHearings] = useState<ProcedureDeadline[]>([]);

  const orderList = props.type === 'data' ? orderData : orderSchedule;

  const isMobile = Screen.isMobile();

  const renderDataExtra = () => {
    return (
      <div className={styles.dataExtra}>
        <div className={ styles.data }>
          <span className={ styles.label }>{t('procedureBox.procedureList.cdas_linked')}:</span>
          <span className={ styles.value }>{ 0 }</span>
        </div>
      </div>
    );
  };

  const competence = () => {
    if (props.procedure.competence?.length !== 0) {
      return props.procedure.competence;
    }
    return t('procedureBox.procedureList.no_competence');
  };

  useEffect(() => {
    if (props.procedure?.procedure_deadlines) {
      setHearings(props.procedure.procedure_deadlines
        .filter((deadline) => deadline.reason === 'Audiência'
          && (deadline.individual_id === null || deadline.individual_id === props.procedure.current_individual_id)));
    }
  }, []);

  const setDeadlineMostNext = () => {
    const crescentDate = hearings.sort((a, b) => new Date(`${a.deadline}`).getTime() - new Date(`${b.deadline}`).getTime());
    return (
      hearings.length !== 0 ? crescentDate.map((cd) => `${moment(cd.deadline).format('DD/MM/YYYY')}\n`)
        : t('procedureBox.procedureList.no_hearings_count')
    );
  };

  const renderData = (description: string) => {
    switch (description) {
      case 'last_product_generated':
        return (
          <Tooltip title={props.procedure?.last_product_generated?.length > 20 ? props.procedure.last_product_generated : ''}>
            <span>{truncate(props.procedure?.last_product_generated, 20)}</span>
          </Tooltip>
        );
      case 'subject':
        return (
          <Tooltip title={props.procedure?.subject?.length > 30 ? props.procedure.subject : ''}>
            <span>{truncate(props.procedure?.subject, 20)}</span>
          </Tooltip>
        );
      case 'procedure_value':
        return Format.currency(props.procedure?.procedure_value);
      case 'flux':
        return (
          <Tooltip title={props.procedure?.flux?.name?.length > 30 ? props.procedure.flux.name : ''}>
            <span>{truncate(props.procedure?.flux?.name, 20)}</span>
          </Tooltip>
        );
      case 'interested_parts':
        return (
          <Tooltip title={props.procedure?.interested_parts?.length > 0 ? `(${props.procedure.interested_parts.length}) ${props.procedure.interested_parts.join(', ')}` : ''}>
            <span>{truncate(props.procedure?.interested_parts.join(', '), 20)}</span>
          </Tooltip>
        );
      case 'origin_procedure_name':
        return (
          <Tooltip title={props.procedure?.origin_procedure_name?.length > 30 ? props.procedure?.origin_procedure_name : ''}>
            <span>{truncate(props.procedure?.origin_procedure_name, 20)}</span>
          </Tooltip>
        );
      case 'process_category_name':
        return (
          <Tooltip title={props.procedure?.process_category_name?.length > 0 ? props.procedure?.process_category_name : ''}>
            <span>{truncate(props.procedure?.process_category_name, 20)}</span>
          </Tooltip>
        );
      case 'hearings_count':
        return (
          <Tooltip
            title={setDeadlineMostNext()}
            classes={styles.hearingTootltip}
          >
            <span>{hearings.length}</span>
          </Tooltip>
        );
      case 'competence':
        return competence();
      default:
        return '';
    }
  };

  const requirimentDate = (description: string) => {
    if (props.procedure.requiriment_date) {
      return (
        Moment((props.procedure[description as keyof Procedure])?.toLocaleString()).format('DD/MM/YYYY')
      );
    }
    return t('procedureBox.procedureList.no_requiriment_date');
  };

  const renderSchedule = (description: string) => {
    switch (description) {
      case 'created_at':
        return Moment((props.procedure[description as keyof Procedure])?.toLocaleString()).format('DD/MM/YYYY');
      case 'received_date':
        return Moment((props.procedure[description as keyof Procedure])?.toLocaleString()).format('DD/MM/YYYY');
      case 'time_inbox':
        return Moment((props.procedure[description as keyof Procedure])?.toLocaleString()).fromNow();
      case 'inactive_time':
        return Moment((props.procedure[description as keyof Procedure])?.toLocaleString()).fromNow();
      case 'procedure_deadline':
        return <Deadline procedure={props.procedure} />;
      case 'requiriment_date':
        return requirimentDate(description);
      default:
        return '';
    }
  };

  const RenderLabel = (item: OrderSchedule, index: number) => {
    return isMobile
      ? <span className={ styles.label }>{ t(`procedureBox.procedureList.${item.description}`) }: </span>
      : (!isMobile && index !== 0) ? <span className={ styles.label }>{ t(`procedureBox.procedureList.${item.description}`) }: </span>
        : <></>;
  };

  return (
    <div className={ styles.container }>
      <div
        className={ `${styles.dragList} ${props.expandend ? styles.expanded : ''}` }
      >
        {orderList.map((item: OrderSchedule, index: number) => (
          <Show if={!(excludedDescriptions.includes(item.description))}>
            <div className={
              `${styles.data} ${index === 0 ? styles.first : !props.isOpen ? styles.closed : styles.open}
                ${props.isOpen ? styles.open : ''}`
              }>
              { item.description === 'procedure_deadline' ? <Deadline procedure={props.procedure} />
                : <div className={ styles.value }>
                { RenderLabel(item, index) }
                <span className={`${styles.dataValue} ${(!props.procedure.was_seen && index === 0) && (box !== 'outbox' && box !== 'archivedbox' ? styles.dataValueWasSeen : '')}`}>
                  { props.type === 'schedule' ? renderSchedule(item.description) : renderData(item.description) }
                </span>
              </div>
              }
            </div>
          </Show>
        ))}
        { props.type === 'data' && props.isOpen && renderDataExtra() }
      </div>
    </div>
  );
}
