import { useState, useContext } from 'react';
import { t } from 'i18next';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { DocumentList } from './DocumentList';
import { SelectedProcedures } from '../SelectedProcedures';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { AuthA1 } from '../SignerA1/AuthA1';
import { Procedure } from '../../@types/model';
import { Document } from '../../@types/config';
import { SignerParams } from '../../@types/a1';
import A1Service from '../../services/a1Service';

type CosignerProps = {
  multipleActions?: MultipleActionStep;
};

export function Cosigner(props: CosignerProps) {
  const {
    proceduresSeleted, removeProcedures, setModal, addToasts,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [documents, setDocuments] = useState<any[]>([]);
  const keyI18n = 'procedureBox.actions.cosigner';

  const handleConfirmLink = (
    docs: any,
    controlId: number,
    procedure: Procedure,
    documentsToProcedure: Document[],
  ) => {
    const body = {
      documents: docs,
      controlId,
    };
    const procedures = [procedure];
    const type = 'signerA1';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: documentsToProcedure.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    setModal(undefined);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
  };

  const handleSignerA1 = (password: string = '') => {
    proceduresSeleted.map((procedure) => {
      const documentsToProcedure = documents.filter((doc: Document) => doc.procedure_id === procedure.id);

      const result = documentsToProcedure.map((doc: Document) => {
        return {
          file_class: doc.class_name,
          file_id: doc.id,
          procedure_id: doc.procedure_id,
          control_code: (Math.random() + 1).toString(36).substring(4).toUpperCase(),
        };
      });

      const params: SignerParams = {
        procedure_id: procedure.id,
        documents: result,
        password,
      };

      A1Service.signer(params)
        .then((res) => {
          if (res && res.id) {
            handleConfirmLink(result, res.id, procedure, documentsToProcedure);
          }
        })
        .catch((error) => {
          addToasts({
            type: 'error',
            text: error.data.error,
          });
        });
    });
  };

  const openModalAuthA1 = () => {
    setModal({
      visible: true,
      open: true,
      size: 'small',
      title: t('procedureBox.actions.signerA1.title'),
      children: <AuthA1
                  submit={(password) => handleSignerA1(password)}
                  goBack={() => setModal(undefined)} />,
      handleClose: () => setModal(undefined),
    });
  };

  return (
    <div className={ styles.container }>
      <SelectedProcedures />
      <div className={styles.header}>
        <p>
          <b>{t(`${keyI18n}.modal.title`)}</b>
        </p>
        <p>{t(`${keyI18n}.modal.subtitle`)}</p>
      </div>

      <DocumentList onChange={(val) => setDocuments(val)} />

      <footer>
        <Button
          title={t(`${keyI18n}.modal.submit`)}
          textCenter
          round
          buttonType='primary'
          size='flat'
          disabled={documents.length === 0}
          onClick={() => openModalAuthA1()} />
      </footer>
    </div>
  );
}
