import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import { Trans } from 'react-i18next';
import { Button } from '../../../components/Button';
import { Table } from '../../../components/Table';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Hash } from '../../../@types/digitalProcesses/timeline';
import Format from '../../../helpers/format';

export default function AlterationOwnership() {
  const { proceduresSeleted, addToasts, setAlert } = useContext(ProcedureActionsContext);
  const [currentContributor, setCurrentContributor] = useState<Hash[]>([]);
  const [newContributor, setNewContributor] = useState<Hash[]>([]);
  const [inscriptions, setInscriptions] = useState<any>([]);
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const renderTableData = (items: any, isInscriptions?: boolean) => {
    return (
      <>
        {
          isInscriptions ? (
            items.map((item: any) => (
              <tbody>
                {
                  item.map((object: Hash) => (
                    <td>{object.value}</td>
                  ))
                }
              </tbody>
            ))
          ) : (
            <tbody>
              {
                items.map((item: Hash) => (
                  <td>
                    {item.value}
                  </td>
                ))
              }
            </tbody>
          )
        }
      </>
    );
  };

  const renderTable = (dataCy: string, title: string, informations: any, isInscriptions?: boolean) => {
    if (informations.length > 0) {
      const keys = isInscriptions ? informations[0].map((item: Hash) => item.key) : informations.map((item: Hash) => item.key);

      return (
        <div className={styles.content}>
          <div className={styles.header}>
            <span>{title}</span>
            <hr/>
          </div>
          <Table dataCy={dataCy}>
            <thead>
              {
                keys.map((column: string) => (
                  <th>{column}</th>
                ))
              }
            </thead>
            { renderTableData(informations, isInscriptions) }
          </Table>
        </div>
      );
    }

    return <></>;
  };

  const handleAlterOwnership = (type: string) => {
    setAlert(undefined);
    ProcedureBoxService.postAlterOwnership(proceduresSeleted[0].id, type)
      .then((resp) => {
        if (resp.status === 201) {
          addToasts({
            type: 'success',
            text: resp.data,
          });
          setLoading(true);
        }
      }).catch((err) => {
        const errorMessage = `${err.data.error} ${err.data.exception}`;
        addToasts({
          type: 'error',
          text: errorMessage,
        });
      });
  };

  const showModal = (type: string) => {
    setAlert({
      visible: true,
      title: <Trans
        i18nKey={`procedureBox.ownership.modal.${type}`}
        components={{ b: <b /> }}
      />,
      confirmText: t('general.yes'),
      handleConfirm: () => handleAlterOwnership(type),
    });
  };

  useEffect(() => {
    if (loading) {
      ProcedureBoxService.getOwnershipAlteration(proceduresSeleted[0].id)
        .then((resp) => {
          if (resp.status === 'authorized' || resp.status === 'rejected') {
            if (resp.decision_by) {
              setData(t(`procedureBox.ownership.messages.${resp.status}.withIndividual`, {
                date: Format.formatDate(resp.decision_date),
                name: resp.decision_by,
              }));
            } else {
              setData(t(`procedureBox.ownership.messages.${resp.status}.withoutIndividual`));
            }
          }
        }).catch((err) => {
          const errorMessage = `${err.data.error} ${err.data.exception}`;
          addToasts({
            type: 'error',
            text: errorMessage,
          });
        });

      ProcedureBoxService.getOwnershipRequests(proceduresSeleted[0].id)
        .then((resp) => {
          if (resp.inscriptions.length > 0) {
            setInscriptions(resp.inscriptions);
          }

          if (resp.current_contributor.length > 0) {
            setCurrentContributor(resp.current_contributor);
          }
          if (resp.new_contributor.length > 0) {
            setNewContributor(resp.new_contributor);
          }
        }).catch((err) => {
          const errorMessage = `${err.data.error} ${err.data.exception}`;
          addToasts({
            type: 'error',
            text: errorMessage,
          });
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);

  const renderContent = () => {
    return (
      <>
        <div className={styles.buttons}>
          {
            data ? (
              <span>{data}</span>
            ) : (
              <>
                <Button
                  dataCy={'cypress-reject-button-alteration-ownership'}
                  title={t('procedureBox.ownership.buttons.reject_requirement')}
                  textCenter
                  round
                  buttonType='danger'
                  size='flat'
                  disabled={false}
                  onClick={() => showModal('rejected')}
                />
                <Button
                  dataCy={'cypress-approve-button-alteration-ownership'}
                  title={t('procedureBox.ownership.buttons.approve_requirement')}
                  textCenter
                  round
                  buttonType='primary'
                  size='flat'
                  disabled={false}
                  onClick={() => showModal('authorized')}
                />
              </>
            )
          }
        </div>
        {
          renderTable(
            'cypress-table-current-contributor',
            t('procedureBox.ownership.table.currentContributor'),
            currentContributor,
          )
        }
        {
          renderTable(
            'cypress-table-new-contributor',
            t('procedureBox.ownership.table.newContributor'),
            newContributor,
          )
        }
        {
          renderTable(
            'cypress-table-inscriptions',
            t('procedureBox.ownership.table.inscriptions'),
            inscriptions,
            true,
          )
        }
      </>
    );
  };

  return (
    <div className={styles.container}>
      {
        loading ? (
          <Skeleton
            variant={'rectangular'}
            className={ styles.row }
          />
        ) : (
          renderContent()
        )
      }
    </div>
  );
}
