import Switch, { ReactSwitchProps } from 'react-switch';

interface CustomSwitchProps extends ReactSwitchProps {
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

const CustomSwitch: React.FC<CustomSwitchProps> = (props) => {
  const getColor = (value: string) => {
    const { body } = document;
    const color = window.getComputedStyle(body).getPropertyValue(value).trim();
    return color;
  };

  return (
    <Switch
      data-cy='cypress-generic-switch'
      offColor={getColor('--primary2')}
      onColor={getColor('--primary4')}
      height={18}
      width={32}
      checkedIcon={false}
      uncheckedIcon={false}
      {...props}
    />
  );
};

export default CustomSwitch;
