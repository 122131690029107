import { CheckBox } from '../../CheckBox';
import styles from './styles.module.scss';

type ColorsCheckBoxProps = {
  setColor: (value: string) => void;
  color: string;
};

export function ColorsSelect(props: ColorsCheckBoxProps) {
  const colorsPalette = [
    'var(--primary5)',
    'var(--extra2)',
    'var(--success5)',
    'var(--warning5)',
    'var(--extra4)',
    'var(--danger4)',
    'var(--extra6)',
    'var(--extra8)',
    'var(--neutral5)',
  ];

  const toggleChecked = (color: string) => {
    props.setColor(color);
  };

  return (
    <div className={styles.colors}>
      {
        colorsPalette.map((color) => (
          <CheckBox
            onClick={ () => toggleChecked(color) }
            isMarker
            key={color}
            color={color}
            value={ props.color === color }
            fontLarger
          />
        ))
      }
    </div>
  );
}
