import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { showBackground } from '../../helpers/showBackground';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: showBackground(),
  },
}));

const CustomTooltip: React.FC<TooltipProps> = (props) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

export default CustomTooltip;
