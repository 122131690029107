import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { t } from 'i18next';
import { FaTimes } from 'react-icons/fa';
import { useContext } from 'react';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { QueryContext } from '../../../context/queryContext';
import { CurrentUser } from '../../../@types/currentUser';
import { Procedure } from '../../../@types/model';

type DeleteSharedProps = {
  procedure: Procedure;
};

export default function DeleteSharing(props: DeleteSharedProps) {
  const { addToasts, setAlert } = useContext(ProcedureActionsContext);
  const { loadData } = useContext(QueryContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);

  const handleDelete = async () => {
    if (currentUser.id) {
      ProcedureBoxService.removeSharedPeople(props.procedure.id, currentUser.id)
        .then(() => {
          addToasts({
            type: 'default',
            text: t('procedureBox.actions.shareProcedure.remove.success', { name: currentUser.name }),
          });
          loadData();

          ProcedureBoxService.notifyRemoveSharedPeople(props.procedure.id, currentUser.id);
        }).catch(() => {
          addToasts({
            type: 'error',
            text: t('procedureBox.actions.shareProcedure.remove.error'),
          });
        });
    } else {
      addToasts({
        type: 'error',
        text: t('procedureBox.actions.shareProcedure.remove.error'),
      });
    }

    setAlert(undefined);
  };

  const handleConfirm = () => {
    setAlert({
      visible: true,
      title: t('procedureBox.actions.shareProcedure.deleteModal.title'),
      text: t('procedureBox.actions.shareProcedure.deleteModal.text', {
        individualName: props.procedure.individual_name,
      }),
      handleConfirm: () => handleDelete(),
    });
  };

  return (
    <Tooltip
      title={`${t('procedureBox.actions.shareProcedure.delete')}`}
    >
      <IconButton
        data-cy={'cypress-button-delete-sharing'}
        className={styles.button}
        onClick={handleConfirm}
      >
        <FaTimes size={18} />
      </IconButton>
    </Tooltip>
  );
}
