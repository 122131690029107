import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { QueryContext } from '../../context/queryContext';
import { ItemType, MenuSelect } from '../MenuSelect';
import Show from '../Show';

export function SortMenu() {
  const {
    setOrderBy, orderItem, setOrderItem, box,
  } = useContext(QueryContext);
  const { t } = useTranslation();
  const itens: ItemType[] = [
    {
      id: 'mostUpdatedAt',
      query: 'updated_at desc',
      translated: `${t('procedureBox.sortMenu.mostUpdatedAt')}`,
      selected: orderItem === 'mostUpdatedAt',
    },
    {
      id: 'olderUpdatedAt',
      query: 'updated_at asc',
      translated: `${t('procedureBox.sortMenu.olderUpdatedAt')}`,
      selected: orderItem === 'olderUpdatedAt',
    },
    {
      id: 'mostRecentCreated',
      query: 'created_at desc',
      translated: `${t('procedureBox.sortMenu.mostRecentCreated')}`,
      selected: orderItem === 'mostRecentCreated',
    },
    {
      id: 'olderCreated',
      query: 'created_at asc',
      translated: `${t('procedureBox.sortMenu.olderCreated')}`,
      selected: orderItem === 'olderCreated',
    },
    {
      id: 'mostRecent',
      query: 'individual_update desc',
      translated: `${t('procedureBox.sortMenu.mostRecent')}`,
      selected: orderItem === 'mostRecent',
    },
    {
      id: 'older',
      query: 'individual_update asc',
      translated: `${t('procedureBox.sortMenu.older')}`,
      selected: orderItem === 'older',
    },
    {
      id: 'mostRecentDeadline',
      query: 'procedure_deadlines.deadline desc',
      translated: `${t('procedureBox.sortMenu.mostRecentDeadline')}`,
      selected: orderItem === 'mostRecentDeadline',
    },
    {
      id: 'olderDeadline',
      query: 'procedure_deadlines.deadline asc',
      translated: `${t('procedureBox.sortMenu.olderDeadline')}`,
      selected: orderItem === 'olderDeadline',
    },
    {
      id: 'unread',
      query: 'was_seen asc',
      translated: `${t('procedureBox.sortMenu.unread')}`,
      selected: orderItem === 'unread',
    },
    {
      id: 'read',
      query: 'was_seen desc',
      translated: `${t('procedureBox.sortMenu.read')}`,
      selected: orderItem === 'read',
    },
    {
      id: 'lowerValue',
      query: 'procedure_value asc',
      translated: `${t('procedureBox.sortMenu.lowerValue')}`,
      selected: orderItem === 'lowerValue',
    },
    {
      id: 'highestValue',
      query: 'procedure_value desc',
      translated: `${t('procedureBox.sortMenu.highestValue')}`,
      selected: orderItem === 'highestValue',
    },
  ];
  const boxFilters = [
    {
      id: 'inbox',
      actions: ['mostRecent', 'older', 'mostUpdatedAt', 'olderUpdatedAt', 'olderDeadline', 'unread', 'highestValue'],
    },
    {
      id: 'outbox',
      actions: ['mostRecent', 'older'],
    },
    {
      id: 'sharedbox',
      actions: ['mostRecent', 'older', 'mostUpdatedAt', 'olderUpdatedAt', 'olderDeadline', 'unread', 'highestValue'],
    },
    {
      id: 'archivedbox',
      actions: ['mostRecent', 'older'],
    },
    {
      id: 'citationIntimation',
      actions: ['mostUpdatedAt', 'olderUpdatedAt', 'mostRecentCreated', 'olderCreated', 'mostRecent', 'older', 'mostRecentDeadline',
        'olderDeadline', 'unread', 'read', 'lowerValue', 'highestValue'],
    },
  ];

  const getItens = (boxes: string) => {
    const find = boxFilters.find((bf) => bf.id === boxes);
    const filteredItens = itens.filter((i) => find?.actions.includes(i.id));
    if (boxes) {
      setOrderItem(filteredItens[0].id);
    }
    return boxes ? filteredItens : itens;
  };

  const onChange = (item: ItemType) => {
    const value = { ...item, selected: true };
    setOrderBy(item.query);
    setOrderItem(value.id);
  };

  return (
    <div className={`${styles.sortMenuContainer}`}>
      <Show if={ box !== 'outbox' }>
        <span className={`${styles.title}`}>{t('procedureBox.sortMenu.title')}</span>
        <MenuSelect itens={getItens(box)} onChange={(item) => onChange(item)} box={box} chevron/>
      </Show>
    </div>
  );
}
