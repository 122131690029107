import { Tooltip } from '../../../../../components/Tooltip';
import styles from './styles.module.scss';

type SearchOperatorsProps = {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>,
};

export function SearchOperators(props: SearchOperatorsProps) {
  const tooltipHandle = (operator: string) => {
    switch (operator) {
      case 'e':
        return (
          <span>
            Busca os documentos que contém as palavras que estão entre o operador.<br />
            <br />
            <hr />
            <br />
            Ex: Prazo <b>e</b> medicamentos
          </span>
        );
      case 'ou':
        return (
          <span>
            Busca os documentos que contém uma ou as duas palavras entre o operador.<br />
            <br />
            <hr />
            <br />
            Ex: Prazo <b>ou</b> medicamentos
          </span>
        );
      case '~':
        return (
          <span>
            Pesquisa os documentos que contenham o início das palavras digitadas.<br />
            <br />
            <hr />
            <br />
            Ex: Medic~
            <br />
            Medic<b>o</b><br/>
            Medic<b>ação</b><br/>
            Medic<b>amentos</b><br/>
          </span>
        );
      case '" "':
        return (
          <span>
            Os termos devem aparecer no documento na exata ordem e com a exata grafia indicadas.
            Exemplo: 'principio de presunção de inocência'. Além disso, os termos entre aspas perdem a função
            de operadores. Exemplo: 'presunção ou inocência'.
          </span>
        );
      case '( )':
        return (
          <span>
            Os parênteses indicam a ordem de prioridade das operações, quando utilizado mais de um operador. Exemplo:
            direito e (privacidade ou intimidade).
          </span>
        );
      default:
        return '';
    }
  };

  const addOperatorToQuery = (operator: string) => {
    props.setQuery(`${props.query} ${operator}`);
  };

  const operators = [
    'e',
    'ou',
    '~',
    '" "',
    '( )',
  ];
  return (
    <div className={ styles.container }>
      {
        operators.map((operator) => {
          return (
            <Tooltip title={ tooltipHandle(operator) }>
              <div className={ styles.item } onClick={() => { addOperatorToQuery(operator); }}>
                <span>{ operator }</span>
              </div>
            </Tooltip>
          );
        })
      }
    </div>
  );
}
