import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import {
  FaChevronDown, FaFolderOpen, FaStickyNote, FaTelegramPlane,
} from 'react-icons/fa';
import styles from './styles.module.scss';
import Select from '../Select';
import { OptionType } from '../SelectFilter';
import { Button } from '../Button';
import ProcedureBoxService from '../../services/procedureBoxService';
import { CustomizeColumnsData } from '../../@types/config';
import { RoundButton } from '../RoundButton';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { QueryContext } from '../../context/queryContext';

export function CustomizeColumnsModal() {
  const { loadData } = useContext(QueryContext);
  const options: OptionType[] = [
    {
      label: t('procedureBox.customizeColumns.select.options.subject'),
      value: 'subject',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.flux'),
      value: 'flux',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.procedure_value'),
      value: 'procedure_value',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.last_product_generated'),
      value: 'last_product_generated',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.origin_procedure_name'),
      value: 'origin_procedure_name',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.process_category_name'),
      value: 'process_category_name',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.hearings_count'),
      value: 'hearings_count',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.competence'),
      value: 'competence',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.cdas_linked'),
      value: 'cdas_linked',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.time_inbox'),
      value: 'time_inbox',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.created_at'),
      value: 'created_at',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.received_date'),
      value: 'received_date',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.inactive_time'),
      value: 'inactive_time',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.requiriment_date'),
      value: 'requiriment_date',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.interested_parts'),
      value: 'interested_parts',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.current_responsible'),
      value: 'current_responsible',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.previous_responsibles'),
      value: 'previous_responsibles',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.shared_with'),
      value: 'shared_with',
    },
    {
      label: t('procedureBox.customizeColumns.select.options.deadlines'),
      value: 'deadlines',
    },
  ];

  options.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { addToasts, setModal } = useContext(ProcedureActionsContext);
  const disabled = (): boolean => {
    return !(selected.length === 4 && selected.every((item) => item !== undefined && item !== '')) || loading;
  };

  const returnOptions = () => {
    return options.filter((option) => {
      return !selected.includes(option.value);
    });
  };

  const handleSelect = (index: number, newValue: string) => {
    setSelected((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[index] = newValue;
      return newSelected;
    });
  };

  const submit = () => {
    setLoading(true);
    const orderFields: CustomizeColumnsData = {
      field: 3,
      data: selected.map((item, index) => {
        return { description: item, order: index };
      }),
    };
    ProcedureBoxService.postCustomizeFields(
      { order_field: orderFields },
    ).then(() => {
      setLoading(false);
      addToasts({
        type: 'success',
        text: t('procedureBox.customizeColumns.toast'),
      });
      setModal(undefined);
      loadData(false);
    });
  };

  return (
    <>
      <div className={styles.container}>
        <Grid
          container
          columns={{ xs: 1, sm: 1, md: 12 }}
          className={styles.content}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            className={styles.number}
          >
            {t('procedureBox.customizeColumns.procedureNumber')}
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            className={styles.item}
          >
            <Select
              dataCy='cypress-customize-columns-select-first'
              label=''
              options={returnOptions()}
              placeholder={t('procedureBox.customizeColumns.select.placeholder')}
              onChange={(e) => { handleSelect(0, e?.value); }}
            />
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            className={styles.item}
          >
            <Select
              dataCy='cypress-customize-columns-select-second'
              label=''
              options={returnOptions()}
              placeholder={t('procedureBox.customizeColumns.select.placeholder')}
              onChange={(e) => { handleSelect(1, e?.value); }}
            />
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            className={styles.item}
          >
            <Select
              dataCy='cypress-customize-columns-select-third'
              label=''
              options={returnOptions()}
              placeholder={t('procedureBox.customizeColumns.select.placeholder')}
              onChange={(e) => { handleSelect(2, e?.value); }}
            />
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            className={styles.item}
          >
            <Select
              dataCy='cypress-customize-columns-select-fourth'
              label=''
              options={returnOptions()}
              placeholder={t('procedureBox.customizeColumns.select.placeholder')}
              onChange={(e) => { handleSelect(3, e?.value); }}
            />
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            className={styles.groupButtons}
          >
            <RoundButton
              icon={ <FaTelegramPlane/> }
              className={styles.button}
              tooltip={t('procedureBox.observations.tramitObservations')}
              size={'medium'}
              onClick={() => {}}
              badge={1} />
            <RoundButton
              icon={ <FaStickyNote/> }
              className={styles.button}
              tooltip={t('procedureBox.notes.notes')}
              size={'medium'}
              onClick={() => {}} />
            <RoundButton
              icon={ <FaFolderOpen/> }
              className={styles.button}
              tooltip={t('procedureBox.procedureList.digitalFolder')}
              size={'medium'}
              onClick={() => {}} />
            <RoundButton
              icon={ <FaChevronDown/> }
              className={styles.button}
              size={'medium'}
              onClick={() => {}} />

          </Grid>
        </Grid>
      </div>
      <div className={styles.groupButtons}>
        <Button
          dataCy='cypress-customize-columns-submit'
          title={t('procedureBox.customizeColumns.button')}
          disabled={disabled()}
          buttonType='primary'
          size='flat'
          onClick={submit}
          round
        />
      </div>
    </>
  );
}
