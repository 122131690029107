import { t } from 'i18next';
import { FaTag } from 'react-icons/fa';
import { Marker } from '../../../@types/model';
import { MarkerMenu } from '../../MarkerMenu';
import { Menu } from './Menu';
import { Selector } from './Selector';
import { priorityItems, pendencyItems } from '../../../helpers/markers';
import styles from './styles.module.scss';
import CustomTooltip from '../../CustomTooltip';

type MarkersProps = {
  expandend?: boolean;
  markers: Marker[];
  priority?: string;
  pendency?: string;
  procedureId: number;
};

export function Markers(props: MarkersProps) {
  if (props.expandend) {
    return (
      <div className={ styles.container }>
        <div className={ styles.title }>
          <FaTag />
          <span>{t('procedureBox.markers.title')}:</span>
        </div>

        <div className={ styles.list }>
          <span className={ styles.subtitle }>{t('procedureBox.markers.priority')}:</span>
          <div className={ styles.selectors }>
            <MarkerMenu
              itens={priorityItems}
              procedureId={props.procedureId}
              label={t(`procedureBox.markerMenu.${props.priority ? props.priority : 'low'}`)}
              keyId={props.priority || 'low'}
            />
          </div>
        </div>

        <div className={ styles.list }>
          <span className={ styles.subtitle }>{t('procedureBox.markers.pendency')}:</span>
          <div className={ styles.selectors }>
          <MarkerMenu
              itens={pendencyItems}
              procedureId={props.procedureId}
              label={t(`procedureBox.markerMenu.${props.pendency ? props.pendency : 'no_pending'}`)}
              keyId={props.pendency || '0'}
            />
          </div>
        </div>

        <div className={ styles.list }>
          <span className={ styles.subtitle }>{t('procedureBox.markers.custom')}:</span>
          <div className={ styles.selectors }>
            { props.markers.map((marker) => <Selector
                                              key={marker.id}
                                              marker={ marker }
                                              procedureId={ props.procedureId }
                                              fixedValue={ false }
                                            />)
            }
          </div>
        </div>

        <div className={ styles.addButton }>
          <Menu
            pendencyMarkers={pendencyItems}
            priorityMarkers={priorityItems}
            procedureId={props.procedureId}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={ styles.collapsed }>
      {
        props.markers.map((m) => <CustomTooltip
                                  key={m.id}
                                  title={ m.label }>
                                  <div
                                    className={ styles.item }
                                    style={{ backgroundColor: m.color }}
                                  />
                                </CustomTooltip>)
      }
    </div>
  );
}
