import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../Button';
import { SelectedProcedures } from '../SelectedProcedures';
import { TextArea } from '../TextArea';
import { Timeline } from './Timeline';
import styles from './styles.module.scss';
import { SwitchNotes } from './SwitchNotes';
import ProcedureBoxService from '../../services/procedureBoxService';
import { CurrentUser } from '../../@types/currentUser';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { MultipleActionStep } from '../../@types/backgroundActions';
import { buildMultipleActionsList } from '../../helpers/multipleActions';
import { QueryContext } from '../../context/queryContext';

type NoteProps = {
  closeModal: () => void;
  multipleActions?: MultipleActionStep;
};

export function Note(props: NoteProps) {
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const { proceduresSeleted, handleMultipleActions } = useContext(ProcedureActionsContext);
  const { pathname } = useLocation();
  const { reloadCurrentProcedure } = useContext(QueryContext);
  const [note, setNote] = useState('');
  const [hide, setHide] = useState(true);
  const [reload, setReload] = useState(Math.random());
  const createNote = () => {
    ProcedureBoxService.NewNote(
      note,
      currentUser.id,
      !hide,
      proceduresSeleted.length === 1 ? proceduresSeleted[0].id : null,
      proceduresSeleted.length === 1 ? null : proceduresSeleted.map((procedure) => procedure.id),
    ).then((res) => {
      setReload(Math.random());
      reloadCurrentProcedure(pathname);

      if (props.multipleActions !== undefined) {
        const nextStep = props.multipleActions.data.find((i) => i.name === props.multipleActions?.currentStep)?.nextStep;
        if (nextStep) {
          props.closeModal();
          const data = buildMultipleActionsList(props.multipleActions, props.multipleActions.currentStep);
          handleMultipleActions(data, nextStep);
        }
      }
    });
  };
  return (
  <div className={styles.container}>
    <SelectedProcedures />
    <div className={styles.form}>
      <TextArea
        dataCy={'cypress-note-text-area'}
        label={t('procedureBox.actions.notes.modal.observation.title')}
        onChange={(event) => { setNote(event.currentTarget.value); }}
        placeholder={t('procedureBox.actions.notes.modal.observation.placeholder')}
        required />
      <Grid
          container
          columns={{ xs: 1, sm: 8, md: 12 }}
          className={styles.content}
        >
        <Grid item xs={1} sm={4} md={6}>
          <SwitchNotes value={hide} setValue={ (e) => { setHide(!e); }} />
        </Grid>
          <Grid item xs={1} sm={4} md={6}>
            <div className={styles.createNote}>
              <Button
                dataCy='cypress-note-create-button'
                buttonType={'primary'}
                round
                disabled={note.length === 0}
                size='flat'
                title={t('procedureBox.actions.notes.modal.observation.create')}
                onClick={createNote} />
            </div>
          </Grid>
        </Grid>
      </div>
      <Timeline key={reload} />
    </div>
  );
}
