import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { QueryContext } from '../../../../context/queryContext';
import { Label } from '../../../Label';
import styles from './styles.module.scss';
import CustomTooltip from '../../../CustomTooltip';

type RoundProps = {
  id: string;
  title: string,
  tooltip?: any,
};

type RoundFilterProps = [string, number];

export function RoundFilter(props: RoundProps) {
  const { t } = useTranslation();
  const { hasAnyFilter, updateAdvancedFilter } = useContext(QueryContext);

  const [radioFilter1, setradioFilter1] = useState<RoundFilterProps>([styles.radio, 3]);
  const [radioFilter2, setradioFilter2] = useState<RoundFilterProps>([styles.radio, 6]);
  const [radioFilter3, setradioFilter3] = useState<RoundFilterProps>([styles.radio, 7]);

  useEffect(() => {
    if (hasAnyFilter === false) {
      setradioFilter1([styles.radio, 3]);
      setradioFilter2([styles.radio, 6]);
      setradioFilter3([styles.radio, 7]);
    }
  }, [hasAnyFilter]);

  useEffect(() => {
    const arr: number[] = [];
    const radioFilters = [radioFilter1, radioFilter2, radioFilter3];
    radioFilters.map((radioOption: RoundFilterProps) => {
      if (radioOption[0] === styles.radioSelected) {
        arr.push(radioOption[1]);
      }
    });
    updateAdvancedFilter(props.id, arr);
  }, [radioFilter1, radioFilter2, radioFilter3]);

  const verifySelected = (selectedClass: RoundFilterProps, setStateMethod: any) => {
    if (selectedClass[0] === styles.radio) {
      setStateMethod([styles.radioSelected, selectedClass[1]]);
    } else {
      setStateMethod([styles.radio, selectedClass[1]]);
    }
  };

  return (
    <div>
      <div>
        <div className={styles.info}>
          <Label text={props.title}/>
            {props.tooltip ? <CustomTooltip title={ props.tooltip }>
              <div className={styles.containerTag}>
                <FaRegQuestionCircle />
              </div>
              </ CustomTooltip> : null}
        </div>
      </div>
      <div className={`${styles.options}`}>
        <div
          className={radioFilter1[0]}
          onClick={() => { verifySelected(radioFilter1, setradioFilter1); }}>
          <div></div>
          <label>{t('procedureBox.filterAdvanced.municipalPart.executed')}</label>
        </div>

        <div
          className={radioFilter2[0]}
          onClick={() => { verifySelected(radioFilter2, setradioFilter2); }}>
          <div></div>
          <label>{t('procedureBox.filterAdvanced.municipalPart.bidder')}</label>
        </div>

        <div
          className={radioFilter3[0]}
          onClick={() => { verifySelected(radioFilter3, setradioFilter3); }}>
          <div></div>
          <label>{t('procedureBox.filterAdvanced.municipalPart.creditor')}</label>
        </div>
      </div>
    </div>
  );
}
