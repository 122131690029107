import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../Select';
import { Header } from '../../Header';
import { TextArea } from '../../TextArea';
import { Button } from '../../Button';
import styles from './styles.module.scss';
import { OptionType, CollaboratorData } from '../../../@types/config';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { Automatic } from '../Automatic';
import { Manual } from '../Manual';
import { MultipleSelect } from '../../MultipleSelect';
import { RadioGroup } from '../../RadioGroup';
import { DistributionType } from '../../../helpers/enums';
import waitingFormParameters from '../../../assets/distributeForm.svg';
import { ProcedureCarousel } from '../../ForwardIpm/ProcedureCarousel';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { ErrorPropsType, QuantityPerFlux } from '../../../@types/digitalProcesses/fluxes';
import { ProgressBar } from '../ProgressBar';
import { Batch } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { ManualDistributionData } from '../../../@types/distribution';
import { CurrentUser } from '../../../@types/currentUser';
import { alphabeticalOrder } from '../../../helpers/alphabeticalOrder';
import Show from '../../Show';

type DataType = {
  field_id: [{
    id: number,
    name: string
  }],
  individual_id: number,
  job_title: [{
    id: number,
    name: string,
    unit_field_id: number[],
  }],
  unit_field_id: [{
    id: number,
    name: string,
    field_id: number,
  }],
};

export function Form() {
  const { handleSubmit, control } = useForm();
  const {
    proceduresSeleted, setAlert, setModal, removeProcedure,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [area, setArea] = useState<OptionType[]>([]);
  const [areaSelected, setAreaSelect] = useState<OptionType>();
  const [unitField, setUnitField] = useState<OptionType[]>([]);
  const [unitFieldSelected, setUnitFieldSelected] = useState<OptionType | null >();
  const [functions, setFunctions] = useState<OptionType[]>([]);
  const [colaboratorData, setColaboratorData] = useState<CollaboratorData>();
  const [functionField, setFunctionField] = useState(false);
  const [functionFieldValue, setFunctionFieldValue] = useState<OptionType | null | undefined>();
  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState<DataType>();
  const [loading, setLoading] = useState(true);
  const [distributionType, setDistributionType] = useState('manual');
  const [processFlux, setProcessFlux] = useState(0);
  const [selectedProceduresByFlux, setSelectedProceduresByFlux] = useState<QuantityPerFlux[]>([]);
  const [distributionData, setDistributionData] = useState<ManualDistributionData[]>([]);
  const [progress, setProgress] = useState({ distributed: 0, total: proceduresSeleted.length });
  const [hasError, setHasError] = useState<ErrorPropsType[]>([]);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const selectedArea = areaSelected && areaSelected.value;
  const selectedUnitField = unitFieldSelected && unitFieldSelected.value;

  const defaultField = { label: '', value: 0 };
  const defaultunitFieldSelected = { label: '', value: 0 };
  const defaultFunction = { label: '', value: 0 };

  const renderBanner = () => {
    return (
      <Grid item xs={1} sm={3} md={3}>
        <Grid item xs={1} sm={2} md={2} className={styles.tip}>
          {t('procedureBox.actions.distributeProcesses.modal.distributePerCollaborator.tip')}
        </Grid>
        <Grid item xs={1} sm={3} md={3} className={styles.svg}>
          <img src={waitingFormParameters} />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const dataLength = distributionData.length;
    const proceduresAmount = selectedProceduresByFlux.reduce((counter, selectedProcedures) => {
      return counter + selectedProcedures.quantity;
    }, 0);

    if (distributionType === 'automatic') {
      if (proceduresAmount <= proceduresSeleted.length && hasError.length === 0) {
        setProgress({ distributed: proceduresAmount, total: proceduresSeleted.length });
      }
    } else {
      setProgress({ distributed: dataLength, total: proceduresSeleted.length });
    }
    if (dataLength === proceduresSeleted.length || proceduresAmount === proceduresSeleted.length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    if (proceduresSeleted.length === 1) {
      setDistributionType('automatic');
    }
  }, [distributionData, selectedProceduresByFlux, distributionType, hasError]);

  const showCollaboratorForm = (value?: OptionType | null) => {
    setFunctionField(true);
    setFunctionFieldValue(value);
    if (area.length !== 0 && unitField.length !== 0 && functions.length !== 0) {
      setShowForm(true);
      if (value == null) {
        setShowForm(false);
        setFunctionField(false);
      }
    }
  };

  useEffect(() => {
    setArea([]);
    ProcedureBoxService.getDistributeParams().then((e) => {
      setData(e.data);
      setArea(e.data.field_id.map((i: any) => { return { label: i.name, value: i.id }; }));
    });
    ProcedureBoxService.getCurrentUserDistributeParams(currentUser.id).then((response) => {
      if (response.area && response.function && response.unit_fiel) {
        setAreaSelect({ label: response.area.label, value: response.area.value });
      }
    });
  }, []);

  useEffect(() => {
    showCollaboratorForm(functionFieldValue);
  }, [functionField]);

  const loadUnitFields = (value?: OptionType | null) => {
    setUnitField([]);
    const unitsFields = data?.unit_field_id.filter((uf) => uf.field_id === value?.value);
    if (unitsFields && value) {
      setAreaSelect(value);
      setUnitField(unitsFields.map((unf) => ({ label: unf.name, value: unf.id })));
    }
    setUnitFieldSelected(null);
    setColaboratorData(undefined);
    setShowForm(false);
  };

  useEffect(() => {
    loadUnitFields(areaSelected);
  }, [area]);

  const loadJobTitles = (value: OptionType | null) => {
    setUnitFieldSelected(value);
    const jobTitles = data?.job_title.filter((jt) => jt?.unit_field_id.includes(value?.value));
    if (jobTitles) {
      setFunctions(jobTitles.map((jbt) => ({ label: jbt.name, value: jbt.id })));
    }
  };

  const getAlertLabel = (type: string) => {
    const uniqueIndividualIds = new Set();
    const individualsIds = selectedProceduresByFlux.map((spbf) => spbf.individualId);
    const individualsUniqQuantity = new Set(individualsIds).size;

    distributionData.map((obj: any) => {
      uniqueIndividualIds.add(obj.individualId);
    });

    return t(
      'procedureBox.actions.distributeProcesses.modal.alertConfirmation.text',
      {
        procedureNumber: progress.total,
        collaboratorNumber: type === 'manual' ? Array.from(uniqueIndividualIds).length : individualsUniqQuantity,
      },
    );
  };

  const handleConfirm = (observation: string) => {
    const type = 'distributionManual';
    const id = (Math.random() + 1).toString(36).substring(1);
    const procedures = proceduresSeleted;

    const body = distributionData.map((obj: any) => {
      return {
        procedure_id: obj.procedureId,
        process_distribution: {
          status: 1,
          field_id: selectedArea,
          unit_field_id: selectedUnitField,
          individual_id: obj.individualId,
          observation,
          procedure_id: obj.procedureId,
        },
      };
    });

    const batch: Batch = {
      id,
      type,
      procedures,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
    };

    addBatch(batch);
    setAlert(undefined);
    setModal(undefined);
  };

  const handleConfirmAutomatic = (observation: string) => {
    const id = (Math.random() + 1).toString(36).substring(1);
    const groupedData: Record<number, any[]> = {};
    const type = 'distributionAutomatic';
    const procedures = proceduresSeleted;

    selectedProceduresByFlux.forEach((item) => {
      const { fluxId } = item;
      if (groupedData[fluxId]) {
        groupedData[fluxId].push(item);
      } else {
        groupedData[fluxId] = [item];
      }
    });

    const readyObject = Object.values(groupedData);

    const body = readyObject.map((obj: { fluxId: number, quantity: number, individualId: number }[]) => {
      const procedureByFlux = proceduresSeleted.filter((ps) => ps.flux.id === obj[0].fluxId).map((procedureId) => procedureId.id);
      const procedureIds = procedureByFlux.map((processId) => processId);
      const individuals: { amount: number, individual_id: number }[] = [];
      if (obj.length > 1) {
        obj.map((individual: { fluxId: number, quantity: number, individualId: number }) => {
          const newIndividual = {
            amount: individual.quantity,
            individual_id: individual.individualId,
          };

          individuals.push(newIndividual);
        });
      } else {
        const newIndividual = {
          amount: obj[0].quantity,
          individual_id: obj[0].individualId,
        };

        individuals.push(newIndividual);
      }
      return {
        procedure_ids: procedureIds,
        individuals,
        process_distribution: {
          status: 0,
          field_id: selectedArea,
          unit_field_id: selectedUnitField,
          job_title_id: functions[0].value,
          observation,
        },
      };
    });

    const batch: Batch = {
      id,
      type,
      procedures,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
    };

    addBatch(batch);
    setAlert(undefined);
    setModal(undefined);
  };

  const submit = (form: any) => {
    setModal(undefined);
    setAlert({
      handleConfirm: () => (
        distributionType === 'manual'
          ? handleConfirm(form.observations)
          : handleConfirmAutomatic(form.observations)
      ),
      visible: true,
      title: t('procedureBox.actions.distributeProcesses.modal.alertConfirmation.title'),
      text: getAlertLabel(distributionType),
      type: 'warning',
    });
  };

  const loadingCollaborators = (value: [] | null) => {
    if (value && value.length !== 0 && unitFieldSelected) {
      ProcedureBoxService.getCollaboratorsList(unitFieldSelected.value, value).then((res) => {
        const mappedFluxesString = alphabeticalOrder(res.individuals.map((mapped: any) => mapped.name));

        const mappedAlphabeticalOrder = mappedFluxesString.map((el: any) => {
          return {
            label: el,
            count_all_process_distribution: res.individuals.filter((flux: any) => flux.name === el)[0].count_all_process_distribution,
            count_process_distribution_per_day: res.individuals.filter((flux: any) => flux.name === el)[0].count_process_distribution_per_day,
            count_process_distribution_per_month: res.individuals.filter((flux: any) => flux.name === el)[0].count_process_distribution_per_month,
            id: res.individuals.filter((flux: any) => flux.name === el)[0].id,
            name: res.individuals.filter((flux: any) => flux.name === el)[0].name,
            total_inbox: res.individuals.filter((flux: any) => flux.name === el)[0].total_inbox,
          };
        });

        setShowForm(true);
        setColaboratorData({
          individuals: mappedAlphabeticalOrder,
        });
      });
    }
  };

  useEffect(() => {
    if (unitField && unitFieldSelected && functions) {
      let value: number[] = [];
      if (functions?.length > 1) {
        functions.map((fc) => value.push(fc.value));
      } else {
        value = [functions[0]?.value];
      }
      loadingCollaborators(value as []);
    }
  }, [functions]);

  return (
    <form className={ styles.container } onSubmit={handleSubmit(submit)}>
      <div className={styles.header}>
        <Header
          text={t('procedureBox.actions.distributeProcesses.modal.form.title')}
        />
      </div>

      <Grid
        container
        columns={{ xs: 1, sm: 3, md: 3 }}
        className={styles.content}
      >
        <Show if={proceduresSeleted.length !== 1}>
          <Grid
              item
              className={ styles.radio }
              xs={ 1 }
              md={ 3 }
              sm={ 3 }
            >
              <Controller
                control={control}
                name="process_type"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    label={t('procedureBox.actions.distributeProcesses.modal.form.distributionType')}
                    options={DistributionType()}
                    onChange={(e) => setDistributionType(e.target.value)}
                  />
                )}
              />
          </Grid>
        </Show>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Controller
            control={control}
            name="field_id"
            render={({ field }) => (
              <Select
                {...field}
                options={area}
                label={t('procedureBox.actions.distributeProcesses.modal.form.area')}
                placeholder={t('procedureBox.actions.distributeProcesses.modal.form.arePlaceholder')}
                onChange={loadUnitFields}
                required
                value={areaSelected || defaultField}
                dataCy={'area-field-select'}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Controller
            control={control}
            name="unit_field"
            render={({ field }) => (
              <Select
                {...field}
                options={unitField}
                label={t('procedureBox.actions.distributeProcesses.modal.form.unitField')}
                placeholder={t('procedureBox.actions.distributeProcesses.modal.form.unitFieldPalceHolder')}
                onChange={loadJobTitles}
                required
                value={ unitFieldSelected || defaultunitFieldSelected}
                dataCy={'unit-field-select'}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Controller
            control={control}
            name="functions"
            render={({ field }) => (
              <MultipleSelect
                {...field}
                options={functions}
                label={t('procedureBox.actions.distributeProcesses.modal.form.functions')}
                placeholder={t('procedureBox.actions.distributeProcesses.modal.form.unitFieldPalceHolder')}
                onChange={loadingCollaborators}
                unitField={ functions || defaultFunction}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.observation}
        >
          <Controller
            control={control}
            name="observations"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('procedureBox.actions.distributeProcesses.modal.form.observation')}
                placeholder={t('procedureBox.actions.distributeProcesses.modal.form.observationPlaceHolder')}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.observation}
        >
          <div className={styles.distributeType}>
            <Header text={t(`procedureBox.actions.distributeProcesses.modal.distributePerCollaborator.${distributionType}`)}/>
          </div>
          {showForm ? (
            <>
              <Show if={proceduresSeleted.length !== 1}>
                <ProcedureCarousel
                  procedures={proceduresSeleted}
                  isMobile={false}
                  componentLocation={`distributeProcess${distributionType}`}
                  onClick={(id) => setProcessFlux(id)}
                  fluxSelectedQuantity={distributionType === 'manual' ? distributionData : selectedProceduresByFlux}
                />
              </Show>
              {proceduresSeleted.length === 1 || distributionType === 'automatic'
                ? <Automatic
                    collaboratorData={colaboratorData}
                    showForm={true}
                    processFlux={processFlux}
                    procedures={proceduresSeleted}
                    distributedQuantity={(e) => e && setSelectedProceduresByFlux(e)}
                    valuePerIndividual={selectedProceduresByFlux}
                    errors={(e) => setHasError(e)}
                    singleDistribute={proceduresSeleted.length === 1}
                  />
                : <>
                    <Manual
                      colaboratorData={colaboratorData}
                      proceduresSelected={proceduresSeleted}
                      handleRemoveProcedure={removeProcedure}
                      currentFlux={processFlux}
                      distributionData={distributionData}
                      setDistributionData={setDistributionData}
                    />
                  </>
              }
              <Show if={proceduresSeleted.length !== 1}>
                <ProgressBar
                  distributed={progress.distributed}
                  total={progress.total}
                />
              </Show>
            </>
          ) : (
            renderBanner()
          )}
        </Grid>
        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.submit}
        >
          <Button
            title={t('procedureBox.actions.distributeProcesses.modal.form.submit')}
            textCenter
            round
            buttonType='primary'
            size='flat'
            type='submit'
            disabled={loading}
            dataCy={'submit-distribute'}
          />
        </Grid>
      </Grid>
    </form>
  );
}
