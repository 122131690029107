export const EPGM_URL = 'https://epgm.pgm.fortaleza.ce.gov.br';
export const AGILIS_API_URL = 'https://apiagilis.pgm.fortaleza.ce.gov.br';
export const AGILIS_FRONT = 'hom1';
export const AGILIS_ACCESS_TOKEN = '2TW5cyxjS-73u1_Fhmay';
export const EPGM_SIGN_URL = 'https://epgm.pgm.fortaleza.ce.gov.br';
export const SHOW_AGILIS_DEBITBOX = false;
export const DEBITBOX_AGILIS_API_URL = '';
export const EPGM_LOGIN_SCREEN = false;
export const GOOGLE_ANALYTICS_TOKEN = 'G-DX9GMF6CV0';
export const DEBITBOX_AGILIS_FRONT_URL = 'https://agilisdivida.pgm.fortaleza.ce.gov.br/';
export const LOCAL_JAVA_SIGNER_SERVICE = 'http://localhost:50000';
