import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import styles from './styles.module.scss';
import { ToastsList } from '../../../components/ToastsList';
import { Breadcumb } from '../../../components/Breadcumb';
import { Table } from '../../../components/Table';
import ReportsService from '../../../services/reportsService';
import Format from '../../../helpers/format';
import { OrderSelect } from './OrderSelect';
import { orderCalculationReport } from '../../../helpers/enums';
import { RequestCalculationReportData } from '../../../@types/digitalProcesses/reports';
import { Filters } from './Filters';
import { EmptyList } from '../EmptyList';
import { Loading } from '../Loading';
import { Button } from '../../../components/Button';
import { CheckBox } from '../../../components/CheckBox';
import { SelectList } from '../SelectList';
import { PaginationMenu } from '../../../components/PaginationMenu';

const defaultValue: RequestCalculationReportData = {
  request_calculations: [],
  total_records: 0,
  total_pages: 0,
};

export function Calculation() {
  const { pathname } = useLocation();
  const [data, setData] = useState<RequestCalculationReportData>(defaultValue);
  const [page, setPage] = useState<number | undefined>();
  const [perPage, setPerPage] = useState<number | undefined>();
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    const order = orderBy || 'main_process asc';
    setData(defaultValue);
    setLoading(true);
    if (query.length > 0) {
      ReportsService.getCalculationList(query, order, page, perPage).then((res) => {
        setData(res);
        setLoading(false);
      }).catch(() => setLoading(false));
    } else {
      setLoading(false);
      setData(defaultValue);
    }
  }, [orderBy, query, page, perPage]);

  const handleOrder = (order: string) => {
    setOrderBy(order);
  };

  const changePage = (action: string, value: number) => {
    const currentPage = page || 1;
    const itemsPerPage = perPage || 50;
    if (currentPage * itemsPerPage < data.total_records && action === 'nextPage') {
      setPage(value);
    } else if (value * itemsPerPage >= 1 && action === 'prevPage') {
      setPage(value);
    }
  };

  const columns = [
    t('reports.calculation.table.header.subprocessNumber'),
    t('reports.calculation.table.header.number'),
    t('reports.calculation.table.header.processCategory'),
    t('reports.calculation.table.header.individual'),
    t('reports.calculation.table.header.sendDate'),
    t('reports.calculation.table.header.deadline'),
    t('reports.calculation.table.header.responseDate'),
    t('reports.calculation.table.header.status'),
  ];

  const renderLoading = () => {
    if (loading) {
      return <Loading />;
    } if (query.length > 0) {
      return <EmptyList />;
    }
    return <></>;
  };

  const handleSelect = async (value: 'all' | 'clear' | 'allFromPage' | number) => {
    switch (value) {
      case 'all':
        setLoading(true);
        await ReportsService.getCalculationList(
          query,
          orderBy || 'main_process asc',
          1,
          data.total_records,
        ).then((res) => {
          const response: RequestCalculationReportData = res;
          setSelected(response.request_calculations.map((item) => item.id));
          setLoading(false);
        }).catch(() => setLoading(false));
        break;
      case 'allFromPage':
        setSelected(data.request_calculations.map((item) => item.id));
        break;
      case 'clear':
        setSelected([]);
        break;
      default:
        if (selected.includes(value)) {
          const newList = selected.filter((num) => num !== value);
          setSelected(newList);
        } else {
          setSelected([...selected, value]);
        }
        break;
    }
  };

  const handleFilter = (val: string) => {
    setLoading(true);
    setQuery(val);
  };

  const handleExportList = () => {
    setLoading(true);
    ReportsService.getExportCalculation(selected).then((res) => {
      setLoading(false);
      if (res.file) {
        window.open(res.file, '_blank');
      }
    }).catch(() => setLoading(false));
  };

  const renderTable = () => {
    return (
      <>
        <Grid item xs={12} className={styles.actions}>
          <div className={styles.buttons}>
            <Button
              title={t('reports.general.print')}
              buttonType='primary'
              onClick={handleExportList}
              disabled={loading || selected.length === 0}
              formTarget='_blank'
              round
            />
            <p className={styles.info}>
              <Trans
                i18nKey='reports.general.totalSelected'
                values={{ number: selected.length }}
              />
            </p>
          </div>
          <div className={styles.buttons}>
            <OrderSelect
              label={t('reports.calculation.table.orderBy')}
              options={orderCalculationReport}
              onChange={handleOrder}
            />
            <PaginationMenu
              isDistributeProcess
              pageNumber={page || 1}
              itemsPerPage={perPage || 50}
              totalItems={data.total_records}
              totalPages={data.total_pages}
              handlePagination={changePage}
              onPerPageChange={(e) => setPerPage(Number(e))}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <SelectList
            selectAllFromPage={() => handleSelect('allFromPage')}
            selectAll={() => handleSelect('all')}
            clear={() => handleSelect('clear')}
            value={selected.length > 0}
            partially={selected.length > 0 && selected.length < data.request_calculations.length}
          />
          <div className={ styles.table } data-cy='calculation-table'>
            <Table>
              <thead>
                <tr>
                  <th/>
                  {columns.map((column) => (
                    <th>{column}</th>
                  ))}
                </tr>
              </thead>
              {
                data.request_calculations.map((value) => (
                  <tbody key={value.id}>
                    <td>
                      {
                        <CheckBox
                          value={selected.includes(value.id)}
                          onClick={() => handleSelect(value.id)}
                          color='var(--primary4)'
                        />
                      }
                    </td>
                    <td>
                      <a href={`/procedure_box/show_procedure/${value.procedure_id}`} target='_blank'>
                        {value.subprocess_number}
                      </a>
                    </td>
                    <td>
                      <a href={`/procedure_box/show_procedure/${value.main_procedure_id}`} target='_blank'>
                        {value.principal_number}
                      </a>
                    </td>
                    <td>{value.process_category_name}</td>
                    <td>{value.individual_name}</td>
                    <td>{Format.formatDate(value.accountant_tramit_created_at)}</td>
                    <td>{Format.formatDate(value.deadline)}</td>
                    <td>{Format.formatDate(value.response_tramit_created_at)}</td>
                    <td>{value.status_name}</td>
                  </tbody>
                ))
              }
            </Table>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <ToastsList />
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid item xs={3} sm={6}>
            <h1>{ t('reports.calculation.title') }</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.content}>
          <Grid item xs={12}>
            <Filters
              onChange={(val) => handleFilter(val)}
              disabled={loading}
            />
          </Grid>
          {data.request_calculations.length > 0 ? renderTable() : renderLoading()}
        </Grid>
      </Grid>
    </div>
  );
}
