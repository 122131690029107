import { useState } from 'react';
import { t } from 'i18next';
import { Button } from '../../../../components/Button';
import { InputDate } from '../../../../components/InputDate';
import ReportsService from '../../../../services/reportsService';
import styles from './styles.module.scss';

type NewPaymentDateProps = {
  setOpenModalNewPaymentDate: any;
  paymentOrderSelected?: number;
  data: any;
  setData: any;
};

export function NewPaymentDate(props: NewPaymentDateProps) {
  const [date, setDate] = useState<string>('');
  const handleAddPaymentDate = () => {
    if (props.paymentOrderSelected) {
      ReportsService.addPaymentDate(props.paymentOrderSelected, date).then((res) => {
        if (res) {
          const newData = props.data.map((paymentOrder: any) => {
            if (paymentOrder.id === props.paymentOrderSelected) {
              return { ...paymentOrder, state: t('reports.paymentOrder.modal.paid', { date }) };
            }

            return paymentOrder;
          });

          props.setData(newData);
          props.setOpenModalNewPaymentDate(false);
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <h2>{t('reports.paymentOrder.modal.title')}</h2>
      <div className={styles.inputDate}>
        <InputDate
          label={t('reports.paymentOrder.modal.input')}
          onChange={(e) => setDate(e)}
          dataCy={'cypress-new-payment-date-modal-input-date'}
        />
      </div>
      <div className={styles.button}>
        <Button
          onClick={handleAddPaymentDate}
          title={t('reports.paymentOrder.modal.title')}
          buttonType={'primary'}
          round
        />
      </div>
    </div>
  );
}
