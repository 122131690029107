import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { loadTitle } from '../../../helpers/changeTitle';
import { Breadcumb } from '../../../components/Breadcumb';
import styles from './styles.module.scss';
import { EmptySearchDocuments } from './EmptySearchDocuments';
import { SearchBarDocument } from './SearchBar';
import { ResponseDocumentItemType } from '../../../@types/searchDocument';
import { DocumentItem } from './DocumentItem';
import Show from '../../../components/Show';
import { Pagination } from './SearchBar/Pagination';
import { EmptyResults } from './EmptyResults';

type DocumentSearchProps = {
  title?: string;
};

const LOADING_ANIMATION = require('../../../assets/lotties/loading.json');

export function DocumentSearch(props: DocumentSearchProps) {
  const { pathname } = useLocation();
  const [documents, setDocuments] = useState<ResponseDocumentItemType>();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12} className={ styles.title }>
          <Grid item xs={3} sm={6}>
            <h1>{ t('documentSearch.title') }</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <SearchBarDocument searchActive={Boolean(documents?.result && documents?.result.length > 0)} setPage={setPage} page={page} setDocuments={setDocuments} setLoading={setLoading} query={query} setQuery={setQuery} />
        <Show if={documents?.result === undefined && !loading}>
          <EmptySearchDocuments />
        </Show>
        <Show if={documents?.result.length === 0 && !loading}>
          <EmptyResults />
        </Show>
        <Show if={loading}>
          <div className={styles.icon}>
            <Player
              loop={true}
              autoplay
              keepLastFrame={true}
              src={LOADING_ANIMATION}
              speed={2}
              className={styles.lottie}
            >
            <Controls visible={false} />
          </Player>
        </div>
        </Show>
        <Show if={!!(!loading && (documents && documents?.result.length > 0))}>
          <Pagination total={documents?.total_hits as any} page={page} setPage={setPage} />
          {documents && documents.result.map((document, index) => {
            return (document.highlight ? <DocumentItem total={documents?.total_hits as any} index={page === 1 ? (index) : (index + (page * 20)) } document={document}/> : <></>);
          })}
          <Pagination total={documents?.total_hits as any} page={page} setPage={setPage} topPage />
        </Show>

      </Grid>
    </div>
  );
}
