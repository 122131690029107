import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { loadTitle } from '../../../helpers/changeTitle';
import styles from './styles.module.scss';
import { Breadcumb } from '../../../components/Breadcumb';
import { InputText } from '../../../components/InputText';
import { AutoCompleteRemote } from '../../../components/SearchBar/FilterAdvanced/AutoComplete';
import ProcedureBoxService from '../../../services/procedureBoxService';
import Select from '../../../components/Select';
import { PaymentOrderReport } from '../../../@types/digitalProcesses/reports';
import ReportsService from '../../../services/reportsService';
import { OptionType } from '../../../@types/config';
import { InputDate } from '../../../components/InputDate';
import { Button } from '../../../components/Button';
import { OrderSelect } from '../Calculation/OrderSelect';
import { orderPaymentReport } from '../../../helpers/enums';
import { Table } from '../../../components/Table';
import { EmptyList } from '../EmptyList';
import { Loading } from '../Loading';
import { Modal } from '../../../components/Modal';
import { NewPaymentDate } from './NewPaymentDate';
import { ExportButton } from './ExportButton';
import SelectionBoxMenu from '../../../components/SelectionBoxMenu';
import { CheckBox } from '../../../components/CheckBox';
import Show from '../../../components/Show';
import { PaginationMenu } from '../../../components/PaginationMenu';
import { EitherFilter } from '../../Schedule/EitherFilter';

type PaymentOrdersProps = {
  title?: string;
};

interface QueryObject {
  [key: string]: any;
}
export function PaymentOrders(props: PaymentOrdersProps) {
  const {
    handleSubmit, control, setValue, unregister,
  } = useForm();

  const { pathname } = useLocation();
  const [data, setData] = useState<PaymentOrderReport[]>([]);
  const [currentData, setCurrentData] = useState<PaymentOrderReport[]>([]);
  const [query, setQuery] = useState('');
  const [orderBy, setOrderBy] = useState<string>('order_type asc');
  const [courts, setCourts] = useState<OptionType[]>([]);
  const [orderTypes, setOrderTypes] = useState<OptionType[]>([]);
  const [processCategories, setProcessCategories] = useState<OptionType[]>([]);
  const [status, setStatus] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [paymentOrderSelected, setPaymentOrderSelected] = useState<number>();
  const [openModalNewPaymentDate, setOpenModalNewPaymentDate] = useState<boolean>(false);
  const [paymentOrders, setPaymentOrders] = useState<PaymentOrderReport[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(4);

  const formatValue = (value: any): string => {
    if (typeof value === 'object' && value !== null && 'value' in value) {
      return value.value.toString();
    }
    if (typeof value === 'string' && value.includes('R$')) {
      return value.replace('R$ ', '');
    }
    return value.toString();
  };

  const clearQuery = (obj: QueryObject): string => {
    let queryString = '';
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (value !== undefined && value !== '') {
          const formattedValue = formatValue(value);
          queryString += `q[${key}]=${formattedValue}&`;
        }
      }
    }
    return queryString.slice(0, -1);
  };

  const columns = [
    t('reports.paymentOrder.table.part_information'),
    t('reports.paymentOrder.table.procedure_number'),
    t('reports.paymentOrder.table.spu_number'),
    t('reports.paymentOrder.table.order_type_i18n'),
    t('reports.paymentOrder.table.parent_category'),
    t('reports.paymentOrder.table.court_name'),
    t('reports.paymentOrder.table.payment_value'),
    t('reports.paymentOrder.table.period'),
    t('reports.paymentOrder.table.judicial_order_number'),
    t('reports.paymentOrder.table.origin_document_date'),
    t('reports.paymentOrder.table.judicial_order_deadline'),
    t('reports.paymentOrder.table.status'),
    '',
  ];

  useEffect(() => {
    loadTitle(props.title);
    ReportsService.getDependenciesPaymentOrder().then((response) => {
      setCourts(response.courts);
      setOrderTypes(response.order_types);
      setProcessCategories(response.process_categories);
      if (response.status) {
        const statuses = response.status.concat(
          {
            label: t('reports.paymentOrder.form.irrelevant'),
            value: 2,
          },
        );
        setStatus(statuses);
      }
    });
  }, []);

  const executeQuery = (orderString: string, queryString: string) => {
    setData([]);
    setLoading(true);
    if (typeof queryString === 'string' && queryString !== '') {
      ReportsService.getPaymentOrderList(orderString, queryString).then(
        (res) => {
          setData(res);
          setLoading(false);
        },
      );
    } else {
      setLoading(false);
    }
  };

  const handleOrder = (order: string) => {
    setOrderBy(order);
    executeQuery(order, query);
  };

  const submit = (form: any) => {
    const formattedForm = clearQuery(form);
    setQuery(formattedForm);
    executeQuery(orderBy, formattedForm);
  };

  const clearFilter = () => {
    window.location.reload();
  };

  const renderLoading = () => {
    if (loading) {
      return <Loading />;
    } if (query.length > 0) {
      return <EmptyList />;
    }
    return <></>;
  };

  const selectAll = () => {
    setPaymentOrders(data);
  };

  const unselectAll = () => {
    setPaymentOrders([]);
  };

  const selectPaymentOrder = (value: any) => {
    const paymentOrder: any = paymentOrders ? paymentOrders.find((f: any) => f.id === value.id) : [];
    if (!paymentOrder) {
      setPaymentOrders([...paymentOrders, value]);
    } else {
      const paymentOrdersNewState = paymentOrders.filter((f: any) => f.id !== value.id);
      setPaymentOrders(paymentOrdersNewState);
    }
  };

  const handlePage = (type: string, page: number) => {
    if (currentPage * itemsPerPage < data.length && type === 'nextPage') {
      setCurrentPage(page);
    } else if (page * itemsPerPage >= 1 && type === 'prevPage') {
      setCurrentPage(page);
    }
  };

  const handleItemsPerPage = (perPage: number) => {
    setItemsPerPage(perPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    const initialIndex = itemsPerPage * (currentPage - 1);
    const finalIndex = (itemsPerPage * currentPage);

    setCurrentData(data.slice(initialIndex, finalIndex));
    setPagesCount(Math.ceil(data.length / itemsPerPage));
  }, [data, itemsPerPage, currentPage]);

  const renderTable = () => {
    return (
      <>
        <Grid item className={styles.order} xs={12}>
          <OrderSelect
            label={t('reports.calculation.table.orderBy')}
            onChange={handleOrder}
            options={orderPaymentReport}
          />
          <PaginationMenu
            pageNumber={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={data.length}
            totalPages={pagesCount}
            onPerPageChange={(e) => handleItemsPerPage(Number(e))}
            handlePagination={handlePage}
            isDistributeProcess
          />
        </Grid>
        <Show if={paymentOrders.length > 0}>
          <ExportButton
            dataCy={'cypress-payment-order-export-buttom'}
            paymentOrderIds={paymentOrders.map((paymentOrder) => paymentOrder.id)}
          />
        </Show>
        <SelectionBoxMenu
          onClickAll={() => selectAll()}
          onClickNone={() => unselectAll()}
          selectedCount={paymentOrders.length}
          countTotal={data.length}
        />
        <Table>
          <thead>
            <tr>
              <th></th>
              {columns.map((column) => (
                <th>{column}</th>
              ))}
            </tr>
          </thead>
          {currentData.map((value) => (
            <tbody>
              <td>
                <CheckBox
                  value={!!paymentOrders.find((paymentOrder) => paymentOrder.id === value.id)}
                  onClick={() => selectPaymentOrder(value)}
                  color='var(--primary4)'
                  dataCy={'cypress-payment-order-select'}
                />
              </td>
              <td>{value.part_information}</td>
              <td>{value.procedure_number}</td>
              <td>{value.spu_number}</td>
              <td>{value.order_type_i18n}</td>
              <td>{value.parent_category}</td>
              <td>{value.court_name}</td>
              <td>{value.payment_value}</td>
              <td>{value.period}</td>
              <td>{value.judicial_order_number}</td>
              <td>{value.origin_document_date}</td>
              <td>{value.judicial_order_deadline}</td>
              <td data-cy={'cypress-payment-order-state'}>{value.state}</td>
              <td
                data-cy={'cypress-payment-order-new-payment-button'}
                onClick={() => {
                  setPaymentOrderSelected(value.id);
                  setOpenModalNewPaymentDate(true);
                }}
                className={styles.newPaymentDateButton}
              >
                <FaEdit/>
              </td>
            </tbody>
          ))}
        </Table>
      </>
    );
  };

  const handleSituation = (value: number) => {
    if (value !== 2) {
      setValue('state_eq', value);
    } else {
      unregister('state_eq');
    }
  };

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid item xs={3} sm={6}>
            <h1>{t('reports.paymentOrder.title')}</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.content}>
          <form onSubmit={handleSubmit(submit)}>
            <div className={styles.dataHeader}>
              <span>{t('reports.paymentOrder.form.tabs.data')}</span>
            </div>
            <Grid
              container
              columns={{ xs: 1, sm: 14, md: 14 }}
              spacing={2}
            >
              <Grid item xs={1} sm={3} md={3}>
                <Controller
                  control={control}
                  name="order_type_eq"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t(
                        'reports.paymentOrder.form.requisitionType.title',
                      )}
                      options={orderTypes}
                      placeholder={t(
                        'reports.paymentOrder.form.requisitionType.placeholder',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={3} md={3}>
                <AutoCompleteRemote
                  id="contributor"
                  title={t('reports.paymentOrder.form.beneficiary.title')}
                  placeholder={t(
                    'reports.paymentOrder.form.beneficiary.placeholder',
                  )}
                  getUrl={ProcedureBoxService.getContributors}
                  setData={(e) => setValue('interested_part_eq', e.value)}
                />
              </Grid>
              <Grid item xs={1} sm={3} md={3}>
                <Controller
                  control={control}
                  name="process_number_eq"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      dataCy={'cypress-payment-order-process-number'}
                      label={t(
                        'reports.paymentOrder.form.processNumber.title',
                      )}
                      placeholder={
                        t('reports.paymentOrder.form.processNumber.placeholder')
                      }
                      classNameContainer={styles.inputText}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={3} md={3}>
                <Controller
                  control={control}
                  name="spu_number_eq"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={
                        t('reports.paymentOrder.form.spuNumber.title')
                      }
                      placeholder={
                        t('reports.paymentOrder.form.spuNumber.placeholder')
                      }
                      classNameContainer={styles.inputText}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={3} md={3}>
                <Controller
                  control={control}
                  name="process_category_eq"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('reports.paymentOrder.form.object.title')}
                      options={processCategories}
                      placeholder={t(
                        'reports.paymentOrder.form.object.placeholder',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={3} md={3}>
                <Controller
                  control={control}
                  name="payment_order_court_id_eq"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('reports.paymentOrder.form.vara.title')}
                      options={courts}
                      placeholder={t(
                        'reports.paymentOrder.form.vara.placeholder',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={3} md={3}>
                <Controller
                  control={control}
                  name="state_eq"
                  render={({ field }) => (
                    <EitherFilter
                      id="state_eq_id"
                      title={t('reports.paymentOrder.form.isPaid.title')}
                      itens={status}
                      onClick={(e) => handleSituation(e)}
                      defaultValue={2}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div className={styles.valuesHeader}>
              <span>{t('reports.paymentOrder.form.tabs.period')}</span>
            </div>
            <Grid
              container
              columns={{ xs: 1, sm: 10, md: 10 }}
              spacing={4}
              className={styles.containerPeriod}
            >
              <Grid item columns={{ xs: 1, sm: 4, md: 4 }}>
                <span>{t('reports.paymentOrder.form.period.title')}</span>
                <Grid container spacing={2}>
                  <Grid item>
                    <Controller
                      control={control}
                      name="period_initial_gteq"
                      render={({ field }) => (
                        <InputDate
                          {...field}
                          label={''}
                          placeholder={t('reports.paymentOrder.form.date.initial')}
                          fixedValue
                          className={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name="period_final_lteq"
                      render={({ field }) => (
                        <InputDate
                          {...field}
                          label={''}
                          placeholder={t('reports.paymentOrder.form.date.final')}
                          fixedValue
                          className={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item columns={{ xs: 1, sm: 4, md: 4 }}>
                <span>{t('reports.paymentOrder.form.sefinDate.title')}</span>
                <Grid container spacing={2}>
                  <Grid item>
                    <Controller
                      control={control}
                      name="origin_document_date_gteq"
                      render={({ field }) => (
                        <InputDate
                          {...field}
                          label={''}
                          placeholder={
                            t('reports.paymentOrder.form.date.initial')
                          }
                          fixedValue
                          className={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name="origin_document_date_lteq"
                      render={({ field }) => (
                        <InputDate
                          {...field}
                          label={''}
                          placeholder={t('reports.paymentOrder.form.date.final')}
                          fixedValue
                          className={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              columns={{ xs: 1, sm: 10, md: 10 }}
              spacing={4}
              className={styles.containerPeriod}
            >
              <Grid item columns={{ xs: 1, sm: 4, md: 4 }}>
                <span>{t('reports.paymentOrder.form.responseDeadline.title')}</span>
                <Grid container spacing={2}>
                  <Grid item>
                    <Controller
                      control={control}
                      name="official_letter_deadline_gteq"
                      render={({ field }) => (
                        <InputDate
                          {...field}
                          label={''}
                          placeholder={
                            t('reports.paymentOrder.form.date.initial')
                          }
                          fixedValue
                          className={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name="official_letter_deadline_lteq"
                      render={({ field }) => (
                        <InputDate
                          {...field}
                          label={''}
                          placeholder={
                            t('reports.paymentOrder.form.date.final')
                          }
                          fixedValue
                          className={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item columns={{ xs: 1, sm: 4, md: 4 }}>
                <span>{t('reports.paymentOrder.form.value.title')}</span>
                <Grid container spacing={2}>
                  <Grid item>
                    <Controller
                      control={control}
                      name="payment_value_gteq"
                      render={({ field }) => (
                        <InputText
                          {...field}
                          placeholder={t('reports.general.gteq')}
                          currency
                          classNameContainer={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name="payment_value_lteq"
                      render={({ field }) => (
                        <InputText
                          {...field}
                          placeholder={t('reports.general.lteq')}
                          currency
                          classNameContainer={styles.inputText}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 1 }}
            >
              <Grid
                className={styles.filterButtons}
                container
                columns={{ xs: 1, sm: 1, md: 1 }}
                flexDirection={'row'}
                >
                <Grid className={styles.submit} item xs={8} sm={8} md={2}>
                  <Button
                    title={t('reports.general.clear')}
                    buttonType="default"
                    size="flat"
                    type="button"
                    round
                    onClick={clearFilter}
                  />
                  <Button
                    title={t('reports.general.filter')}
                    buttonType="primary"
                    size="flat"
                    disabled={loading}
                    round
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
          {data.length > 0 ? renderTable() : renderLoading()}
        </Grid>
      </Grid>
      <Modal
        open={openModalNewPaymentDate}
        onClose={() => setOpenModalNewPaymentDate(false)}
        size={'small'}
      >
        <NewPaymentDate
          setOpenModalNewPaymentDate={setOpenModalNewPaymentDate}
          paymentOrderSelected={paymentOrderSelected}
          data={data}
          setData={setData}
        />
      </Modal>
    </div>
  );
}
