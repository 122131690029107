import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { FaBan, FaCheckCircle } from 'react-icons/fa';
import { Batch } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import Show from '../../Show';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../../services/procedureBoxService';

type ResultsProps = {
  batch: Batch;
};

export function Results(props: ResultsProps) {
  const {
    openTramit,
    openDeleteFile,
    removeAllProcedures,
    addProcedures,
    openChangeFlux,
    openArchiveProcesses,
    openAttachFile,
    openJoinDocuments,
    openForceTramit,
    openCreateDocument,
    openForwardIpm,
    openSpu,
    openRegistrationSpu,
    openCreateSubProcess,
    openInitialPetition,
    openAttachProcedure,
    openUnarchiveModal,
    openIntermediaryPetition,
    openPaymentOrder,
    openEletronicCalculation,
    openDistributeProcesses,
    openShareProcedure,
    isShowProcedure,
  } = useContext(ProcedureActionsContext);
  const { removeBatch, addBatch } = useContext(BackgroundActionsContext);

  const tryAgain = () => {
    if (props.batch.type === 'tramit') {
      removeAllProcedures();
      addProcedures(props.batch.failedList);
      openTramit(props.batch.formContent);
    } else if (props.batch.type === 'archive') {
      removeAllProcedures();
      addProcedures(props.batch.failedList);
      openArchiveProcesses(props.batch.body);
    } else if (props.batch.type === 'removeDocument') {
      removeBatch(props.batch);
      openDeleteFile(props.batch.body.documents);
    } else if (props.batch.type === 'attachFiles') {
      addProcedures(props.batch.failedList);
      openAttachFile(props.batch.body.documents);
    } else if (props.batch.type === 'joinDocuments') {
      addProcedures(props.batch.failedList);
      openJoinDocuments(props.batch.body.documents);
    } else if (props.batch.type === 'forceTramit') {
      openForceTramit(props.batch.body.reasonValue);
    } else if (props.batch.type === 'changeFlux') {
      openChangeFlux(props.batch.body.flux, props.batch.body.observation);
    } else if (props.batch.type === 'prepareDocument') {
      addProcedures(props.batch.failedList);
      openCreateDocument(props.batch);
    } else if (props.batch.type === 'unarchive') {
      addProcedures(props.batch.failedList);
      openUnarchiveModal();
    } else if (props.batch.type === 'forwardIPM') {
      addProcedures(props.batch.failedList);
      openForwardIpm(props.batch);
    } else if (props.batch.type === 'spu') {
      addProcedures(props.batch.failedList);
      openSpu(props.batch.body.spuObject);
    } else if (props.batch.type === 'registrationSpu') {
      addProcedures(props.batch.failedList);
      openRegistrationSpu(props.batch.body.spuObject);
    } else if (props.batch.type === 'subProcedure') {
      addProcedures(props.batch.failedList);
      openCreateSubProcess();
    } else if (props.batch.type === 'initialPetition') {
      addProcedures(props.batch.failedList);
      openInitialPetition(props.batch);
    } else if (props.batch.type === 'intermediatePetition') {
      addProcedures(props.batch.failedList);
      openIntermediaryPetition(props.batch);
    } else if (props.batch.type === 'distributionManual' || props.batch.type === 'distributionAutomatic') {
      addProcedures(props.batch.failedList);
      openDistributeProcesses(props.batch.body);
    } else if (props.batch.type === 'eletronicCalculation') {
      addProcedures(props.batch.failedList);
      openEletronicCalculation();
    } else if (props.batch.type === 'attachProcedure') {
      addProcedures(props.batch.failedList);
      openAttachProcedure();
    } else if (props.batch.type === 'paymentOrder') {
      addProcedures(props.batch.failedList);
      openPaymentOrder(props.batch.body.paymentOrderObject);
    } else if (props.batch.type === 'shareProcedure') {
      addProcedures(props.batch.failedList);
      openShareProcedure();
    } else if (props.batch.type === 'suspensionCancellationTramit' || props.batch.type === 'suspensionCancellationRequest') {
      addProcedures(props.batch.failedList);
      const newBatch = props.batch;
      removeBatch(props.batch);

      newBatch.failedList = [];
      newBatch.id = (Math.random() + 1).toString(36).substring(1);
      delete newBatch.status;
      delete newBatch.redirectToProcedureBox;

      addBatch(newBatch);
    }
  };

  useEffect(() => {
    if (props.batch?.reload && props.batch?.failedList?.length === 0 && isShowProcedure) {
      const { pathname } = window.location;
      ProcedureBoxService.getProcedure(Number(pathname.split('/')[3])).then((resp) => {
        addProcedures([resp]);
      });
    }
  }, [props.batch]);

  const handleSuccessMessage = () => {
    if (props.batch.type === 'removeDocument') {
      return t(
        'procedureBox.actions.removeDocument.modal.alertLoading.results.success',
        {
          qnt_doc: props.batch.successList.length,
          plural_doc: props.batch.body.documents.length > 1 ? 's' : '',
          qnt_procedure: props.batch.procedures.length,
          plural_procedure: props.batch.procedures.length > 1 ? 's' : '',
        },
      );
    }
    if (props.batch.successList.length > 1) {
      return t(`procedureBox.actions.${props.batch.type}.modal.alertLoading.results.manyProcedures`, { number: props.batch.successList.length });
    }
    return t(`procedureBox.actions.${props.batch.type}.modal.alertLoading.results.oneProcess`, { number: props.batch.successList[0]?.process_number });
  };

  const handleFailedMessage = () => {
    if (props.batch.type === 'removeDocument') {
      return t(
        'procedureBox.actions.removeDocument.modal.alertLoading.results.error',
        {
          qnt_doc: props.batch.failedList.length,
          plural_doc: props.batch.body.documents.length > 1 ? 's' : '',
          qnt_procedure: props.batch.procedures.length,
          plural_procedure: props.batch.procedures.length > 1 ? 's' : '',
        },
      );
    }
    if (props.batch.successList.length > 1) {
      return t(`procedureBox.actions.${props.batch.type}.modal.alertLoading.results.manyProcedures`, { number: props.batch.successList.length });
    }
    return t(`procedureBox.actions.${props.batch.type}.modal.alertLoading.results.oneProcess`, { number: props.batch.successList[0]?.process_number });
  };

  return (
    <div className={ styles.container }>
      <Show if={props.batch.successList.length > 0}>
        <div className={styles.success}>
          <FaCheckCircle className={styles.icon}/>
          <span className={styles.text}>{t('procedureBox.actions.results.success')}</span>
          <span>{handleSuccessMessage()}</span>
        </div>
      </Show>

      <Show if={props.batch.failedList.length > 0}>
        <div className={styles.failed}>
          <div className={styles.title}>
            <FaBan className={styles.icon}/>
            <span className={styles.text}>{t('procedureBox.actions.results.error')}</span>
            <span>{handleFailedMessage()}</span>
          </div>
          <a onClick={tryAgain}>{t('general.tryAgain')}</a>
        </div>
      </Show>
    </div>
  );
}
