import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { InputText } from '../../InputText';
import Select from '../../Select';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { OptionType } from '../../SelectFilter';
import { AttachedDocument } from '../../../@types/config';
import { Button } from '../../Button';
import { Procedure } from '../../../@types/model';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Batch } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import Copy from './Copy';

type SpuDataProps = {
  procedure: Procedure;
  institutionOptions: OptionType[];
  priorityOptions: OptionType[];
  documents: AttachedDocument[];
};

export default function Form(props: SpuDataProps) {
  const { control, handleSubmit } = useForm();
  const [fieldOptions, setFieldOptions] = useState([]);
  const [copies, setCopies] = useState<number[]>([]);
  const { setModal } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const setSpuFields = (event: any) => {
    setFieldOptions([]);
    ProcedureBoxService.getSpuFields(event.value)
      .then((res) => {
        if (res.json) {
          const options = res.json.map((option: any) => ({ label: option[0], value: option[1] }));
          setFieldOptions(options);
        }
      });
  };

  const submit = (values: any) => {
    const id = (Math.random() + 1).toString(36).substring(1);
    const batch: Batch = {
      id,
      procedures: [props.procedure],
      type: 'tramitSpu',
      count: 1,
      successList: [],
      failedList: [],
      body: { ...values, copies, documents: props.documents },
    };

    addBatch(batch);
    setModal(undefined);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={styles.header}>
        <span>{t('procedureBox.actions.spu.modal.headers.destined')}</span>
        <hr/>
      </div>
      <Grid container xs={1} sm={8} md={12} spacing={2}>
        <Grid item xs={1} sm={4} md={4}>
          <Controller
            control={control}
            name="institution"
            render={({ field }) => (
              <Select
                {...field}
                dataCy={'cypress-institution-select-form-spu'}
                label={t('procedureBox.actions.spu.modal.form.institution')}
                tooltip={t('procedureBox.actions.spu.modal.documentData.tooltip.institution')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.institutionPlaceholder')}
                options={props.institutionOptions}
                onChange={setSpuFields}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="field"
            render={({ field }) => (
              <Select
                returnValue
                {...field}
                dataCy={'cypress-field-select-form-spu'}
                label={t('procedureBox.actions.spu.modal.form.field')}
                tooltip={t('procedureBox.actions.spu.modal.documentData.tooltip.field')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.fieldPlaceholder')}
                options={fieldOptions}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={4} md={4}>
          <Controller
            control={control}
            name="priority"
            render={({ field }) => (
              <Select
                returnValue
                {...field}
                dataCy={'cypress-priority-select-form-spu'}
                label={t('procedureBox.actions.spu.modal.form.priority')}
                tooltip={t('procedureBox.actions.spu.modal.documentData.tooltip.priority')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.priorityPlaceholder')}
                options={props.priorityOptions}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={8} md={8} className={styles.observation}>
          <Controller
            control={control}
            name="observation"
            render={({ field }) => (
              <InputText
                {...field}
                dataCy={'cypress-observation-input-text-form-spu'}
                label={t('procedureBox.actions.spu.modal.documentData.observation.label')}
                placeholder={t('procedureBox.actions.spu.modal.documentData.observation.placeholder')}
                required
              />
            )}
          />
        </Grid>
      </Grid>
      <div className={styles.header}>
        <span>{t('procedureBox.actions.spu.modal.headers.copy')}</span>
        <hr/>
      </div>
      <Copy
        control={control}
        setCopies={setCopies}
        institutionOptions={props.institutionOptions}
      />
      <div className={styles.button}>
        <Button
          title={t('procedureBox.actions.spu.sendOficialLetter')}
          buttonType='primary'
          type='submit'
          size='flat'
          round
        />
      </div>
    </form>
  );
}
