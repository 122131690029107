import Axios from '../config/api';

export default class NewProcessService {
  public static async getAllFluxes() {
    return Axios.get('api/procedures/all_fluxes')
      .then((res) => res.data);
  }

  public static async getNewProcedureData() {
    return Axios.get('api/process_registrations/new')
      .then((res) => res.data);
  }

  public static async createProcedureRegistration(procedure: any) {
    return Axios.post('api/process_registrations', procedure)
      .then((res) => res.data);
  }

  public static async getEditProcedure(id: number) {
    return Axios.get(`api/procedures/${id}/edit`)
      .then((res) => res.data);
  }

  public static async updateProcedureRegistration(procedure: any) {
    return Axios.post('api/process_registrations/re_registration', procedure)
      .then((res) => res.data);
  }

  public static async getNewConsultancyProcessData() {
    return Axios.get('api/consultancy_processes/new')
      .then((res) => res.data);
  }

  public static async createConsultancyProcess(procedure: any) {
    return Axios.post('api/consultancy_processes', procedure)
      .then((res) => res.data);
  }

  public static async editConsultancyProcess(procedure: any) {
    return Axios.post('api/consultancy_processes/re_registration', procedure)
      .then((res) => res.data);
  }

  public static async createOrigin(origin: string) {
    return Axios.post(`api/consultancy_processes/create_origin?origin_name=${origin}`)
      .then((res) => res.data);
  }

  public static async createSubject(subject: string) {
    return Axios.post(`api/consultancy_processes/create_subject?subject_name=${subject}`)
      .then((res) => res.data);
  }

  public static async getNewJudicialProcessData(fluxId: number) {
    return Axios.get(`api/judicial_processes/new?flux_id=${fluxId}`)
      .then((res) => res.data);
  }

  public static async getJudicialProcessExist(processNumber: any) {
    return Axios.get(`api/judicial_processes/judicial_process_exist?judicial_process_number=${processNumber}`)
      .then((res) => res.data);
  }

  public static async createJudicialProcess(procedure: any) {
    return Axios.post('api/judicial_processes', procedure)
      .then((res) => res.data);
  }

  public static async editJudicialProcess(procedure: any) {
    return Axios.post('api/judicial_processes/re_registration', procedure)
      .then((res) => res.data);
  }

  public static async editProurmaDeed(procedure: any) {
    return Axios.post('api/prourma_deeds/re_registration', procedure)
      .then((res) => res.data);
  }

  public static async getJudicialProcessClasses(competenceId: number) {
    return Axios.get(`api/judicial_processes/class_name_field_by_competence?judicial_competence_id=${competenceId}`)
      .then((res) => res.data);
  }

  public static async getJudicialProcessSubjects() {
    return Axios.get('api/judicial_processes/major_subject_by_subject_category')
      .then((res) => res.data);
  }

  public static async getNewAdministrativeProurmaProcessData() {
    return Axios.get('api/administrative_prourma_processes/new')
      .then((res) => res.data);
  }

  public static async createAdministrativeProurmaProcess(procedure: any) {
    return Axios.post('api/administrative_prourma_processes', procedure)
      .then((res) => res.data);
  }

  public static async editAdministrativeProurmaProcess(procedure: any) {
    return Axios.post('api/administrative_prourma_processes/re_registration', procedure)
      .then((res) => res.data);
  }

  public static async searchProcessToPetitioningInformations(processNumber: string) {
    return Axios.get(`api/petitioning_informations/search_by_process_number?process_number=${processNumber}`)
      .then((res) => res.data);
  }

  public static async getNewPetitioningInformationsData(flux_code: string) {
    return Axios.get('api/petitioning_informations/new', { params: { flux_code } })
      .then((res) => res.data);
  }

  public static async createProcessToPetitioningInformations(procedure: any) {
    return Axios.post('api/petitioning_informations', procedure)
      .then((res) => res.data);
  }

  public static async editPetitioningInformations(procedure: any) {
    return Axios.put('api/petitioning_informations', procedure)
      .then((res) => res.data);
  }

  public static async reRegistrationProcessToPetitioningInformations(procedure: any) {
    return Axios.post('api/petitioning_informations/re_registration', procedure)
      .then((res) => res.data);
  }

  public static async getNewPJARecordData() {
    return Axios.get('api/pja_records/new')
      .then((res) => res.data);
  }

  public static async createPJARecord(procedure: any) {
    return Axios.post('api/pja_records', procedure)
      .then((res) => res.data);
  }

  public static async editPJAProcess(procedure: any) {
    return Axios.post('api/pja_records/re_registration', procedure)
      .then((res) => res.data);
  }

  public static async getNewOfficialLetterSefinData() {
    return Axios.get('api/sefin_official_letters/new')
      .then((res) => res.data);
  }

  public static async createOfficialLetterSefin(procedure: any) {
    return Axios.post('api/sefin_official_letters', procedure)
      .then((res) => res.data);
  }

  public static async createSubjectProcedure(name: string | undefined, code: string, procedure_type: number) {
    return Axios.post('api/procedures/subject_procedures', { name, code, procedure_type }).then((res) => res.data);
  }

  public static async createCitationIntimation(body: any) {
    return Axios.post('api/citation_intimations', body)
      .then((res) => res.data);
  }

  public static async getSubjectsCnjs() {
    return Axios.get('api/citation_intimations/subjects')
      .then((res) => res.data);
  }

  public static async getSubjectByKeyList(value?: string) {
    return Axios.get(`api/petitioning_informations/autocomplete/subjects?q[name_cont]=${value}`)
      .then((res) => res.data);
  }

  public static async createJudicialOrigin(origin: string) {
    return Axios.post('api/judicial_processes/new_origin', { origin })
      .then((res) => res.data);
  }
}
