import { Modal as ModalMui } from '@mui/material';
import { t } from 'i18next';
import { ReactNode, useContext } from 'react';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Header } from './Header';
import styles from './styles.module.scss';

type ModalProps = {
  title?: string;
  children?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  size?: string;
};

export function Modal(props: ModalProps) {
  const { modal, setModal, setAlert } = useContext(ProcedureActionsContext);
  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    } else if (modal?.handleClose) {
      modal.handleClose();
    }
  };

  const handleClosedAlert = () => {
    setModal(undefined);
    setAlert(undefined);
  };

  const handleAlert = () => {
    if (!modal?.confirmationOnClose) {
      setAlert({
        visible: true,
        handleConfirm: () => handleClosedAlert(),
        title: t('procedureBox.actions.modalOutSideConfirmationAlert.title'),
        text: t('procedureBox.actions.modalOutSideConfirmationAlert.text'),
      });
    }
  };

  if (modal || props.open) {
    return (
      <ModalMui
        open={modal?.open || props.open || false}
        onClose={() => handleAlert()}
        disableAutoFocus={true}
      >
        <div
          className={`${styles.box} ${
            props.size ? (
              props.size === 'small'
                ? styles.small
                : props.size === 'medium'
                  ? styles.medium
                  : ''
            ) : (
              modal?.size === 'small'
                ? styles.small
                : modal?.size === 'medium'
                  ? styles.medium
                  : ''
            )
          }`}
        >
          <Header
            title={modal?.title || props.title}
            onClose={onClose}
            icon={modal?.icon}
            boxComponent={modal?.box}
            minimize={modal?.minimize}
            onMinimize={modal?.onMinimize}
            closeAutoFocus={modal?.closeAutoFocus}
          />
          <div className={styles.body}>
            {props.children ? props.children : modal?.children}
          </div>
        </div>
      </ModalMui>
    );
  }
  return <></>;
}
