import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { Header } from '../Header';
import styles from './styles.module.scss';
import Screen from '../../../helpers/screen';

import ProcedureBoxService from '../../../services/procedureBoxService';
import { Day } from './Day';
import { Details } from './Details';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import Show from '../../../components/Show';
import { ProcedureDetailsContext } from '../../../context/procedureDetailsContextProvider';
import { sendEvent } from '../../../helpers/googleAnalytics';

type TimelineProps = {
  procedureId: number;
  procedureStatus: string;
};

export function Timeline(props: TimelineProps) {
  const { setModal } = useContext(ProcedureActionsContext);
  const [progress, setProgress] = useState();
  const [progressStatus, setProgressStatus] = useState<'error' | 'loading'>();
  const [selected, setSelected] = useState<number | undefined>(0);
  const { batches } = useContext(BackgroundActionsContext);
  const { timeline, loadTimeline } = useContext(ProcedureDetailsContext);
  const isMobile = Screen.isMobile();

  const handleAnalytics = () => {
    sendEvent({
      event_name: 'timeline',
      label: `Clique em um detalhe da timeline do processo ${props.procedureId}`,
    });
  };

  const loadDetailsModal = (response: any) => {
    if (isMobile) {
      setModal({
        visible: true,
        open: true,
        size: 'larger',
        title: '',
        children: <Details progress={response} status={progressStatus} />,
        handleClose: () => setModal(undefined),
      });
    }
  };

  useEffect(() => {
    loadTimeline(props.procedureId);
  }, []);

  useEffect(() => {
    loadTimeline(props.procedureId);
  }, [batches]);

  useEffect(() => {
    setProgressStatus('loading');
    setProgress(undefined);
    if (selected) {
      ProcedureBoxService.getProgress(selected)
        .then((res) => {
          setProgress(res.data);
          loadDetailsModal(res.data);
        })
        .catch(() => {
          setProgressStatus('error');
        });
    }
  }, [selected]);

  return (
    <div className={ styles.container }>
      <Header title={t('procedureBox.processExtract.timeline.title')}/>

      <div className={styles.timeline}>
      <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
        <Grid item xs={1} sm={1} md={6}>
            <div className={styles.content}>
              {
                timeline.map((item, index) => (
                  <Day
                    day={item} last={index === timeline.length - 1}
                    first={index === 0}
                    selected={selected}
                    onClick={(id: number) => { handleAnalytics(); setSelected(id); }}
                    procedureStatus={props.procedureStatus}
                  />
                ))
              }
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={6}>
            <Show if={!isMobile}>
              <Details progress={progress} status={progressStatus} progressId={selected}/>
            </Show>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
