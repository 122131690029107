import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputText } from '../../../../components/InputText';
import { AutoCompleteRemote } from '../../../../components/SearchBar/FilterAdvanced/AutoComplete';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import styles from './styles.module.scss';
import Show from '../../../../components/Show';
import FormHelper from '../../../../helpers/form';
import { RadioGroup } from '../../../../components/RadioGroup';
import { ProcessTypeSuspension } from '../../../../helpers/enums';
import { Table } from '../../../../components/Table';
import { Hash } from '../../../../@types/digitalProcesses/timeline';
import { Errors, OptionType } from '../../../../@types/config';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Format from '../../../../helpers/format';
import Select from '../../../../components/Select';

type NewSuspensionCancellationRequestProps = {
  fluxId?: number;
  closeModal: () => void;
};

const LOADING_ANIMATION = require('../../../../assets/lotties/loading.json');

export function NewSuspensionCancellationRequest(props: NewSuspensionCancellationRequestProps) {
  const { addToasts } = useContext(ProcedureActionsContext);

  const [selectedInscriptions, setSelectedInscriptions] = useState<number[]>([]);
  const [reasons, setReasons] = useState<OptionType[]>([]);
  const [inscriptions, setInscriptions] = useState([]);
  const [inscriptionsLoading, setInscriptionsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    ProcedureBoxService.getNewSuspensionCancellationRequestData()
      .then((res) => {
        setReasons(res.data.suspension_cancellation_request_reason_id);
      });
    setValue('operation', 'suspension');
  }, []);

  const submit = (form: any) => {
    setLoading(true);

    const result = selectedInscriptions.reduce((map: any, object: any) => {
      const inscripMap = map;
      inscripMap[object] = { inscription_id: object };
      return inscripMap;
    }, {});
    const data = {
      operation: form.operation,
      contributor_id: form.contributor_id,
      process_number: form.process_number,
      suspension_cancellation_request_reason_id: form.suspension_cancellation_request_reason_id,
      requested_inscriptions_attributes: result,
    };
    ProcedureBoxService.createSuspensionCancellationRequest(data, props.fluxId)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();
          addToasts({
            type: 'success',
            text: FormHelper.customToastMessage(res.id),
          });
        }
        setLoading(false);
      }).catch((error) => {
        setErrors(error.data);
        setLoading(false);
      });
  };

  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <Player
          loop={true}
          autoplay
          keepLastFrame={true}
          src={LOADING_ANIMATION}
          speed={2}
          className={styles.lottie}
        >
          <Controls visible={false} />
        </Player>
      </div>
    );
  };

  const setFilter = (form: any) => {
    setInscriptionsLoading(true);
    const data = {
      contributor_id_eq: form.contributor_id,
      administrative_inscription_cont: form.administrative_inscription,
      cda_number_cont: form.cda_number,
      origin_key_cont: form.origin_key,
    };
    ProcedureBoxService.getInscriptions(data)
      .then((resp) => {
        setInscriptions(resp?.inscriptions || []);
        setInscriptionsLoading(false);
      });
  };

  const handleOnSelect = (value: string) => {
    const id = parseInt(value, 10);
    if (selectedInscriptions.includes(id)) {
      setSelectedInscriptions(selectedInscriptions.filter((item: any) => item !== id));
    } else {
      setSelectedInscriptions([...selectedInscriptions, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedInscriptions.length === inscriptions.length) {
      setSelectedInscriptions([]);
    } else {
      setSelectedInscriptions(inscriptions.map((el: Hash[]) => el[0].value));
    }
  };

  const renderTable = (data: any) => {
    const columns = data[0].map((val: Hash) => val.key);
    return (
      <div
        data-cy={'cypress-inscriptions-table-suspension-cancellation-request'}
        className={styles.table}
      >
        <Table>
          <thead>
            <tr>
              {columns.map((col: string, index: number) => (index !== 0 ? (
                  <th>
                    {t(
                      `procedureBox.newProcedure.newSuspensionCancellationRequest.form.table.${col}`,
                    )}
                  </th>
              ) : (
                  <th>
                    <input
                      type="checkbox"
                      data-cy={'cypress-inscription-checkbox-suspension-cancellation-request'}
                      onChange={handleSelectAll}
                      checked={selectedInscriptions.length === inscriptions.length}
                    />
                  </th>
              )))}
            </tr>
          </thead>

          {
            data.map((values: Hash[]) => <tbody>
              {
                values.map((v: Hash) => {
                  switch (v.key) {
                    case 'id':
                      return (
                        <td>
                          <input
                            value={v.value}
                            type="checkbox"
                            id="inscription_checkbox"
                            name="inscription_checkbox"
                            onChange={(e) => handleOnSelect(e.target.value)}
                            checked={selectedInscriptions.includes(v.value)}
                          />
                        </td>
                      );
                    case 'balance':
                      return <td>{`R$ ${Format.formatNumber(v.value)}`}</td>;
                    default:
                      return <td>{v.value}</td>;
                  }
                })
              }
            </tbody>)
          }
        </Table>
      </div>
    );
  };

  return (
    <div>
      <form className={ styles.container } onSubmit={handleSubmit(setFilter)}>
        <Header text={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.data')}/>
        <Grid
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <AutoCompleteRemote
              id='contributor'
              title={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.contributor.label')}
              placeholder={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.contributor.placeholder')}
              getUrl={ProcedureBoxService.getContributors}
              setData={(e) => setValue('contributor_id', e.value)}
              tooltip={t('procedureBox.filterAdvanced.tooltips.procedure')}
            />
            { FormHelper.renderErrorField('contributor_id', errors) }
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="administrative_inscription"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.administrativeInscription.label')}
                  placeholder={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.administrativeInscription.placeholder')}
                />
              )}
            />
            { FormHelper.renderErrorField('administrative_inscription', errors) }
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="cda_number"
              render={({ field }) => (
                <InputText
                  dataCy={'cypress-cda_number_input-suspension-cancellation-request'}
                  {...field}
                  label={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.cdaNumber.label')}
                  placeholder={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.cdaNumber.placeholder')}
                />
              )}
            />
            { FormHelper.renderErrorField('cda_number', errors) }
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="origin_key"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.originKey.label')}
                  placeholder={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.originKey.placeholder')}
                />
              )}
            />
            { FormHelper.renderErrorField('origin_key', errors) }
          </Grid>
        </Grid>
        <div className={styles.submit}>
          <Button
            dataCy={'cypress-filter-button-suspension-cancellation-request'}
            title={t('procedureBox.filterAdvanced.filter')}
            textCenter
            round
            buttonType='primary'
            size='flat'
            type='submit'
            disabled={loading || inscriptionsLoading}
          />
        </div>
      </form>
      {
        inscriptionsLoading
          ? renderLoading()
          : (
            <Show if={inscriptions.length > 0}>
              <form className={ styles.container } onSubmit={handleSubmit(submit)}>
                <Grid
                    item
                    xs={ 1 }
                    sm={ 5 }
                    md={ 4 }
                    className={styles.input}
                  >
                    <Controller
                      control={control}
                      name="process_number"
                      render={({ field }) => (
                        <InputText
                          {...field}
                          dataCy={'cypress-process-number-input-suspension-cancellation-request'}
                          label={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.processNumber.label')}
                          placeholder={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.processNumber.placeholder')}
                          required
                        />
                      )}
                    />
                    { FormHelper.renderErrorField('process_number', errors) }
                  </Grid>

                  <Grid
                    item
                    xs={ 1 }
                    sm={ 5 }
                    md={ 4 }
                    className={styles.input}
                  >
                    <Controller
                      control={control}
                      name="suspension_cancellation_request_reason_id"
                      render={({ field }) => (
                        <Select
                          {...field}
                          dataCy={'cypress-reason-select-suspension-cancellation-request'}
                          label={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.reason.label')}
                          placeholder={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.reason.placeholder')}
                          options={reasons}
                          returnValue
                          required
                        />
                      )}
                    />
                    { FormHelper.renderErrorField('suspension_cancellation_request_reason_id', errors) }
                  </Grid>

                  <Grid
                    item
                    className={ styles.filterItem }
                    xs={ 12 }
                    md={ 4 }
                    sm={ 4 }
                  >
                    <Controller
                      control={control}
                      name="operation"
                      render={({ field }) => (
                      <RadioGroup
                        {...field}
                        label={t('procedureBox.newProcedure.newSuspensionCancellationRequest.form.processType')}
                        options={ProcessTypeSuspension()}
                      />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={ 3 }
                    sm={ 15 }
                    md={ 12 }
                    className={styles.input}
                  >
                    <Controller
                      control={control}
                      name="registry"
                      render={({ field }) => (
                        renderTable(inscriptions)
                      )}
                    />
                    { FormHelper.renderErrorField('registry', errors) }
                  </Grid>
                  <div className={styles.submit}>
                    <Button
                      title={t('procedureBox.newProcedure.submit')}
                      textCenter
                      round
                      buttonType='primary'
                      size='flat'
                      type='submit'
                      dataCy={'cypress-submit-suspension-cancellation-request'}
                      disabled={loading || selectedInscriptions.length === 0}
                    />
                  </div>
              </form>
            </Show>
          )}
    </div>
  );
}
