import { ReactNode } from 'react';
import styles from './styles.module.scss';

type FilterAdvancedProps = {
  isOpen: boolean;
  closeFilter: () => void;
  children?: ReactNode;
};

export function FilterAdvanced(props: FilterAdvancedProps) {
  return (
    <div className={`${styles.container} ${!props.isOpen ? styles.openFilterAdvanced : ''}`}>
      {props.children}
    </div>
  );
}
