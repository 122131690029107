import { Grid } from '@mui/material';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Table } from '../../pages/ShowProcedure/Table';
import { InterestedPartsInfo } from '../../@types/model';
import Show from '../Show';

type ExpandedInterestedPartsProps = {
  interestedParts: InterestedPartsInfo[] | undefined;
};

export function ExpandedInterestedParts({ interestedParts }: ExpandedInterestedPartsProps) {
  if (!interestedParts) {
    return null;
  }

  const handleActiveParts = () => interestedParts?.filter((ip) => ip.participation.participation_type === 'active');
  const handlePassiveParts = () => interestedParts?.filter((ip) => ip.participation.participation_type === 'passive');
  const handleUndefinedParts = () => interestedParts?.filter((ip) => {
    const participationType = ip.participation.participation_type;
    return participationType !== 'passive' && participationType !== 'active';
  });

  const serializerInterestedParts = (interestedPartsList: InterestedPartsInfo[]) => {
    return interestedPartsList.map((ip: InterestedPartsInfo) => {
      return {
        rowTitle: ip.participation.description,
        processInformation: `${ip.name} - ${ip.document}`,
      };
    });
  };

  return (
    <Grid
      container
      columns={{ xs: 1, sm: 3, md: 3 }}
      className={styles.container}>

      <Grid
        item
        xs={1}
        sm={3}
        md={3}>
        <Table
          singleColumn={false}
          wantedColumns={[t('procedureBox.actions.expandedInterestedParts.modal.active')]}
          wantedRows={serializerInterestedParts(handleActiveParts())}
        />
      </Grid>

      <Grid
        item
        xs={1}
        sm={3}
        md={3}>
        <Table
          singleColumn={false}
          wantedColumns={[t('procedureBox.actions.expandedInterestedParts.modal.passive')]}
          wantedRows={serializerInterestedParts(handlePassiveParts())}
        />
      </Grid>

      <Show if={handleUndefinedParts().length > 0}>
        <Grid
          item
          xs={1}
          sm={3}
          md={3}>
          <Table
            singleColumn={false}
            wantedColumns={[t('procedureBox.actions.expandedInterestedParts.modal.undefinedPart')]}
            wantedRows={serializerInterestedParts(handleUndefinedParts())}
          />
        </Grid>
      </Show>
    </Grid>
  );
}
