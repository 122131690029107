import { PaymentOrderDependencies } from '../@types/digitalProcesses/reports';
import Axios from '../config/api';

export default class ReportsService {
  public static async getCalculationList(search: string, order: string, queryPage?: number, perPage?: number) {
    return Axios.get(`api/reports/request_calculations?${search}&q[s]=${order}&page=${queryPage || 1}&per_page=${perPage || 50}`)
      .then((res) => res.data);
  }

  public static async getCalculationDependencies() {
    return Axios.get('api/reports/request_calculations/dependencies')
      .then((res) => res.data);
  }

  public static async getExportCalculation(ids: number[]) {
    return Axios.post('api/reports/request_calculations/download_xls', { q: { id_in: ids } })
      .then((res) => res.data);
  }

  public static async getExportPaymentOrderXls(ids: number[]) {
    return Axios.post('api/reports/payment_orders/download_xls', { q: { id_in: ids } })
      .then((res) => res.data);
  }

  public static async getExportPaymentOrderPdf(ids: number[]) {
    return Axios.post('api/reports/payment_orders/download_pdf', { q: { id_in: ids } })
      .then((res) => res.data);
  }

  public static async getDependenciesPaymentOrder() {
    return Axios.get('/api/reports/payment_orders/dependencies')
      .then((res) => res.data as PaymentOrderDependencies);
  }

  public static async getPaymentOrderList(order: string, query: string) {
    return Axios.get(`/api/reports/payment_orders?q[s]=${order}&${query}`)
      .then((res) => res.data);
  }

  public static async getAudiencesList(query: string) {
    return Axios.get(`api/reports/audiences/load_audience?${query}`).then((res) => res.data);
  }

  public static async getPrintReport(query: string) {
    return Axios.get(`api/reports/audiences/print_report?${query}`).then((res) => res.data);
  }

  public static async getAudience() {
    return Axios.get('api/reports/audiences/load_select').then((res) => res.data);
  }

  public static async addPaymentDate(payment_order_interested_part_id: number, payment_date: string) {
    return Axios.post('/api/activities/payment_orders/add_payment', { payment_order_interested_part_id, payment_date })
      .then((res) => res.data);
  }
}
