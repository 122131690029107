import { useContext, useState } from 'react';
import { FaChevronDown, FaTimes } from 'react-icons/fa';
import { GenericMarker } from '../../../../@types/markers';
import { Marker } from '../../../../@types/model';
import { QueryContext } from '../../../../context/queryContext';
import { getMarkerColor, getMarkerLabel, isMarker } from '../../../../helpers/markers';
import { truncate } from '../../../../helpers/strings';
import styles from './styles.module.scss';
import CustomTooltip from '../../../CustomTooltip';

type SelectorProps = {
  marker: Marker | GenericMarker;
  fixedValue: boolean;
  procedureId: number;
};

export function Selector(props: SelectorProps) {
  const { removeMarkersProcedure, addMarker, removeMarker } = useContext(QueryContext);
  const [open, setOpen] = useState(false);

  const handleRemoveMarker = (marker: Marker | GenericMarker) => {
    if (isMarker(marker)) {
      if (marker.id !== undefined) {
        removeMarkersProcedure(marker.id, [props.procedureId]);
        removeMarker(marker);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const renderContent = () => {
    if (getMarkerLabel(props.marker).length > 15) {
      return (
        <CustomTooltip
          open={open}
          placement="right-start"
          title={ getMarkerLabel(props.marker) }
          >
            <span
              onMouseEnter={handleOpen}
              onMouseLeave={handleClose}
              className={ styles.title }
              onClick={() => addMarker(props.marker)}
            >
              {truncate(getMarkerLabel(props.marker), 11)}
            </span>
        </CustomTooltip>
      );
    }
    return (
      <span className={ styles.title } onClick={() => addMarker(props.marker)}>{ getMarkerLabel(props.marker) }</span>
    );
  };

  return (
    <div
      className={ `${styles.container} ${props.fixedValue ? styles.fixedValue : styles.customized}` }
      style={ { backgroundColor: getMarkerColor(props.marker) }}
    >
      <FaChevronDown className={ styles.selectButton }/>
      { renderContent() }
      <FaTimes onClick={() => handleRemoveMarker(props.marker)} className={ styles.removeButton } />
    </div>
  );
}
