import { useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { InputText } from '../../InputText';
import styles from './styles.module.scss';
import { Button } from '../../Button';

type AuthA1Props = {
  submit: (password: string) => void;
  goBack: () => void ;
};

export function AuthA1(props: AuthA1Props) {
  const keyI18n = 'procedureBox.actions.signerA1.auth';
  const [inputValue, inputChange] = useState('');

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t(`${keyI18n}.observation`)}
      </div>

      <Grid
        container
        columns={{ xs: 12, sm: 12, md: 12 }}
        className={styles.containerInput}
      >
        <Grid item xs={6} sm={6} md={6}>
          <InputText
            onChange={(value) => inputChange(value.currentTarget.value)}
            placeholder={t(`${keyI18n}.password.placeholder`)}
            label={t(`${keyI18n}.password.title`)}
            className={styles.input}
            required
            type={'password'}
            value={inputValue} />
        </Grid>
      </Grid>

      <footer className={styles.footerLogin}>
        <div className={styles.groupButtons}>
          <Button
            title={t(`${keyI18n}.return`)}
            textCenter
            round
            buttonType='default'
            size='flat'
            onClick={props.goBack} />

          <Button
            title={t(`${keyI18n}.send`)}
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={inputValue === ''}
            onClick={() => props.submit(inputValue)} />
        </div>
      </footer>
    </div>
  );
}
