import moment from 'moment';

export const getBackgroundColorSchedule = (dateString: string | null, checked: boolean | undefined, can_mofidy: boolean | undefined) => {
  const date = moment(dateString);
  const today = moment().endOf('day');
  const deadlineParsed = moment(date.format('YYYY-MM-DD')).endOf('day');

  const difference = deadlineParsed.diff(today, 'days', false);
  if (!can_mofidy) {
    return 'external';
  }
  if (checked) {
    return 'completed';
  }
  if (difference < 1) {
    return 'delayed';
  }
  if (difference <= 3) {
    return 'next';
  }

  return 'far';
};
