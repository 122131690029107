const UAParser = require('ua-parser-js/dist/ua-parser.min');

export const ClientUAInstance = new UAParser();

export function isFirefox() {
  return ClientUAInstance.getBrowser().name === 'Firefox';
}

export function isDesktop() {
  return ClientUAInstance.getOS().name === 'Windows' || ClientUAInstance.getOS().name === 'Mac OS' || ClientUAInstance.getBrowser().name === 'Electron';
}
