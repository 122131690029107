import { A1, A1Validate, SignerParams } from '../@types/a1';
import { ResponseDocuments } from '../@types/config';
import Axios from '../config/api';

export default class A1Service {
  public static async import(data: File, info: any, password: string) {
    const formData = new FormData();
    formData.append('file', data);
    formData.append('password', password);

    for (const key in info) {
      if (Object.prototype.hasOwnProperty.call(info, key)) {
        formData.append(`info[${key}]`, info[key]);
      }
    }

    return Axios.post(
      '/api/a1/import',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ).then((res) => res.data);
  }

  public static async show() {
    return Axios.get<A1>('/api/a1').then((res) => res.data);
  }

  public static async download() {
    return Axios.get('/api/a1/download', { responseType: 'blob' }).then((res) => res);
  }

  public static async delete() {
    return Axios.delete('/api/a1/').then((res) => res);
  }

  public static async signer(data: SignerParams) {
    return Axios.post(
      '/api/a1/sign_document',
      data,
    )
      .then((res) => res.data);
  }

  public static async checkDocument(control_id: number, doc: any) {
    return Axios.get('/api/a1/check_document', {
      params: {
        control_id,
        document: doc,
      },
    }).then((res) => res.data);
  }

  public static async validateCert() {
    return Axios.get<A1Validate>('/api/a1/check_validate_cert')
      .then((res) => res.data);
  }

  public static async getDocumentsToCosign(procedures: number[]) {
    return Axios.get<ResponseDocuments>(
      'api/a1/documents_to_cosign',
      { params: { procedure_ids: procedures } },
    ).then((res) => res.data);
  }
}
