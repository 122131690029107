import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import './styles/colors.scss';
import './styles/global.scss';
import { GOOGLE_ANALYTICS_TOKEN } from './config/constants';

ReactGA4.initialize(GOOGLE_ANALYTICS_TOKEN);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
