import { t } from 'i18next';
import {
  FaGripVertical,
  FaCalendarAlt, FaCheckCircle, FaFileAlt, FaFlag, FaUserAlt,
} from 'react-icons/fa';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useContext, useEffect, useState } from 'react';
import { AttachedDocument, ResponseAttachedDocument } from '../../@types/config';
import styles from './styles.module.scss';
import { Table } from '../Table';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import ProcedureBoxService from '../../services/procedureBoxService';
import Format from '../../helpers/format';
import { CheckBox } from '../CheckBox';
import CustomSwitch from '../CustomSwitch';
import { sendEvent } from '../../helpers/googleAnalytics';
import SelectionBoxMenu from '../SelectionBoxMenu';

type DragDropDocumentListProps = {
  documents?: AttachedDocument[];
  onChange: (documents: AttachedDocument[]) => void;
};

export function DroppableDocumentList(props: DragDropDocumentListProps) {
  const { proceduresSeleted } = useContext(ProcedureActionsContext);
  const [documentList, setDocumentList] = useState<AttachedDocument[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<AttachedDocument[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<AttachedDocument[]>([]);
  const [checked, setChecked] = useState<boolean>(true);

  const switchToSigned = () => {
    return (
      <div className={styles.switch}>
        <CustomSwitch
          onChange={(e) => { setChecked(e); }}
          checked={checked}
        />
        <span>
          {t('procedureBox.actions.removeDocument.modal.switch')}
        </span>
      </div>
    );
  };

  const draggableSelectedDocuments = (documents: AttachedDocument[]) => {
    if (selectedDocuments.length > 0) {
      const filteredSelectedDocument = documents.filter((document) => selectedDocuments.includes(document));
      if (JSON.stringify(filteredSelectedDocument) !== JSON.stringify(selectedDocuments)) {
        setSelectedDocuments(filteredSelectedDocument);
      }
    }
  };

  const selectAll = () => {
    setSelectedDocuments(filteredDocuments);
  };

  const unselectAll = () => {
    setSelectedDocuments([]);
  };

  const handleClick = (doc: AttachedDocument) => {
    if (selectedDocuments.includes(doc)) {
      setSelectedDocuments(selectedDocuments.filter((el) => el.file_id !== doc.file_id));
    } else {
      setSelectedDocuments([...selectedDocuments, doc]);
    }
  };

  const checkDocumentSelected = (document: AttachedDocument): boolean => {
    return !!selectedDocuments.find((item) => item.file_id === document.file_id && item.file_class === document.file_class);
  };

  const handleDragEnd = (event: any) => {
    if (!event.destination) {
      return;
    }

    const { source, destination } = event;
    const updatedDocuments = Array.from(filteredDocuments);
    const [movedItem] = updatedDocuments.splice(source.index, 1);
    updatedDocuments.splice(destination.index, 0, movedItem);
    setFilteredDocuments(updatedDocuments);
    draggableSelectedDocuments(updatedDocuments);
  };

  const handleLinkDownload = (filename: string) => {
    sendEvent({
      event_name: 'open_document',
      label: filename,
    });
  };

  const showDraggable = () => {
    return filteredDocuments.map((document: AttachedDocument, index: number) => (
      <Draggable
        key={document.file_id}
        draggableId={document.file_id.toString()}
        index={index}
      >
        {(draggableProvided, snapshot) => (
          <tbody
            data-cy={'cypress-document-list-spu'}
            className={snapshot.isDragging ? styles.dragDown : ''}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <td key={document.file_id}>
              <FaGripVertical />
            </td>
            <td>
              <CheckBox
                dataCy={'cypress-check-box-document-list'}
                value={checkDocumentSelected(document)}
                uncheckedColor='var(--neutral6)'
                color={'var(--primary4)'}
                isMarker={false}
                onClick={() => handleClick(document)}
              />
            </td>
            <td>
              <>
                <FaFileAlt
                  className={checkDocumentSelected(document) ? styles.selected : ''}
                />
                <a
                  className={checkDocumentSelected(document) ? styles.selected : ''}
                  href={document.url}
                  target='_blank'
                  data-cy={'document_name'}
                  onClick={() => handleLinkDownload(document.file_name)}
                >
                  {document.file_name}
                </a>
              </>
            </td>
            <td
              className={checkDocumentSelected(document) ? styles.selected : ''}
            >
              <>
                <FaCalendarAlt />
                {Format.formatDate(document.created_at)}
              </>
            </td>
            <td
              className={checkDocumentSelected(document) ? styles.selected : ''}
            >
              <>
                <FaUserAlt />
                {document.individual_name}
              </>
            </td>
            <td
              className={checkDocumentSelected(document) ? styles.selected : ''}
            >
              <>
                <FaFlag />
                {document.type}
              </>
            </td>
            <td
              className={checkDocumentSelected(document) ? styles.selected : ''}
            >
              {document.signed ? (
                <div className={styles.checkSigned}>
                  {document.signed}
                  <FaCheckCircle />
                </div>
              ) : null}
            </td>
            <td
              className={checkDocumentSelected(document) ? styles.selected : ''}
            >
              {document.cosigned}
            </td>
          </tbody>
        )}
      </Draggable>
    ));
  };

  const documentsTable = () => {
    return (
      <Table
        className={styles.documentTable}
      >
        <thead>
          <th></th>
          <th>
            <SelectionBoxMenu
              onClickAll={() => selectAll()}
              onClickNone={() => unselectAll()}
              selectedCount={selectedDocuments.length}
              countTotal={filteredDocuments.length}
              color={'var(--primary4)'}
            />
          </th>
          <th>{t('procedureBox.actions.removeDocument.modal.documentList.fileName')}</th>
          <th>{t('procedureBox.actions.removeDocument.modal.documentList.date')}</th>
          <th>{t('procedureBox.actions.removeDocument.modal.documentList.owner')}</th>
          <th>{t('procedureBox.actions.removeDocument.modal.documentList.type')}</th>
          <th>{t('procedureBox.actions.removeDocument.modal.documentList.signed')}</th>
          <th>{t('procedureBox.actions.removeDocument.modal.documentList.cosigned')}</th>
        </thead>
        {showDraggable()}
      </Table>
    );
  };

  useEffect(() => {
    props.onChange(selectedDocuments);
    draggableSelectedDocuments(filteredDocuments);
  }, [selectedDocuments]);

  useEffect(() => {
    setFilteredDocuments(documentList);
  }, [documentList]);

  useEffect(() => {
    if (documentList) {
      const documents = checked ? documentList : documentList.filter((document) => document.signed !== null);
      setFilteredDocuments(documents);
    }

    if (!checked) {
      setSelectedDocuments(selectedDocuments.filter((selectedDocument) => selectedDocument.signed !== null));
    }
  }, [checked]);

  useEffect(() => {
    if (props.documents !== undefined) {
      setDocumentList(props.documents);
    }
  }, [props.documents]);

  useEffect(() => {
    if (!props.documents) {
      ProcedureBoxService.getAttachedDocuments(proceduresSeleted[0].id)
        .then((res) => {
          const response: ResponseAttachedDocument = res;
          setDocumentList(response.archives);
        });
    }
  }, []);

  return (
    <div className={styles.container}>
      { switchToSigned() }
      <DragDropContext
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId="documentList">
          {(provided) => {
            return (
              <div
                ref={ provided.innerRef }
                { ...provided.droppableProps }
              >
                { documentsTable() }
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
