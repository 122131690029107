import ReactGA4 from 'react-ga4';
import { CurrentUser } from '../@types/currentUser';
import { GOOGLE_ANALYTICS_TOKEN } from '../config/constants';

interface GAEvent {
  event_name: string;
  label: string;
  [key: string]: string | number;
}

ReactGA4.initialize(GOOGLE_ANALYTICS_TOKEN);

const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);

export const sendEvent = (event: GAEvent) => {
  ReactGA4.gtag('event', event.event_name, {
    label: event.label,
    userId: currentUser.id,
  });
};
