import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { t } from 'i18next';

type ToastProps = {
  open: boolean;
  label: string | undefined;
  className?: string;
};

export default function Toast(props: ToastProps) {
  const [state, setState] = React.useState<any>({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert icon={false} className={props.className}>
          {t('home.dashboard.digitalProcesses.exportSpreadsheet.toast', {
            type: props.label,
          })}
        </Alert>
      </Snackbar>
    </Box>
  );
}
