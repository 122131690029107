import { ReactNode } from 'react';
import { FaTimes, FaMinus } from 'react-icons/fa';
import styles from './styles.module.scss';

type HeaderProps = {
  title?: string;
  onClose: () => void;
  icon?: ReactNode;
  boxComponent?: ReactNode;
  onMinimize?: () => {},
  closeAutoFocus?: boolean,
  minimize?: boolean;
  className?: string;
};
export function Header(props: HeaderProps) {
  return (
    <div className={`${styles.container} ${props.className}`}>
      <div className={ styles.actions }>
        { props.minimize && <a className={styles.minimize} onClick={props.onMinimize}>
          <FaMinus/>
        </a> }
        <button
          data-cy={'cypress-button-close-modal'}
          autoFocus={props.closeAutoFocus}
          className={styles.close}
          onClick={props.onClose}
        >
          <FaTimes/>
        </button>
      </div>
      <div className={styles.content}>
        {props.icon ? <div className={styles.icon}>{props.icon}</div> : props.boxComponent}
        <span className={styles.title}>{props.title}</span>
      </div>
    </div>
  );
}
