import {
  FaExclamationTriangle,
  FaRegCheckCircle,
} from 'react-icons/fa';
import styles from './styles.module.scss';

type TabProps = {
  title: string;
  onClick: () => void;
  ipmStateSelected: boolean
  disabled?: boolean;
  showIcon?: boolean | undefined;
  isMobile?: boolean;
  dataCy?: string;
};

export function Tab(props: TabProps) {
  const returnIconStatus = (status: boolean | undefined) => {
    return (
      status === undefined ? <></> : status
        ? <FaRegCheckCircle className={`${styles.icon} ${styles.success}`}/>
        : <FaExclamationTriangle className={`${styles.icon} ${styles.warning}`}/>
    );
  };

  return (
    <div className={styles.container}>
      <a
        onClick={props.onClick}
        data-cy={props.dataCy}
        className={
          ` ${styles.tab}
            ${props.isMobile && styles.tabResponsive}
            ${props.ipmStateSelected ? styles.selected : ''}
            ${props.disabled ? styles.disabled : ''}
          `
        }>
        {props.title}
        {returnIconStatus(props.showIcon)}
      </a>
    </div>
  );
}
