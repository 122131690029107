import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { FaRegQuestionCircle } from 'react-icons/fa';
import styles from './styles.module.scss';
import { OptionType } from '../../../../../@types/config';
import { Label } from '../../../../Label';
import CustomTooltip from '../../../../CustomTooltip';

type AutocompleteProps = {
  title: string,
  options: OptionType[];
  placeholder?: string;
  tooltip?: string;
  value?: OptionType;
  inputValue?: string;
  onChange?: (value: OptionType) => void;
  onChangeInput?: (value: any) => void;
  onClick?: () => void;
  labelBold?: boolean;
};

export function Remote(props: AutocompleteProps) {
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    popupOpen,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    options: props.options,
    value: props.value,
    getOptionLabel: (option) => option.label,
    clearOnBlur: true,
    clearOnEscape: true,
    onChange: (el, val) => {
      if (props.onChange && val) {
        props.onChange(val);
      }
    },
  });
  const [hasError, setHasError] = useState(false);

  const isNotEmpty = () => {
    return groupedOptions.length > 0;
  };

  const verifyField = () => {
    if (groupedOptions.length > 0) {
      setHasError(true);
    }
  };

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  useEffect(() => {
    verifyField();
  }, [props.inputValue]);

  return (
    <div className={styles.container}>
      <div className={ styles.info }>
        {props.title && <Label text={props.title} bold={props.labelBold}/>}
        {props.tooltip
          ? <CustomTooltip title={ props.tooltip }>
            <div className={styles.containerTag}>
              <FaRegQuestionCircle />
            </div>
            </CustomTooltip>
          : null}
      </div>
      <div className={styles.inputWrapper} {...getRootProps()}>
        <div
          className={`${styles.inputGroup} ${!isNotEmpty() && popupOpen && hasError ? styles.error : ' '}`}
          ref={setAnchorEl}>
          <input
            {...getInputProps()}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleClick();
              }
            }}
            placeholder={props.placeholder}
            onChange={props.onChangeInput}
            value={props.inputValue}
            className={`${styles.input} ${styles.open} }`}
          />
        </div>
      </div>
      { isNotEmpty() ? (
        <ul className={styles.listbox} {...getListboxProps()} style={{ width: anchorEl?.offsetWidth }}>
          {(groupedOptions as typeof props.options).map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option.label}</li>
          ))}
        </ul>
      ) : popupOpen && hasError
        ? <span className={styles.isEmpty}>
            {t('general.noOptions')}
          </span>
        : null
      }
    </div>
  );
}
