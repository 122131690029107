import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import moment from 'moment';
import { CitationIntimation } from '../../../../@types/model';
import styles from './styles.module.scss';
import { CardInfos } from '../../CardInfos';
import { Moment } from '../../../../config/moment';

type ExpandedProps = {
  citationIntimation: CitationIntimation;
  display: boolean;
};

export function Expanded(props: ExpandedProps) {
  const getGridProps = () => {
    if (props.display) {
      return { xs: 4, sm: 10, md: 16 };
    }
    return { xs: 4, sm: 10, md: 10 };
  };

  const handleContentCollumn = () => {
    return (
      <>
        <CardInfos
          title={t('procedureBox.procedureList.citationIntimation.content.title')}
          content={props.citationIntimation.content ? props.citationIntimation.content : t('procedureBox.procedureList.citationIntimation.content.without')} />
      </>
    );
  };

  const handleObservationCollumn = () => {
    return (
      <>
        <CardInfos
        title={t('procedureBox.procedureList.citationIntimation.observation.title')}
        content={props.citationIntimation.observation ? props.citationIntimation.observation : t('procedureBox.procedureList.citationIntimation.observation.without')} />
      </>
    );
  };

  const handleCreatedCollumn = () => {
    return (
      <>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureBox.procedureList.citationIntimation.time_inbox'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ value: Moment((props.citationIntimation.created_at)?.toLocaleString()).fromNow() }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureBox.procedureList.citationIntimation.recieved'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ value: moment(new Date(props.citationIntimation.created_at)).format('DD/MM/YY') }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureBox.procedureList.citationIntimation.exercise'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ value: props.citationIntimation.exercise }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureBox.procedureList.citationIntimation.individualName'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ value: props.citationIntimation.responsible }}
          />
        </span>
      </>
    );
  };

  const handleSubjectCollumn = () => {
    return (
      <>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureBox.procedureList.citationIntimation.type.title'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ value: t(`procedureBox.procedureList.citationIntimation.process_type.${props.citationIntimation.process_type}`) }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureBox.procedureList.citationIntimation.competence.title'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ value: t(`procedureBox.procedureList.citationIntimation.competence.${props.citationIntimation.competence}`) }}
          />
        </span>
      </>
    );
  };
  return (
    <Grid
      container
      columns={getGridProps()}
      className={ styles.container }
      onClick={ (e) => e.stopPropagation() }
    >
      <Grid
        item
        className={ `${styles.value} ${styles.procedureNumber}` }
        xs={ 2 }
        md={ 2 }
        sm={ 2 }
      >
        <div className={ styles.valueWithTitle }>
          { handleSubjectCollumn() }
        </div>
      </Grid>
      <Grid
        item
        className={ `${styles.value} ${styles.titleMobile}` }
        xs={ props.display ? 4 : 3 }
        md={ props.display ? 4 : 3 }
        sm={ props.display ? 4 : 3 }
      >
        <div className={ styles.valueWithTitle }>
        { handleCreatedCollumn() }
        </div>
      </Grid>
      <Grid
        item
        className={ `${styles.value} ${styles.titleMobile}` }
        xs={ 4 }
        md={ 2 }
        sm={ 4 }
        sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
      >
        <div className={styles.involvedPeople}>
          { handleObservationCollumn() }
        </div>
      </Grid>
      <Grid
        item
        onClick={ (e) => e.stopPropagation() }
        className={ `${styles.value} ${styles.titleMobile}` }
        xs={ props.display ? 3 : 2 }
        md={ props.display ? 3 : 2 }
        sm={ props.display ? 3 : 2 }
      >
        <div className={ styles.valueWithTitle }>
          { handleContentCollumn() }
        </div>
      </Grid>
    </Grid>
  );
}
