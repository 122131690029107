import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { RoundButton } from '../../../../components/RoundButton';
import FormHelper from '../../../../helpers/form';
import { Form } from './Form';
import styles from './styles.module.scss';

type CdasProps = {
  control: any;
  setValue: (name: string, value: string) => void;
  unregister: (name: string) => void;
  errors: any;
  data: any[];
};

type List = {
  id: string;
  cda?: any;
};

export function Cdas(props: CdasProps) {
  const [list, setList] = useState<List[]>([]);

  const handleAddForm = () => {
    const id = (Math.random() + 1).toString(36).substring(1);
    setList([...list, { id }]);
  };

  const handleDeleteForm = (id: string) => {
    let newList = [...list];
    newList = newList.filter((el) => el.id !== id);
    setList(newList);
  };

  useEffect(() => {
    if (props.data) {
      const ids = props.data.map((item) => { return { id: item.id, cda: item }; });
      setList(ids);
    }
  }, []);

  return (
    <div className={ styles.container } data-cy={'container-cda'}>
      {FormHelper.renderErrorField('petitioning_cdas_attributes', props.errors)}
      <div className={styles.nestedForm}>
        {
          list.map((item, index) => (
            <Form
              id={index}
              key={item.id}
              control={props.control}
              setValue={props.setValue}
              delete={() => handleDeleteForm(item.id)}
              unregister={props.unregister}
              data={item.cda}
            />
          ))
        }
        <RoundButton
          icon={<FaPlus />}
          backgroundColor="var(--primary4)"
          size='small'
          onClick={() => handleAddForm()}
        />
      </div>
    </div>
  );
}
