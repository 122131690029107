import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { FaPenNib } from 'react-icons/fa';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Alert } from '../Alert';

export function SignerA1Alert() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'signerA1').map((batch) => (
        <Alert
          icon={<FaPenNib />}
          title={t('procedureBox.actions.signerA1.modal.alertLoading.title')}
          text={<Trans
            i18nKey='procedureBox.actions.signerA1.modal.alertLoading.text'
            components={{ bold: <strong /> }}
          />}
          batch={batch}
        />
      ))}
    </div>
  );
}
