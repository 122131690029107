import { Grid } from '@mui/material';
import { t } from 'i18next';
import { DocumentItemType } from '../../../../@types/searchDocument';
import styles from './styles.module.scss';
import { DocumentRate } from './DocumentRate';

interface DocumentProps {
  document: DocumentItemType
  total: number;
  index: number;
}

export function DocumentItem(props: DocumentProps) {
  const calculatePercentage = (total: number, index: number): number => {
    if (index === 1) {
      return 1;
    } if (index === props.total) {
      return 0;
    }
    return 1 - (index / total);
  };

  const i18nkey = 'documentSearch.documents.item';
  return (
    <div className={ styles.container }>
      <Grid
        className={styles.item}
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <span>
              {t(`${i18nkey}.title`)} <a href={props.document.filepath || '#'} target="_blank">{props.document.filename}</a>
            </span>
            <span>{t(`${i18nkey}.document_type`)} {props.document.keywords}</span>
            <span>
              <DocumentRate rate={calculatePercentage(props.total, props.index)} />
            </span>
          </div>
          <div className={styles.headerContent}>
            <span>
            {t(`${i18nkey}.created_by`)} <b>{props.document.attached_by}</b> em <b>{props.document.attached_date}</b>
            </span>
            <span>{t(`${i18nkey}.flux`)} <b>{props.document.flux}</b>  {t(`${i18nkey}.subject`)} <b>{props.document.subject}</b></span>
            <span>
            {t(`${i18nkey}.process_number`)} <a target='_blank' href={`/procedure_box/show_procedure/${props.document.procedure_id}`}>{props.document.procedure_number}</a>
            </span>
          </div>
        </div>
        <hr />
        <div className={styles.highlight}>
          <span dangerouslySetInnerHTML={{ __html: props.document.highlight || '' }}>
          </span>
        </div>
      </Grid>
    </div>
  );
}
