import { useState } from 'react';
import { t } from 'i18next';
import { SelectedProcedures } from '../SelectedProcedures';
import EmptyActions from '../../assets/emptyActions.svg';
import styles from './styles.module.scss';
import { Procedure } from '../../@types/model';
import { Tab } from './Tab';
import { Default } from './Default';
import { Customize } from './Customize';

type MultipleActionsProps = {
  closeModal: () => void;
  procedure?: Procedure;
  section?: string;
};

export function MultipleActions(props: MultipleActionsProps) {
  const [stateSelected, setStateSelected] = useState(props.section || 'default');
  const i18nkey = 'procedureBox.actions.multipleActions';
  let renderView;

  switch (stateSelected) {
    case 'default':
      renderView = <Default closeModal={props.closeModal} />;
      break;
    case 'customize':
      renderView = <Customize />;
      break;
    default:
      break;
  }

  return (
    <div className={ styles.container }>
      <SelectedProcedures />

      <div className={styles.emptyListContainer}>
        <img src={EmptyActions}/>
        <div className={styles.containerText}>
          <p>{t(`${i18nkey}.observation.title`)}</p>
          <p>{t(`${i18nkey}.observation.subtitle`)}</p>
        </div>
      </div>

      <div className={styles.tabs}>
        <div className={styles.tabsContent}>
        <Tab
          onClick={() => setStateSelected('default')}
          ipmStateSelected={stateSelected === 'default'}
          title={t(`${i18nkey}.sections.default.title`)}
          dataCy={'tab-default'}
        />

        <Tab
          onClick={() => setStateSelected('customize')}
          ipmStateSelected={stateSelected === 'customize'}
          title={t(`${i18nkey}.sections.customize.title`)}
          dataCy={'tab-customize'}
        />
        </div>
      </div>

      { renderView }
    </div>
  );
}
