import { FaRegQuestionCircle } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryContext } from '../../../../context/queryContext';
import { Button } from '../../../Button';
import styles from './styles.module.scss';
import { Label } from '../../../Label';
import CustomTooltip from '../../../CustomTooltip';

type EitherProps = {
  id: string;
  title: string,
  tooltip?: string,
};

export function EitherFilter(props: EitherProps) {
  const { t } = useTranslation();

  const { hasAnyFilter, updateAdvancedFilter } = useContext(QueryContext);

  const [selectedArr, setSelectedArr] = useState([false, false, true]);

  useEffect(() => {
    if (hasAnyFilter === false) {
      setSelectedArr([false, false, true]);
    }
  }, [hasAnyFilter]);

  return (
    <div>
      <div className={styles.info}>
        <Label text={props.title} />
          {props.tooltip ? <CustomTooltip title={ props.tooltip }>
            <div className={styles.containerTag}>
              <FaRegQuestionCircle />
            </div>
            </CustomTooltip> : null}
      </div>
      <div className={`${styles.container}`}>
        <Button
          title={t('procedureBox.filterAdvanced.hasCdas.yes')}
          buttonType='default'
          size='small'
          round
          onClick={() => {
            updateAdvancedFilter(props.id, '1');
            setSelectedArr([true, false, false]);
          }}
          selected={selectedArr[0]}
        />
        <Button
          title={t('procedureBox.filterAdvanced.hasCdas.no')}
          buttonType='default'
          size='small'
          round
          onClick={() => {
            updateAdvancedFilter(props.id, '0');
            setSelectedArr([false, true, false]);
          }}
          selected={selectedArr[1]}
        />
        <Button
          title={t('procedureBox.filterAdvanced.hasCdas.irrelevant')}
          buttonType='default'
          size='small'
          round
          onClick={() => {
            updateAdvancedFilter(props.id, []);
            setSelectedArr([false, false, true]);
          }}
          selected={selectedArr[2]}
        />
      </div>
    </div>
  );
}
