import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import { Trans } from 'react-i18next';
import Screen from '../../../helpers/screen';
import { Button } from '../../Button';
import { DocumentList } from '../../DocumentList';
import { SelectedProcedures } from '../../SelectedProcedures';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { ProcedureCarousel } from '../../ForwardIpm/ProcedureCarousel';
import { AttachedDocument } from '../../../@types/config';
import { Batch, MultipleActionStep } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Login } from '../Login';
import { CurrentUser } from '../../../@types/currentUser';
import Show from '../../Show';
import Select from '../../Select';
import { documentsOrder } from '../../../helpers/enums';

type PetitionIntermediaryProps = {
  multipleActions?: MultipleActionStep;
};

export function PetitionIntermediary(props: PetitionIntermediaryProps) {
  const {
    proceduresSeleted, removeProcedures, setModal,
  } = useContext(ProcedureActionsContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const { addBatch } = useContext(BackgroundActionsContext);

  const [documentsList, setDocumentsList] = useState([]);
  const [documentsSeleted, setDocumentsSeleted] = useState<AttachedDocument[]>([]);
  const [procedureId, setProcedureId] = useState(proceduresSeleted[0].id);
  const [protocolNumber, setProtocolNumber] = useState('');
  const [visible, setVisible] = useState(true);
  const [rowsOrder, setRowsOrder] = useState('date asc');

  const isMobile = Screen.isMobile();

  const getIntermediatePetition = async () => {
    const response: any = await ProcedureBoxService.getIntermediatePetitionData(procedureId);

    const documents = response.documents_signed.map((document: any) => {
      return (
        {
          ...document,
          procedure_id: procedureId,
          type: document.type,
        }
      );
    });

    setProtocolNumber(response?.protocol);
    setDocumentsList(documents);
  };

  const getData = () => {
    const pjeData: any[] = [];
    proceduresSeleted.map((procedure: any) => {
      const documentsToPetition = documentsSeleted.filter((document: any) => document.procedure_id === procedure.id);
      const procedureToPetition = {
        procedure_id: procedure.id,
        attach_document_ids: documentsToPetition.map((doc: any) => doc.file_id),
      };

      pjeData.push(procedureToPetition);
    });

    return pjeData;
  };

  const handleSendIntermediatePetition = (password: string = '') => {
    const proceduresToPetition = getData();
    const body = {
      proceduresToPetition,
      password,
    };
    const procedures = proceduresSeleted;
    const type = 'intermediatePetition';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    setModal(undefined);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getIntermediatePetition();
    }, 200);
  }, [procedureId]);

  const openModalLogin = () => {
    setModal({
      visible: true,
      open: true,
      size: 'small',
      title: t('procedureBox.actions.initialPetition.modal.login.title'),
      children: <Login
                  submit={(password) => handleSendIntermediatePetition(password)}
                  goBack={() => setModal(undefined)} />,
      handleClose: () => setModal(undefined),
    });
  };

  const handlePetitionIntermediate = () => {
    if (currentUser.has_pje_user) {
      handleSendIntermediatePetition();
    } else {
      openModalLogin();
    }
  };

  return (
    <div className={styles.container}>
      <SelectedProcedures />

      <div className={styles.procedures}>
        <ProcedureCarousel
          isMobile={isMobile}
          onClick={(id) => setProcedureId(id)}
          procedures={proceduresSeleted}
        />
      </div>

      <div className={styles.header}>
        <p>{t('procedureBox.actions.intermediatePetition.modal.protocolNumber')}</p>
      </div>

      <div className={styles.protocolNumber}>
        <span>{protocolNumber}</span>
      </div>

      <header className={styles.header}>
        <p>{t('procedureBox.actions.intermediatePetition.modal.text')}</p>
      </header>

      <div className={styles.infos}>
        <span>{t('procedureBox.actions.intermediatePetition.modal.documentSelect')}</span>
        <span>{t('procedureBox.actions.intermediatePetition.modal.info')}</span>
      </div>

      <Show if={visible}>
        <div className={styles.alert}>
          <Alert severity="info">
            <div>
              <Trans
                i18nKey={t('procedureBox.actions.intermediatePetition.modal.signedMessage')}
                components={{ b: <b /> }}
              />
            </div>
            <div>
              <FaTimes
                className={styles.icon}
                onClick={() => setVisible(false)}
              />
            </div>
          </Alert>
        </div>
      </Show>

      <div className={styles.orderContainer}>
        <label>{t('procedureBox.processExtract.samePart.order.label')}</label>
        <Select
          label={''}
          dataCy={'order_select_petition_intermediary'}
          placeholder={t('procedureBox.processExtract.samePart.order.placeholder')}
          options={documentsOrder()}
          defaultValue={documentsOrder()[1]}
          onChange={(e) => setRowsOrder(e as any)}
          className={styles.orderBy}
          returnValue
          onlySelect
        />
      </div>

      <div className={styles.documents}>
        {
          documentsList.length > 0 && (
            <DocumentList
              documents={documentsSeleted}
              isMobile={isMobile}
              documentsList={documentsList}
              selectColor={'var(--primary'}
              sizeField
              removePagination
              onChange={(doc) => setDocumentsSeleted(doc)}
              order={rowsOrder}
            />
          )
        }
      </div>

      <footer>
        { documentsSeleted.length > 0 && (
          <Button
            dataCy={'cypress-button-submit-petition-intermediary'}
            title={t('procedureBox.actions.initialPetition.modal.button')}
            onClick={() => handlePetitionIntermediate()}
            size='flat'
            buttonType='primary'
            round
          />
        )}
      </footer>
    </div>
  );
}
