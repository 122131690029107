import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type ShowAttributeProps = {
  label: string;
  value: any;
};

export function ShowAttribute({ label, value } : ShowAttributeProps) {
  return (
    <>
      {typeof value === 'object' && value?.id ? (
        <div className={styles.item}>
          <div className={styles.title}>{label}</div>
          <div className={styles.link}>
          <Link target='_blank' to={`/procedure_box/show_procedure/${value.id}`}>{value.process_number}</Link>
          </div>
        </div>) : (
          <div className={styles.item}>
          <div className={styles.title}>{label}</div>
          <div className={styles.desc}>{value}</div>
        </div>
      )
      }
    </>
  );
}
