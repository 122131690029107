import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Button } from '../../Button';
import { DropFiles } from '../../DropFiles';
import { ListFiles } from '../ListFiles';
import { AttachDocument } from '../../../@types/config';
import Screen from '../../../helpers/screen';
import ProcedureBoxService from '../../../services/procedureBoxService';
import Show from '../../Show';
import CustomSwitch from '../../CustomSwitch';

type FormProps = {
  handleConfirm: () => void;
  onChange: (documents: AttachDocument[]) => void;
  files?: AttachDocument[];
  joinDocuments?: boolean;
};

export function Form(props: FormProps) {
  const [files, setFiles] = useState<AttachDocument[]>(props.files ?? []);
  const [checked, setChecked] = useState(false);
  const filesRef = useRef(files);
  filesRef.current = files;

  const isMobile = Screen.isMobile();

  useEffect(() => {
    props.onChange(files);
  }, [files]);

  const handleOnClick = () => {
    props.handleConfirm();
  };

  const removeFile = (filesParam: AttachDocument[]) => {
    if (filesParam.length > 1) {
      const newState: AttachDocument[] = [];

      filesRef.current.map((obj) => {
        if (!filesParam.find((doc: AttachDocument) => doc.id === obj.id)) {
          newState.push(obj);
        }
      });

      setFiles(newState);
    } else {
      const newState = filesRef.current.filter((el) => el.id !== filesParam[0].id);
      setFiles(newState);
    }
  };

  const handleDrop = async (filesParam: File[]) => {
    const verifiedFiles = filesParam.map(async (file) => {
      const signed = await ProcedureBoxService.verifySignedArchive(file);
      const newFile: AttachDocument = {
        id: (Math.random() + 1).toString(36).substring(1),
        file,
        sign: signed,
        type: ' ',
      };
      return newFile;
    });

    const newFiles = await Promise.all(verifiedFiles);

    setFiles((current) => [...current, ...newFiles]);
  };

  const handleSign = (document: AttachDocument) => {
    const newState = filesRef.current.map((obj) => {
      if (obj.id === document.id) {
        return {
          ...obj, sign: !document.sign,
        };
      }
      return obj;
    });
    setFiles(newState);
  };

  const handleType = (documents: AttachDocument[], type: string) => {
    const newState = filesRef.current.map((obj) => {
      const findDocument = documents.find((doc: AttachDocument) => doc.id === obj.id);
      if (findDocument) {
        return {
          ...obj, type,
        };
      }
      return obj;
    });

    setFiles(newState);
  };

  const handleProceeding = (documents: AttachDocument[], proceeding: number) => {
    const newState = filesRef.current.map((obj) => {
      const findDocument = documents.find((doc: AttachDocument) => doc.id === obj.id);
      if (findDocument) {
        return {
          ...obj, proceeding_id: proceeding,
        };
      }
      return obj;
    });
    setFiles(newState);
  };

  return (
    <div className={ styles.container }>
      <div className={(props.files?.length || 0) > 2 ? styles.scrollView : ''}>
        <div className={styles.header}>
          <span className={styles.title}>{t('procedureBox.actions.attachFiles.modal.label')}</span>
        </div>
        <Grid
          container
          columns={{ xs: 1, sm: 3, md: 3 }}
          className={styles.content}
        >
          <Grid
            item
            xs={1}
            sm={3}
            md={3}
          >
            <DropFiles onDrop={handleDrop} isMobile={isMobile} />
          </Grid>
          <Show if={files.length > 0}>
            <Grid
              item
              xs={1}
              sm={3}
              md={3}
            >
              <ListFiles
                documents={files}
                removeFile={removeFile}
                handleSign={handleSign}
                handleType={handleType}
                handleProceeding={handleProceeding}
                isMobile={isMobile}
                joinDocuments={props.joinDocuments}
                isChecked={checked}
              />
            </Grid>
          </Show>
        </Grid>
      </div>
      <Grid
        container
        columns={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.switchContainer}
        >
          <>
            <CustomSwitch
              data-cy={'cypress-list-files-switch'}
              className={styles.switch}
              onChange={(e) => { setChecked(e); }}
              checked={checked}
              disabled={files.length === 0}
            />
            <span className={styles.label}>
              {t('procedureBox.actions.prepareDocument.modal.form.switch')}
            </span>
          </>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.submit}
        >
          <Button
            title={t(`procedureBox.actions.${!props.joinDocuments ? 'attachFiles' : 'joinDocuments'}.modal.submit`)}
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={files.filter((file) => file.type === ' ').length > 0 || files.length === 0}
            onClick={handleOnClick} />
        </Grid>
      </Grid>
    </div>
  );
}
