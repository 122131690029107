import { Grid } from '@mui/material';
import { t } from 'i18next';
import {
  useContext, useRef, useState, useEffect,
} from 'react';
import { saveAs } from 'file-saver';
import { FaRegCheckCircle, FaTrashAlt } from 'react-icons/fa';
import { Button } from '../../Button';
import styles from './styles.module.scss';
import A1Service from '../../../services/a1Service';
import { HomeContext } from '../../../context/homeContext';
import { Toast } from '../../Toast';
import Format from '../../../helpers/format';
import { renderColor } from '../../../helpers/a1';
import Show from '../../Show';
import { A1, A1Validate } from '../../../@types/a1';
import { AuthA1 } from '../../SignerA1/AuthA1';

type ImportA1Props = {
  closeModal: () => void;
  a1Validate: A1Validate | null;
};

export function ImportA1(props: ImportA1Props) {
  const { setModal, addToasts } = useContext(HomeContext);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const downloadLink = useRef<HTMLAnchorElement | null>(null);
  const [file, setFile] = useState<File>();
  const [a1, setA1] = useState<A1 | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [validate, setValidate] = useState(props.a1Validate);

  const loadA1 = () => {
    A1Service.show().then((res) => {
      if (res) {
        setA1(res);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setA1(undefined);
    });
  };

  useEffect(() => {
    loadA1();
  }, []);

  const handleChange = (e: any) => {
    const data: File = e.target.files[0];
    setFile(data);
  };

  const importA1 = (password: string) => {
    if (file) {
      const info = {
        name: file.name,
      };

      A1Service.import(file, info, password).then((res) => {
        if (res) {
          addToasts({
            type: 'success',
            text: t('general.modalImportA1.add.success'),
          });
        }

        props.closeModal();
      }).catch((error) => {
        addToasts({
          type: 'error',
          text: error.data.error,
        });
      });
    }
  };

  const openModalAuthA1 = () => {
    setModal({
      visible: true,
      open: true,
      size: 'small',
      title: t('general.modalImportA1.submit'),
      children: <AuthA1
                  submit={(password) => importA1(password)}
                  goBack={() => setModal(undefined)} />,
      handleClose: () => setModal(undefined),
    });
  };

  const downloadA1 = () => {
    A1Service.download().then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const contentDisposition = response.headers['content-disposition'] || '';
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      const filename = fileNameMatch ? fileNameMatch[1] : 'cert.p12';

      if (fileNameMatch) {
        saveAs(blob, filename);
        addToasts({
          type: 'success',
          text: t('general.modalImportA1.download.success'),
        });
      }
    }).catch(() => {
      addToasts({
        type: 'error',
        text: t('general.modalImportA1.download.error'),
      });
    });
  };

  const downloadFile = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!file || !downloadLink.current) return;

    const url = URL.createObjectURL(file);
    downloadLink.current.href = url;
    downloadLink.current.download = file.name;
    downloadLink.current.click();
    URL.revokeObjectURL(url);
  };

  const deleteA1 = () => {
    A1Service.delete().then(() => {
      setFile(undefined);
      setA1(undefined);
      setValidate(null);

      addToasts({
        type: 'success',
        text: t('general.modalImportA1.delete.success'),
      });
    }).catch(() => {
      addToasts({
        type: 'error',
        text: t('general.modalImportA1.delete.error'),
      });
    });
  };

  const renderFilename = () => {
    if (file) {
      return (
        <a
          href='#'
          ref={downloadLink}
          onClick={downloadFile} className={styles.filename}>{file?.name}
        </a>
      );
    }

    if (a1) {
      return (
        <div className={styles.containerDelete}>
          <a href='#' onClick={downloadA1} className={styles.filename}>{a1?.info?.name}</a>
          <FaTrashAlt onClick={() => deleteA1()} />
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className={ styles.container }>
      <Grid
        container
        columns={{ xs: 4, sm: 12, md: 12 }}
      >
        <Grid item xs={2} sm={12} md={12}>
          <div className={styles.actions}>
            <Show if={a1 === undefined}>
              <Toast
                type='info'
                collapse
                text={t('general.modalImportA1.toast')}
                open
              />
            </Show>
            <p className={styles.text}>
              { !loading && a1 && (
                <>
                  <FaRegCheckCircle className={styles.success} />
                  {t('general.modalImportA1.updatedAt', { date: Format.formatDateWithHours(a1?.updated_at) })}
                </>
              )}
            </p>
            <p className={styles.text}>{t('general.modalImportA1.text')}</p>
            { renderFilename() }
            <div className={styles.buttons}>
              <Button
                title={
                  t(`general.modalImportA1.link.${(file !== undefined || a1 !== undefined) ? 'update' : 'add'}`)
                }
                buttonType='default'
                size='flat'
                className={styles.button}
                onClick={() => (fileInput.current ? fileInput.current.click() : {})}
                round
              />
              <input
                ref={fileInput}
                type="file"
                accept=".p12,.pfx"
                className={styles.input}
                onChange={handleChange}
              />
            </div>
            <footer>
              {
                validate ? (
                  <span
                    style={{ color: renderColor(validate.validate_in_days) }}
                    className={styles.validateDate}
                  >
                    {t('general.modalImportA1.validateDate', { date: Format.formatDate(validate.validate_date) })}
                  </span>
                ) : (<span/>)
              }

              <Button
                title={t('general.modalImportA1.submit')}
                textCenter
                round
                buttonType='primary'
                size='flat'
                disabled={file === undefined}
                onClick={() => openModalAuthA1()} />
            </footer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
