import { ReactNode } from 'react';
import styles from './styles.module.scss';

type TableProps = {
  dataCy?: string;
  children?: ReactNode;
  className?: string;
};

export function Table(props: TableProps) {
  return (
    <table
      data-cy={props.dataCy}
      className={`${styles.table} ${props.className}`}
    >
      {props.children}
    </table>
  );
}
