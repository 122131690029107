import {
  FaStreetView,
  FaCalendarAlt,
  FaBullhorn,
  FaComment,
  FaQuestion,
  FaFolder,
  FaHome,
  FaScroll,
  FaUserCog,
  FaSitemap,
  FaSearch,
} from 'react-icons/fa';
import { MdDocumentScanner } from 'react-icons/md';

import { CurrentUser } from '../@types/currentUser';
import { DEBITBOX_AGILIS_FRONT_URL } from '../config/constants';

const itemsModulesDigitalProcessReports = [
  {
    text: 'sidebar.modules.digital_process.reports.dashboard',
    level: 3,
    href: '/procedures/reports/dashboard',
  },
  {
    text: 'sidebar.modules.digital_process.reports.dashboard_health',
    level: 3,
    href: '/procedures/reports/dashboard_medic',
  },
  {
    text: 'sidebar.modules.digital_process.reports.distribution',
    level: 3,
    href: '/procedures/reports/distribution',
  },
  {
    text: 'sidebar.modules.digital_process.reports.productivity',
    level: 3,
    href: '/procedures/reports/productivity',
  },
  {
    text: 'sidebar.modules.digital_process.reports.petition',
    level: 3,
    href: '/procedures/reports/petitioning',
  },
  {
    text: 'sidebar.modules.digital_process.reports.audience',
    level: 3,
    href: '/procedures/reports/audience',
  },
  {
    text: 'sidebar.modules.digital_process.reports.search_cabinet',
    level: 3,
    href: '/procedures/reports/procedures_cabinet',
  },
  {
    text: 'sidebar.modules.digital_process.reports.search_judicial',
    level: 3,
    href: '/procedures/reports/judicial_procedures',
  },
  {
    text: 'sidebar.modules.digital_process.reports.court_orders',
    level: 3,
    href: '/procedures/reports/payment_orders',
  },
  {
    text: 'sidebar.modules.digital_process.reports.accounting_calculations',
    level: 3,
    href: '/procedures/reports/calculation',
  },
  {
    text: 'sidebar.modules.digital_process.reports.official_letter',
    level: 3,
    href: '/procedures/reports/judicial_orders',
  },
];

export const itemsModulesDigitalProcess = [
  {
    text: 'sidebar.modules.digital_process.home',
    icon: <FaHome size={18}/>,
    href: '/',
    level: 1,
  },
  {
    text: 'sidebar.modules.digital_process.procedure_box',
    icon: <FaFolder/>,
    href: '/procedure_box',
    level: 1,
  },
  {
    text: 'sidebar.modules.digital_process.procedure_collection',
    icon: <FaSearch/>,
    href: '/procedure_collection',
    level: 1,
  },
  {
    text: 'sidebar.modules.search.to_parts',
    icon: <MdDocumentScanner size={18}/>,
    href: '/search/document_search',
    level: 1,
  },
  {
    text: 'sidebar.modules.digital_process.reports.title',
    icon: <FaScroll/>,
    level: 1,
    subItems: itemsModulesDigitalProcessReports,
  },
  {
    text: 'sidebar.modules.calendar',
    icon: <FaCalendarAlt/>,
    href: '/schedule',
  },
];

export const activeDebtLogo = [
  {
    text: 'sidebar.modules.active_debt.title',
    href: DEBITBOX_AGILIS_FRONT_URL,
    logo: true,
  },
];

export const judicialFluxes = [
  'FLU0003',
  'FLU0004',
  'FLU0028',
  'FLU0060',
  'FLU0061',
  'FLU0033',
  'FLU0020',
  'FLU0095',
  'FLU0118',
  'FLU0013',
];

export const itemsSearch = [
  {
    text: 'sidebar.modules.search.detran',
    icon: <FaStreetView/>,
    href: '/search/detran',
  },
];

export const itemsSupport = [
  {
    text: 'sidebar.modules.support.suggestion_box',
    icon: <FaBullhorn/>,
  },
  {
    text: 'sidebar.modules.support.contact_us',
    icon: <FaComment/>,
  },
  {
    text: 'sidebar.modules.support.faq',
    icon: <FaQuestion/>,
    href: '/faq',
  },
];

export const itemsSettings = [
  {
    text: 'sidebar.modules.settings.user_management',
    icon: <FaUserCog/>,
    href: '/settings/user_management',
  },
  {
    text: 'sidebar.modules.settings.fluxes_management',
    icon: <FaSitemap/>,
    href: '/settings/fluxes_management',
  },
];

export const isActualResponsible = (individualName: string | undefined): boolean => {
  if (individualName) {
    const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
    return currentUser.name === individualName;
  }
  return false;
};

export const orderByOptions = [
  {
    label: 'Área (A-Z)',
    value: 'main_field asc',
  },
  {
    label: 'Área (Z-A)',
    value: 'main_field desc',
  },
  {
    label: 'Classificação (A-Z)',
    value: 'process_category_name asc',
  },
  {
    label: 'Classificação (Z-A)',
    value: 'process_category_name desc',
  },
  {
    label: 'Última Verificação (menos recente)',
    value: 'display_last_verification asc',
  },
  {
    label: 'Última Verificação (mais recente)',
    value: 'display_last_verification desc',
  },
];
