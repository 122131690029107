import { useEffect, useRef } from 'react';
import { GenericMarker } from '../../@types/markers';
import { Procedure } from '../../@types/model';
import { MarkerItem } from '../ProcedureList/Markers/Menu/MarkerItem';

import styles from './styles.module.scss';

interface MarkerContentProps {
  procedureId: number;
  procedures?: Procedure[];
  priorityMarkers?: GenericMarker[];
  pendencyMarkers?: GenericMarker[];
  handleClose: () => void;
  isOpen: boolean;
}

export function MarkerContent(props: MarkerContentProps) {
  const markerContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        markerContentRef.current
        && !markerContentRef.current.contains(event.target as Node)
      ) {
        props.handleClose();
      }
    };

    if (props.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.isOpen, props.handleClose]);

  if (props.isOpen) {
    return <div ref={markerContentRef} className={styles.container}>
      <MarkerItem
        onChange={props.handleClose}
        type='priority'
        options={props.priorityMarkers}
        procedureId={props.procedureId}
        procedures={props.procedures}
        open
      />
      <MarkerItem
        onChange={props.handleClose}
        type='pendency'
        options={props.pendencyMarkers}
        procedures={props.procedures}
        procedureId={props.procedureId}
        open
      />
      <MarkerItem
        onChange={props.handleClose}
        type='custom'
        procedures={props.procedures}
        procedureId={props.procedureId}
        open
      />
    </div>;
  }

  return <></>;
}
