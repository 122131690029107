import { t } from 'i18next';
import {
  FaArchive,
  FaEdit,
  FaFileAlt,
  FaFolder,
  FaHands,
  FaPaperclip,
  FaPaperPlane,
  FaFileInvoiceDollar,
  FaPenNib,
  FaTag,
  FaTrashAlt,
  FaBoxOpen,
  FaStickyNote,
  FaListAlt,
  FaClock,
  FaLink,
  FaSitemap,
  FaTimes,
  FaShareAlt,
  FaEnvelope,
  FaEnvelopeOpen,
  FaFileExcel,
  FaFileExport,
  FaEllipsisV,
} from 'react-icons/fa';
import { ImShuffle } from 'react-icons/im';
import LinkIcon from '@mui/icons-material/Link';
import {
  useContext, useState, useEffect, ReactNode,
} from 'react';
import { ThemeProvider, Menu as MenuAction } from '@mui/material';
import { RoundButton } from '../../../RoundButton';
import { RectangularButton, Option } from '../../../RectangularButton';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Show from '../../../Show';
import { isVisible } from '../../../../helpers/actionsMenu';
import { SubProcedure } from '../SubProcedure';
import { Procedure } from '../../../../@types/model';
import { MarkerContent } from '../../../MarkerContent';
import { checkSendSpu } from '../../../../helpers/spu';
import { BackgroundActionsContext } from '../../../../context/backgroundActions';
import { Batch } from '../../../../@types/backgroundActions';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import { judicialFluxes } from '../../../../helpers/menuItems';
import { isProcedureCollection } from '../../../../helpers/routeTranslate';
import { CurrentUser } from '../../../../@types/currentUser';
import { ProcedureDetailsContext } from '../../../../context/procedureDetailsContextProvider';
import { sendEvent } from '../../../../helpers/googleAnalytics';
import { menuTheme } from '../../../../styles/customThemes/menuTheme';
import { Icon } from './Icon';

type ItemProps = {
  procedure?: Procedure;
};

type BatchTypes = 'suspensionCancellationTramit' | 'suspensionCancellationRequest';

export function Menu(props: ItemProps) {
  const disabled = false;
  const {
    openSigner,
    openCosigner,
    openReadyForProgress,
    openTramit,
    openDistributeProcesses,
    openChangeFlux,
    openEditProcedure,
    openCreateDocument,
    openAttachFile,
    openDeleteFile,
    openArchiveProcesses,
    openDeleteProcesses,
    handleForceTramit,
    openForwardIpm,
    openSpu,
    openRegistrationSpu,
    openJoinDocuments,
    openUnarchiveModal,
    openNotesModal,
    proceduresSeleted,
    openInitialPetition,
    openIntermediaryPetition,
    openMultipleActions,
    openDeadline,
    removeProcedures,
    openAttachProcedure,
    openShareProcedure,
    openUnattachProcedure,
    openAttachProcedureCitationIntimation,
    setAlert,
    seenNotSeen,
    openSignerA1,
    hasSpuData,
    setHasSpuData,
  } = useContext(ProcedureActionsContext);
  const box = props.procedure?.box || proceduresSeleted[0].box;
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const [open, setOpen] = useState(false);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [disabledInitialPetition, setDisabledInitialPetition] = useState(true);
  const [disabledDeadline, setDisabledDeadline] = useState(true);
  const [, setDisabledSpuRegistration] = useState(false);
  const [disabledIntermediatePetition, setDisabledIntermediatePetition] = useState(true);
  const [canViewSuspensionCancellationTramit, setCanViewSuspensionCancellationTramit] = useState(false);
  const [canViewSuspensionCancellationRequest, setCanViewSuspensionCancellationRequest] = useState(false);
  const [iconSeenNotSeen, setIconSeenNotSeen] = useState<ReactNode>();
  const [seenNotSeenTip, setSeenNotSeenTip] = useState('');
  const proceduresSeletedIds = proceduresSeleted.map((procedure: Procedure) => procedure.id);
  const { timeline } = useContext(ProcedureDetailsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenSpu = () => {
    if (proceduresSeleted.length === 1 && (checkSendSpu(proceduresSeleted[0].flux.code) || hasSpuData)) {
      openSpu(undefined);
    }
  };

  const handleOpenRegistrationSpu = () => {
    if (proceduresSeleted.length === 1 && checkSendSpu(proceduresSeleted[0].flux.code)) {
      openRegistrationSpu(undefined);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const verifyFluxes = () => {
    const filingFlux = props.procedure?.flux.code === 'FLU0020';
    const hasAccess = ['FLU0003', 'FLU0013', 'FLU0033', 'FLU0028', 'FLU0061', 'FLU0060'];
    const fluxCode = props.procedure?.flux.code || '';
    const fluxCodeSelected = proceduresSeleted[0]?.flux.code;

    if (filingFlux || proceduresSeleted[0]?.flux.code === 'FLU0020') {
      setDisabledInitialPetition(false);
    } else {
      setDisabledInitialPetition(true);
    }

    if (hasAccess.includes(fluxCode) || hasAccess.includes(fluxCodeSelected)) {
      setDisabledIntermediatePetition(false);
    } else {
      setDisabledIntermediatePetition(true);
    }
  };

  const isDisabled = (disabledParam: boolean) => {
    return disabledParam ? styles.disabledItem : '';
  };

  const showDeadline = (boxParam: string) => {
    return boxParam === 'inbox' || proceduresSeleted.length === 1;
  };

  const setSeenNotSeenProps = (procedures: Procedure[]) => {
    const wasSeenProceduresValues = procedures.map((pr) => (pr.was_seen));
    const IconDefinition = wasSeenProceduresValues.every((value) => value) ? true : !!wasSeenProceduresValues.some((value) => value);
    if (IconDefinition) {
      setIconSeenNotSeen(<FaEnvelope/>);
      setSeenNotSeenTip('markLikeUnread');
    } else {
      setIconSeenNotSeen(<FaEnvelopeOpen/>);
      setSeenNotSeenTip('markLikeRead');
    }
  };

  const verifySpuRegistrationExist = () => {
    if (proceduresSeleted.length > 0) {
      ProcedureBoxService.verifiySpuRegistration(proceduresSeleted[0].id).then((res: any) => {
        setDisabledSpuRegistration(res);
      });
    }
  };

  const handleSendEvent = (type: string, name?: string) => {
    sendEvent({
      event_name: name || 'next_step',
      label: type,
    });
  };

  useEffect(() => {
    verifyFluxes();
  }, []);

  useEffect(() => {
    verifySpuRegistrationExist();
  }, [proceduresSeleted, timeline]);

  const signer: Option[] = [
    {
      title: 'signer',
      action: () => openReadyForProgress('signer', () => { openSigner(); handleSendEvent(t('procedureBox.actions.sign_document.title'), t('analytics.steps.sign')); }),
      disabled: false,
    },
    {
      title: 'signerA1',
      action: () => openSignerA1(),
      disabled: false,
    },
    {
      title: 'cosigner',
      action: () => openReadyForProgress('cosigner', openCosigner),
      disabled: false,
    },
  ];

  const handleConfirm = (procedures: any[]) => {
    const body = {
      waiting: true,
    };

    const type = 'attachExtract';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
    };
    addBatch(batch);
    removeProcedures(procedures);
  };

  const handleAttachExtract = () => {
    const procedures = proceduresSeleted;
    const procedureNumbers = procedures.map((procedure) => procedure.process_number);

    setAlert({
      visible: true,
      title: t('procedureBox.actions.attachExtract.modal.alert.title'),
      text: procedureNumbers.join(', '),
      type: 'warning',
      cancelText: t('procedureBox.actions.attachExtract.modal.alert.cancel'),
      confirmText: t('procedureBox.actions.attachExtract.modal.alert.confirm'),
      handleConfirm: () => {
        setAlert(undefined);
        handleConfirm(procedures);
      },
    });
  };

  const suspensionCancellation = (batchType: BatchTypes) => {
    const procedures = proceduresSeleted;
    const id = (Math.random() + 1).toString(36).substring(1);
    const body = {
      waiting: true,
    };

    const batch: Batch = {
      id,
      procedures,
      type: batchType,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
    };
    addBatch(batch);
    removeProcedures(procedures);
  };

  const getCurrentProcedure = () => {
    return props.procedure || proceduresSeleted[0];
  };

  const handleSuspensionCancellation = (batchType: BatchTypes) => {
    const inscriptionsValue = getCurrentProcedure()?.inscriptions_total_value || 0;

    const title = (
      batchType === 'suspensionCancellationTramit' && inscriptionsValue > 50000
        ? t('procedureBox.actions.suspensionCancellationTramit.onlyTramitTitle')
        : t(`procedureBox.actions.${batchType}.modal.title`, { number: getCurrentProcedure()?.total_inscriptions })
    );

    setAlert({
      visible: true,
      title,
      type: 'warning',
      confirmText: t(`procedureBox.actions.${batchType}.modal.confirm`),
      cancelText: t(`procedureBox.actions.${batchType}.modal.cancel`),
      handleConfirm: () => {
        setAlert(undefined);
        suspensionCancellation(batchType);
      },
    });
  };

  const validateDistribute = () => {
    const procedure = getCurrentProcedure();
    const involvedPeople = procedure.involved_people.map((ip) => ip.name);
    return (involvedPeople.includes(currentUser.name) || procedure.individual_name === currentUser.name || procedure.status === 'archived');
  };

  const checkProceduresFluxes = () => {
    const procedures = proceduresSeleted;
    const codes = procedures.map((procedure) => (procedure.flux.code));
    if (codes.includes('FLU0003') || codes.includes('FLU0051')) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const procedureIds = proceduresSeleted.map((procedure: Procedure) => procedure.id);

    verifyFluxes();

    ProcedureBoxService.verifyPetitionInPje(procedureIds).then((res: boolean) => {
      setDisabledInitialPetition(res);
    });

    if (proceduresSeleted.length === 1) {
      ProcedureBoxService.canViewDeadline(proceduresSeleted[0].id, proceduresSeleted[0].box).then((res: boolean) => {
        setDisabledDeadline(res);
      });

      ProcedureBoxService.canViewSupensionCancellationTramitFunction(proceduresSeleted[0].id).then((res: boolean) => {
        setCanViewSuspensionCancellationTramit(res);
      });

      ProcedureBoxService.canViewSupensionCancellationRequestFunction(proceduresSeleted[0].id).then((res: boolean) => {
        setCanViewSuspensionCancellationRequest(res);
      });

      ProcedureBoxService.hasRegistrationInSpu(proceduresSeleted[0].id)
        .then((resp) => {
          setHasSpuData(resp);
        });
    }

    setSeenNotSeenProps(proceduresSeleted);
  }, [proceduresSeleted]);

  return (
  <>
    <div className={ styles.rightBorder }>
    <Show if={isVisible(box, 'seenNotSeen')}>
      <RoundButton
          dataCy={'cypress-button-seen-not-seen-menu'}
          className={ styles.button }
          onClick={() => { seenNotSeen(proceduresSeletedIds); handleSendEvent(t(`procedureBox.actions.seenNotSeen.${seenNotSeenTip}`)); }}
          icon={iconSeenNotSeen}
          tooltip={t(`procedureBox.actions.seenNotSeen.${seenNotSeenTip}`)}
          disabled={disabled}
          size='big'
        />
      </Show>
      <Show if={isVisible(box, 'addOrRemoveMarker')}>
        <RoundButton
          className={ styles.button }
          onClick={() => { setOpen(!open); handleSendEvent(t('procedureBox.actions.add_or_remove_marker.title')); }}
          icon={<FaTag/>}
          tooltip={t('procedureBox.actions.add_or_remove_marker.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.add_or_remove_marker.disabled')}
          size='big'
        />
        <MarkerContent
          handleClose={() => setOpen(false)}
          isOpen={open}
          procedureId={proceduresSeleted[0]?.id}
          procedures={proceduresSeleted}
        />
      </Show>
      <RoundButton
        dataCy={'cypress-menu-button-notes'}
        className={ styles.button }
        onClick={() => { openNotesModal(); handleSendEvent(t('procedureBox.actions.notes.title')); }}
        icon={<FaStickyNote/>}
        tooltip={t('procedureBox.actions.notes.title')}
        disabled={disabled}
        disabledText={t('procedureBox.actions.notes.disabled')}
        size='big'
      />
      <Show if={isVisible(box, 'deadline') && showDeadline(box)}>
        <RoundButton
          dataCy={'cypress-button-deadline-menu'}
          className={ styles.button }
          icon={<FaClock/>}
          tooltip={t('procedureBox.actions.deadline.title')}
          size='big'
          disabled={!disabledDeadline}
          disabledText={t('procedureBox.actions.deadline.disabled')}
          onClick={() => { openDeadline(undefined, getCurrentProcedure()); handleSendEvent(t('procedureBox.actions.deadline.disabled')); }}
        />
      </Show>

    </div>
    <div className={ styles.rightBorder }>
      <RoundButton
        className={ styles.button }
        onClick={() => { openMultipleActions(props.procedure); handleSendEvent(t('procedureBox.actions.multipleActions.title')); }}
        icon={<FaListAlt/>}
        tooltip={t('procedureBox.actions.multipleActions.title')}
        size='big'
        dataCy={'button-multiple-actions'}
      />
      <Show if={isVisible(box, 'tramit')}>
        <RoundButton
          className={ styles.button }
          icon={<FaPaperPlane/>}
          tooltip={t('procedureBox.actions.tramit.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.tramit.disabled')}
          size='big'
          onClick={() => { openTramit(undefined); handleSendEvent(t('procedureBox.actions.tramit.title'), t('analytics.steps.tramit')); }}
          dataCy={'cypress-menu-button-tramit'}
        />
      </Show>
      <Show if={(isVisible(box, 'distributeProcess') || isProcedureCollection(window.location.pathname)) && validateDistribute()}>
        <RoundButton
          className={ styles.button }
          icon={<FaSitemap/>}
          tooltip={t('procedureBox.actions.distributeProcesses.title')}
          disabled={disabled}
          disabledText={t('procedureBox.distributeProcesses.disabled')}
          size='big'
          dataCy={'cypress-distribute-process'}
          onClick={() => { openDistributeProcesses(undefined); handleSendEvent(t('procedureBox.actions.distributeProcesses.title')); }}
        />
      </Show>
      <Show if={isVisible(box, 'forceTramit')}>
        <RoundButton
          className={ styles.button }
          icon={<FaHands/>}
          tooltip={t('procedureBox.actions.forceTramit.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.forceTramit.disabled')}
          size='big'
          onClick={() => { handleForceTramit(); handleSendEvent(t('procedureBox.actions.forceTramit.title')); }}
        />
      </Show>
      <Show if={isVisible(box, 'joinDocuments')}>
        <RoundButton
          className={ styles.button }
          icon={<FaFolder/>}
          tooltip={t('procedureBox.actions.joinDocuments.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.joinDocuments.disabled')}
          size='big'
          onClick={() => { openJoinDocuments(undefined); handleSendEvent(t('procedureBox.actions.joinDocuments.title')); }}
        />
      </Show>
      <Show if={isVisible(box, 'prepareDocument')}>
        <RoundButton
          className={ styles.button }
          icon={<FaFileAlt/>}
          tooltip={t('procedureBox.actions.prepareDocument.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.prepareDocument.disabled')}
          size='big'
          onClick={() => { openCreateDocument(undefined); handleSendEvent(t('procedureBox.actions.prepareDocument.title')); }}
        />
      </Show>
      <Show if={isVisible(box, 'attachFiles')}>
        <RoundButton
          dataCy={'cypress-button-attach-files-procedure'}
          className={ styles.button }
          icon={<FaPaperclip/>}
          tooltip={t('procedureBox.actions.attachFiles.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.attachFiles.disabled')}
          size='big'
          onClick={() => { openAttachFile(); handleSendEvent(t('procedureBox.actions.attachFiles.title'), t('analytics.steps.attach_document')); }}
        />
      </Show>
      <Show if={isVisible(box, 'signDocument')}>
        <RectangularButton
          dataCy={'cypress-signer-button-menu'}
          options={signer}
          text={'signer'}
          size='micro'
          iconSize='iconMedium'
          icon={<FaPenNib/>}
        />
      </Show>
      <Show if={isVisible(box, 'unArchive')}>
        <RoundButton
          className={ styles.button }
          icon={<FaBoxOpen/>}
          tooltip={t('procedureBox.actions.unArchive.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.unArchive.disabled')}
          size='big'
          onClick={() => { openUnarchiveModal(); handleSendEvent(t('procedureBox.actions.unArchive.title')); }}
        />
      </Show>
      <Show if={isVisible(box, 'archive')}>
        <RoundButton
          className={ styles.button }
          icon={<FaArchive/>}
          tooltip={t('procedureBox.actions.archive.title_procedure')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.archive.disabled')}
          size='big'
          onClick={() => { openArchiveProcesses(); handleSendEvent(t('procedureBox.actions.archive.title_procedure'), t('analytics.steps.archive')); }}
        />
      </Show>
      <Show if={isVisible(box, 'shareProcedure')}>
        <RoundButton
          className={ styles.button }
          icon={<FaShareAlt/>}
          tooltip={t('procedureBox.actions.shareProcedure.title')}
          size='big'
          onClick={() => { openShareProcedure(); handleSendEvent(t('procedureBox.actions.shareProcedure.title')); }}
        />
      </Show>
    </div>

    <div>
      <ThemeProvider theme={menuTheme}>
        <RoundButton
          className={ styles.button }
          icon={<FaEllipsisV />}
          onClick={openMenu ? handleClose : handleClick}
          disabled={disabled}
          size='big'
          dataCy='cypress-more-options-menu-procedure-box'
        />
        <MenuAction
          anchorEl={anchorEl}
          open={openMenu}
          onClick={handleClose}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: 'var(--neutral0);',
            },
          }}
        >

          <Show if={isVisible(box, 'attachCitationIntimation')}>
            <div className={`${styles.itemOption}`} onClick={() => openAttachProcedureCitationIntimation()}>
              <LinkIcon />
              <p>{t('procedureBox.actions.attachCitationIntimation.title')}</p>
            </div>
          </Show>
          <Show if={(checkProceduresFluxes() && isVisible(box, 'attachExtract'))}>
            <div className={`${styles.itemOption}`} onClick={() => { handleAttachExtract(); handleSendEvent('Anexar extrato da dívida'); }}>
              <FaFileInvoiceDollar />
              <p>{'Anexar extrato da dívida'}</p>
            </div>
          </Show>

          <Show if={isVisible(box, 'suspensionCancellationTramit') && canViewSuspensionCancellationTramit}>
            <div className={`${styles.itemOption}`} onClick={() => handleSuspensionCancellation('suspensionCancellationTramit')}>
            <FaFileExport size={14}/>
              <p>{t('procedureBox.actions.suspensionCancellationTramit.title')}</p>
            </div>
          </Show>

          <Show if={isVisible(box, 'suspensionCancellationRequest') && canViewSuspensionCancellationRequest}>
            <div className={`${styles.itemOption}`} onClick={() => handleSuspensionCancellation('suspensionCancellationRequest')}>
            <FaFileExcel size={14}/>
              <p>{t('procedureBox.actions.suspensionCancellationRequest.title')}</p>
            </div>
          </Show>
          <Show if={isVisible(box, 'removeDocument')}>
            <div className={`${styles.itemOption}`} onClick={() => { openDeleteFile(undefined); handleSendEvent(t('procedureBox.actions.removeDocument.title')); }}>
              <FaTrashAlt/>
              <p>{t('procedureBox.actions.removeDocument.title')}</p>
            </div>
          </Show>
          <Show if={isVisible(box, 'editProcedure')}>
            <div className={`${styles.itemOption}`} onClick={() => openEditProcedure()} data-cy={'cypress-button-edit-procedure'}>
              <FaEdit />
              <p>{t('procedureBox.actions.editProcedure.title')}</p>
            </div>
          </Show>
          <Show if={isVisible(box, 'changeFlux')}>
            <div className={`${styles.itemOption}`} onClick={() => { openChangeFlux(); handleSendEvent(t('procedureBox.actions.changeFlux.title')); }}>
              <ImShuffle className={styles.resizer}/>
              <p>{t('procedureBox.actions.changeFlux.title')}</p>
            </div>
          </Show>

          <hr className={`${styles.solid}`} />

          <div className={`${styles.itemOption}`} onClick={() => { openAttachProcedure(); handleSendEvent(t('procedureBox.actions.attachProcedure.title')); }}>
            <FaLink/>
            <p>{t('procedureBox.actions.attachProcedure.title')}</p>
          </div>
          <Show if={proceduresSeleted.length === 1}>
            <div className={`${styles.itemOption}`} onClick={() => { openUnattachProcedure(); handleSendEvent(t('procedureBox.actions.unattachProcedure.title')); }}>
              <FaLink/>
              <p>{t('procedureBox.actions.unattachProcedure.title')}</p>
            </div>
          </Show>
          <Show if={isVisible(box, 'createSubprocess')}>
            <SubProcedure
              responsive
              onlyRequestCalculation={judicialFluxes.slice(0, -1).includes(getCurrentProcedure().flux.code)}
              disabled={proceduresSeleted.length > 1 || !judicialFluxes.includes(getCurrentProcedure().flux.code)}
            />
          </Show>
          <hr className={`${styles.solid}`} />
            <Show if={isVisible(box, 'court')}>
              <div
                className={`${styles.itemOption} ${isDisabled(disabledInitialPetition)}`}
                onClick={() => !disabledInitialPetition && openInitialPetition()}>
                <Icon text='court' />
                <p>{t('procedureBox.actions.court.initialPetition')}</p>
              </div>
              <div
                className={`${styles.itemOption} ${isDisabled(disabledIntermediatePetition)}`}
                data-cy="cypress-court-option-intermediatePetition"
                onClick={() => !disabledIntermediatePetition && openIntermediaryPetition()}>
                <Icon text='court' />
                <p>{t('procedureBox.actions.court.intermediatePetition')}</p>
              </div>
          </Show>
          <Show if={isVisible(box, 'spu')}>
            <div
              className={`
                ${styles.itemOption}
                ${proceduresSeleted.length > 1 || !checkSendSpu(getCurrentProcedure().flux.code || '') ? styles.disabledItem : ''}`
              }
              onClick={() => handleOpenRegistrationSpu()}
              data-cy="cypress-spu-option-registrationOficialLetter"
            >
              <Icon text='spu' />
              <p>{t('procedureBox.actions.spu.registrationOficialLetter')}</p>
            </div>
            <div
              className={`
              ${styles.itemOption}
              ${proceduresSeleted.length === 1 && (checkSendSpu(getCurrentProcedure().flux.code || '') || hasSpuData) ? '' : styles.disabledItem}`
            }
              onClick={() => handleOpenSpu()}
              data-cy="cypress-menu-send-spu"
            >
              <Icon text='spu' />
              <p>{t('procedureBox.actions.spu.sendOficialLetter')}</p>
            </div>
          </Show>
          <Show if={isVisible(box, 'ipm')}>
            <div className={`${styles.itemOption}`}>
              <Icon text='ipm' />
              <p>{t('procedureBox.actions.ipm.requestDueDiligence')}</p>
            </div>
            <div className={`${styles.itemOption}`} onClick={openForwardIpm}>
              <Icon text='ipm' />
              <p>{t('procedureBox.actions.ipm.forwardIPM.text')}</p>
            </div>
          </Show>
          <Show if={isVisible(box, 'delete')}>
            <hr className={`${styles.solid}`} />
            <div className={`${styles.itemOption}`} onClick={() => { openDeleteProcesses(); handleSendEvent(t('procedureBox.actions.delete.title_procedure')); }}>
            <FaTimes />
              <p>{t('procedureBox.actions.delete.title_procedure')}</p>
            </div>
          </Show>
        </MenuAction>
      </ThemeProvider>
    </div>
  </>
  );
}
