export const actionsDefault = [
  {
    index: 0,
    itens: ['attachFiles', 'tramit'],
  },
  {
    index: 1,
    itens: ['attachFiles', 'archive'],
  },
  {
    index: 2,
    itens: ['attachFiles', 'signDocument', 'tramit'],
  },
  {
    index: 3,
    itens: ['attachFiles', 'signDocument', 'archive'],
  },
  {
    index: 4,
    itens: ['prepareDocument', 'signDocument', 'tramit'],
  },
  {
    index: 5,
    itens: ['prepareDocument', 'signDocument', 'archive'],
  },
  {
    index: 6,
    itens: ['attachFiles', 'prepareDocument', 'tramit'],
  },
  {
    index: 7,
    itens: ['attachFiles', 'prepareDocument', 'archive'],
  },
  {
    index: 8,
    itens: ['attachFiles', 'prepareDocument', 'signDocument', 'tramit'],
  },
  {
    index: 9,
    itens: ['attachFiles', 'prepareDocument', 'signDocument', 'archive'],
  },
];
