import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { useContext, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartTooltip, ResponsiveContainer, Text,
} from 'recharts';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Format from '../../helpers/format';
import { Box } from '../Box';
import { HeaderLine } from '../Charts/HeaderLine';
import Show from '../Show';
import styles from './styles.module.scss';
import { Tooltip } from '../Tooltip';
import { DashboardContext } from '../../context/dashboardContext';

export function DebtPrescriptions() {
  const { prescriptionsData, loading } = useContext(DashboardContext);
  const [displayChart, setDisplayChart] = useState(false);
  const currentMonth = moment(new Date()).month() + 1;
  const currentDay = moment(new Date()).date() - 1;

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active) {
      return null;
    }

    for (const bar of payload) {
      if (active) {
        if (bar.payload.month_number > currentMonth) {
          return (
            <div className={`${styles.tooltip} ${styles.toPrescribed}`}>
              <p className={styles.name}>{t('dashboard.prescriptions.toPrescribe')}</p>
              <div className={ `${styles.content} ${styles.previous}` }>
                <p className={styles.value}>{`${Format.currency(bar.payload.total_next_prescriptions)}`}</p>
              </div>
            </div>
          );
        }
        if (bar.payload.month_number === currentMonth) {
          return (
            <div className={`${styles.tooltip} ${styles.prescribed}`}>
            <div className={ `${styles.content}` }>
              <p className={styles.period}>
                {`${t('dashboard.prescriptions.wouldPrescribe')}: `}
              </p>
              <p className={styles.value}>{`${Format.currency(bar.payload.total_next_prescriptions)}`}</p>
            </div>
            <div className={ `${styles.content}` }>
              <p className={styles.period}>
                {`${t('dashboard.prescriptions.prescribedUntil')}${currentDay}/${t(`dashboard.prescriptions.months.${currentMonth}`)}: `}
              </p>
              <p className={ `${styles.value} ${styles.prescribed}` }>{`${Format.currency(bar.payload.total_prescription)}`}</p>
            </div>
          </div>
          );
        }
        return (
          <div className={`${styles.tooltip} ${styles.prescribed}`}>
          <div className={ `${styles.content}` }>
            <p className={styles.period}>
              {`${t('dashboard.prescriptions.wouldPrescribe')}: `}
            </p>
            <p className={styles.value}>{`${Format.currency(bar.payload.total_next_prescriptions)}`}</p>
          </div>
          <div className={ `${styles.content}` }>
            <p className={styles.period}>
            {`${t('dashboard.prescriptions.prescribed')}: `}
            </p>
            <p className={ `${styles.value} ${styles.prescribed}` }>{`${Format.currency(bar.payload.total_prescription)}`}</p>
          </div>
        </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={ styles.container }>
      <HeaderLine
        title={t('dashboard.prescriptions.title')}
        tooltip={t('dashboard.prescriptions.tooltip')}
        info={t('dashboard.prescriptions.period', { current_year: new Date().getFullYear() })}
        infoValue={Format.currency(prescriptionsData ? prescriptionsData.total_next_prescriptions : 0)}
        display={displayChart}
        onClick={() => setDisplayChart(!displayChart)}
      />
      <Show if={displayChart}>
      {
        !loading && prescriptionsData !== undefined && prescriptionsData.months.length > 0 ? (
        <>
        <div className={ styles.header }>
          <Tooltip
            title={ t('dashboard.prescriptions.toPrescribeTooltip') }
          >
            <Box title={t('dashboard.prescriptions.toPrescribe')} color='var(--neutral2)'/>
          </Tooltip>
          <Tooltip
            title={ t('dashboard.prescriptions.prescribedTooltip') }
          >
            <Box title={t('dashboard.prescriptions.prescribed')} color='var(--orange)'/>
          </Tooltip>
          <div className={ styles.info }>
            <p className={ styles.period }>{t('dashboard.prescriptions.current')}</p>
            <Tooltip
              title={`${t('dashboard.prescriptions.tooltipToPrescribe')}\n ${t('dashboard.prescriptions.tooltipPrescribed')}`}>
              <FaRegQuestionCircle />
            </Tooltip>
          </div>
        </div>
        <div className={ styles.chart }>
        <ResponsiveContainer width={'99%'} minWidth={700} height={250} minHeight={200}>
            <BarChart
              data={prescriptionsData.months}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 10,
              }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month_number"
              tick={(e) => {
                const { payload: { value } } = e;
                e.fontSize = 12;
                e.fontWeight = currentMonth === value ? 'bold' : 'normal';
                e.fill = currentMonth === value ? 'var(--orange)' : 'var(--neutral6)';
                return <Text {...e}>{t(`dashboard.prescriptions.months.${value}`)}</Text>;
              }}
            />
            <YAxis
              tick={(e) => {
                const { payload: { value } } = e;
                e.fontSize = 10;
                e.fill = 'var(--neutral4)';
                return <Text {...e}>{Format.formatValueName(value)}</Text>;
              }}
            />
            <RechartTooltip cursor={{ fill: 'var(--neutral1)' }}content={<CustomTooltip />} />
            <Bar
              dataKey="total_next_prescriptions"
              fill='var(--neutral3)'
              barSize={16}
              radius={[25, 25, 0, 0]}>
            </Bar>
            <Bar
              dataKey="total_prescription"
              fill='var(--orange)'
              barSize={16}
              radius={[25, 25, 0, 0]}>
            </Bar>
            </BarChart>
        </ResponsiveContainer>
        </div>
        </>
        )
          : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
      </Show>
    </div>
  );
}
