import { t } from 'i18next';
import { useContext } from 'react';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { BoxIcon } from '../../BoxIcon';
import { Alert } from '../Alert';

export function SuspensionCancellationAlert() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div>
      { batches?.filter((el) => (
        el.status !== 'closed' && (el.type === 'suspensionCancellationTramit' || el.type === 'suspensionCancellationRequest')
      )).map((batch) => (
        <Alert
          icon={<BoxIcon text={t(`procedureBox.actions.${batches[0].type}.modal.alertLoading.text`)} />}
          textIcon
          title={t(`procedureBox.actions.${batches[0].type}.modal.alertLoading.title`)}
          text={''}
          batch={batch}
        />
      ))}
    </div>
  );
}
