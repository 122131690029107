import Axios from '../config/api';

export default class MultipleActionsService {
  public static async getPersonalized() {
    return Axios.get('api/multiple_actions/personalized')
      .then((res) => res.data);
  }

  public static async create(multipleAction: any) {
    return Axios.post('api/multiple_actions/', { actions: multipleAction })
      .then((res) => res.data);
  }

  public static async delete(id: number) {
    return Axios.delete(`api/multiple_actions/${id}`)
      .then((res) => res.data);
  }
}
