import { useContext, useState, useEffect } from 'react';
import { FaListAlt, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { t } from 'i18next';
import { Radio } from '@mui/material';
import { RoundButton } from '../../RoundButton';
import { Create } from './Create';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { initialData } from '../data';
import { MultipleAction } from '../../../@types/backgroundActions';
import MultipleActionsService from '../../../services/multipleActionsService';
import { CustomizeAction } from '../../../@types/multipleActions';
import { Submit } from '../Submit';
import styles from './styles.module.scss';

const LOADING_ANIMATION = require('../../../assets/lotties/loading.json');

export function Customize() {
  const { setModal } = useContext(ProcedureActionsContext);
  const i18nkey = 'procedureBox.actions.multipleActions';
  const [selected, setSelected] = useState<number>();
  const [selectedActions, setSelectedActions] = useState<MultipleAction[]>([]);
  const [actions, setActions] = useState<CustomizeAction[]>([]);
  const [loading, setLoading] = useState(false);

  const renderLoading = () => {
    return (
      <div className={styles.icon}>
        <Player
          loop={true}
          autoplay
          keepLastFrame={true}
          src={LOADING_ANIMATION}
          speed={2}
          className={styles.lottie}
        >
          <Controls visible={false} />
        </Player>
      </div>
    );
  };

  const loadActions = async () => {
    setLoading(true);
    await MultipleActionsService.getPersonalized()
      .then((res) => {
        setActions(res);
        setLoading(false);
      });
  };

  const openCreate = () => {
    setModal({
      visible: true,
      icon: <FaListAlt />,
      title: t(`${i18nkey}.sections.customize.new`),
      handleClose: () => setModal(undefined),
      onMinimize: () => <></>,
      open: true,
      size: 'medium',
      children: <Create
        closeModal={() => setModal(undefined)}
      />,
    });
  };

  const selectedItem = (option: number) => {
    setSelected(option);
    const selectedActionsTmp: MultipleAction[] = [];
    const actionSelected = actions.find((i) => i.id === option);

    actionSelected?.actions.map((item) => {
      const action = initialData?.find((a: MultipleAction) => a.id === item);
      if (action) {
        selectedActionsTmp.push(action);
      }
    });

    setSelectedActions(selectedActionsTmp);
  };

  const renderAction = (item: string, lastItem: boolean) => {
    const action = initialData?.find((a) => a.id === item);
    if (!action) return null;

    return (
      <>
        { action.icon }
        <div className={styles.label}>{ t(action?.label) }</div>
        { !lastItem && <span>{'>'}</span> }
      </>
    );
  };

  useEffect(() => {
    loadActions();
  }, []);

  const onDelete = (id: number) => {
    MultipleActionsService.delete(id).then((data) => {
      if (data) {
        const removeItem = actions.filter((action) => action.id !== id);
        setActions(removeItem);
      }
    });
  };

  const returnList = () => {
    return (
      <>
        {
          actions.map((item) => (
            <div className={styles.containerItem}>
              <div className={styles.item}>
                <Radio
                  name={''}
                  checked={selected === item.id}
                  value={item.id}
                  onChange={() => selectedItem(item.id)}
                  className={styles.radio}
                  data-cy={`radio-${item.id}`}
                />
                { item.actions.map((i, index) => renderAction(i, (item.actions.length - 1 === index)))}
              </div>
              <a
                className={styles.buttonDelete}
                onClick={() => onDelete(item.id)}
                data-cy={`delete-${item.id}`}
              >
                <FaTrashAlt />
              </a>
            </div>
          ))
        }
        <RoundButton
          className={styles.button}
          icon={<FaPlus />}
          onClick={() => openCreate()}
          disabled={actions.length >= 5}
          disabledText={''}
          dataCy={'create-action'}
        />
      </>
    );
  };

  return (
    <div className={ styles.container }>
      <p>{ t(`${i18nkey}.modal.actions.${actions.length > 0 ? 'create' : 'empty'}`) }</p>
      {loading ? renderLoading() : returnList()}
      <div className={styles.footer}>
        <Submit
          closeModal={() => setModal(undefined)}
          selectedActions={selectedActions}
        />
      </div>
    </div>
  );
}
