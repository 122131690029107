import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaArchive,
  FaInbox,
  FaPaperPlane,
  FaQuoteRight,
  FaShareAlt,
} from 'react-icons/fa';
import Screen from '../../helpers/screen';
import { DropDown } from './DropDown';
import { Item } from './Item';
import './styles.scss';
import ProcedureBoxService from '../../services/procedureBoxService';
import { Boxes } from '../../@types/boxes';
import { QueryContext } from '../../context/queryContext';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { sendEvent } from '../../helpers/googleAnalytics';

export function BoxesSelect() {
  const { t } = useTranslation();
  const {
    setBox, box, fluxes, removeAllMarkers, removeQuerys, loadData,
  } = useContext(QueryContext);
  const { removeAllProcedures } = useContext(ProcedureActionsContext);
  const [selected, setSelected] = useState('inbox');
  const [boxes, setBoxes] = useState<Boxes>();
  const isMobile = Screen.isMobile();

  const loadDataBoxes = async () => {
    const response : Boxes = await ProcedureBoxService.getProcedureBoxesInfo();
    setBoxes(response);
  };

  useEffect(() => {
    loadDataBoxes();
  }, []);

  useEffect(() => {
    loadData();
  }, [selected]);

  useEffect(() => {
    loadDataBoxes();
  }, [fluxes]);

  const handleOnClick = (item: any) => {
    removeQuerys();
    removeAllMarkers();
    setSelected(item);
    setBox(item);
    sendEvent({
      event_name: 'change_box',
      label: item,
    });
    removeAllProcedures();
  };

  const data = [
    {
      icon: <FaInbox />,
      text: t('procedureBox.boxesSelect.inbox'),
      counter: [{ number: boxes ? boxes.inbox.total_seen : 0, text: t('procedureBox.boxesSelect.unread') },
        { number: boxes ? boxes.inbox.total : 0, text: t('procedureBox.boxesSelect.total') }],
      selected: 'inbox',
      greenNumber: false,
    },
    {
      icon: <FaPaperPlane />,
      text: t('procedureBox.boxesSelect.outbox'),
      counter: [{ number: boxes ? boxes.outbox.total_seen : 0, text: t('procedureBox.boxesSelect.outboxMonth') },
        { number: boxes ? boxes.outbox.total : 0, text: t('procedureBox.boxesSelect.total') }],
      selected: 'outbox',
      greenNumber: true,
    },
    {
      icon: <FaShareAlt />,
      text: t('procedureBox.boxesSelect.sharebox'),
      counter: [{ number: boxes ? boxes.sharedbox.total_seen : 0, text: t('procedureBox.boxesSelect.unread') },
        { number: boxes ? boxes.sharedbox.total : 0, text: t('procedureBox.boxesSelect.total') }],
      selected: 'sharedbox',
      greenNumber: false,
    },
    {
      icon: <FaArchive />,
      text: t('procedureBox.boxesSelect.archive'),
      counter: [{ number: boxes ? boxes.archivedbox.total_seen : 0, text: t('procedureBox.boxesSelect.archiveMonth') },
        { number: boxes ? boxes.archivedbox.total : 0, text: t('procedureBox.boxesSelect.total') }],
      selected: 'archivedbox',
      greenNumber: true,
    },
    {
      icon: <FaQuoteRight />,
      text: t('procedureBox.boxesSelect.citation_intimation'),
      counter: [{ number: 0, text: t('procedureBox.boxesSelect.unread') },
        { number: boxes ? boxes.citationIntimation.total : 0, text: t('procedureBox.boxesSelect.total') }],
      selected: 'citationIntimation',
      greenNumber: false,
    },
  ];
  return (
    <>
      { isMobile
        ? <DropDown
            selected={ selected }
            data={ data }
          />
        : <div className='boxesSelectContainer'>
            { data.map((option, index) => (
              <Item
                key={ index }
                icon={ option.icon }
                text={ option.text }
                counter={ option.counter }
                selected={ box === option.selected }
                onClick={ () => { handleOnClick(option.selected); } }
                greenNumber={ option.greenNumber }
              />
            ))}
          </div>
      }
    </>
  );
}
