import { ReactNode } from 'react';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import CustomTooltip from '../CustomTooltip';
import { showBackground } from '../../helpers/showBackground';

type RoundButtonProps = {
  dataCy?: string;
  text?: string;
  icon?: ReactNode;
  tooltip?: string;
  backgroundColor?: string;
  size?: 'small' | 'large' | 'medium' | 'big' | 'micro' | 'intermediate';
  className?: string;
  onClick?: (e: any) => void;
  color?: string;
  disabled?: boolean;
  disabledText?: String;
  badge?: number;
  shared?: boolean;
  customTooltip?: any;
};

export function RoundButton(props: RoundButtonProps) {
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const sizes = [
    {
      size: 'small',
      value: '10px',
    },
    {
      size: 'large',
      value: '11px',
    },
    {
      size: 'medium',
      value: '12px',
    },
    {
      size: 'big',
      value: '13px',
    },
    {
      size: 'micro',
      value: '8px',
    },
    {
      size: 'intermediate',
      value: '10px',
    },
  ];

  const useStyles = makeStyles({
    tooltip: {
      fontSize: sizes.find((s) => s.size === props.size)?.value,
      backgroundColor: showBackground(),
      ...props.customTooltip,
    },
  });

  const renderIcon = () => props.icon;
  const classes = useStyles();

  return (
    <div
      className={`roundButtonContainer ${props.size ? props.size : 'medium'} ${props.className} ${props.disabled ? 'disabledButton' : ''}`}
      onClick={ props.disabled ? () => {} : props.onClick }
    >
      {
        props.badge && (
          <div className={ `badge ${props.shared ? 'shared' : 'default'} ${isMobile ? 'responsive' : ''}` }>
            { props.badge }
          </div>
        )
      }
      <CustomTooltip arrow={props.disabled}
        classes={{ tooltip: classes.tooltip }}
        title={`${props.disabled ? props.disabledText : props.tooltip || ''}`}
      >
        <div
          data-cy={props.dataCy}
          className={`content ${props.backgroundColor ? '' : 'transparent'}`}
          style={{ backgroundColor: props.backgroundColor }}
        >
          <span
            style={{ color: props.color }}
          >
            { props.text ? props.text : renderIcon() }
          </span>
        </div>
      </CustomTooltip>
    </div>
  );
}
