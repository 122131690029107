import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Button } from '../../../../components/Button';
import { ErrorField } from '../../../../components/ErrorField';
import { Header } from '../../../../components/Header';
import { InputDate } from '../../../../components/InputDate';
import { InputText } from '../../../../components/InputText';
import { TextArea } from '../../../../components/TextArea';
import FormHelper from '../../../../helpers/form';
import Format from '../../../../helpers/format';
import NewProcessService from '../../../../services/newProcessService';
import { Cdas } from '../Cdas';
import styles from './styles.module.scss';
import { OptionType } from '../../../../@types/config';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Show from '../../../../components/Show';
import Select from '../../../../components/Select';
import { PetitioningInformation } from '../../../../@types/petitioningInformation';
import { QueryContext } from '../../../../context/queryContext';
import { ProcedureDetailsContext } from '../../../../context/procedureDetailsContextProvider';

type NewPetitioningInformationsProps = {
  fluxId: number;
  closeModal: () => void;
  fluxCode: string | undefined;
  procedure?: PetitioningInformation;
  procedureId?: number;
};

export function NewPetitioningInformations(props: NewPetitioningInformationsProps) {
  const {
    handleSubmit,
    control,
    setValue,
    unregister,
  } = useForm();
  const { addToasts } = useContext(ProcedureActionsContext);
  const { setLoadingDetails, reloadCurrentProcedure } = useContext(QueryContext);
  const { handleLoadTimeline } = useContext(ProcedureDetailsContext);

  const { pathname: url } = useLocation();

  const cdaRequired = !['FLU0028', 'FLU0061', 25].includes(props.fluxCode || props.fluxId);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>(null);
  const [origins, setOrigins] = useState([]);
  const [subjects, setSubjects] = useState<OptionType[]>([]);
  const [defaultSubject, setDefaultSubject] = useState<OptionType>();
  const [classes, setClasses] = useState<OptionType[]>([]);
  const [defaultClass, setDefaultClass] = useState<OptionType>();
  const [procedureId, setProcedureId] = useState();
  const [cdaTypes, setCdaTypes] = useState([]);
  const [activePartName, setActivePartName] = useState('');
  const [activePartType, setActivePartType] = useState('');
  const [passivePartType, setPassivePartType] = useState('');

  const fillForm = () => {
    setValue('id', props.procedure?.id);
    setValue('process_number', props.procedure?.process_number);
    setValue('receipt_date', props.procedure?.receipt_date);
    setValue('origin_procedure_id', props.procedure?.origin?.value);
    setValue('class_denomination_id', props.procedure?.class_denomination?.value);
    setValue('active_part_type', props.procedure?.active_part_type);
    setValue('active_part_name', props.procedure?.active_part_name);
    setValue('active_part_document', props.procedure?.active_part_document);
    setValue('passive_part_type', props.procedure?.passive_part_type);
    setValue('passive_part_name', props.procedure?.passive_part_name);
    setValue('passive_part_document', props.procedure?.passive_part_document);
    setValue('cda_tax_type_id', props.procedure?.cda_tax_type?.value);
    setValue('observation', props.procedure?.observation);
    setValue('subject_id', props.procedure?.subject?.value);
    setLoading(false);
  };

  useEffect(() => {
    if (props.fluxCode !== undefined) {
      NewProcessService.getNewPetitioningInformationsData(props.fluxCode)
        .then((res) => {
          setSubjects(res.data.subject_id);
          setOrigins(res.data.origin);
          setClasses(res.data.class_denomination_id);
          setCdaTypes(res.data.cda_tax_type);
          setActivePartName(res.data.active_part_type);
          setActivePartType(res.data.active_part_name);
          setPassivePartType(res.data.passive_part_type);
          if (props.procedure) {
            fillForm();
          } else {
            setValue('petitioning_cdas_attributes', []);
            setValue('subject_id', res.data.subject_id[0].value);
            setValue('class_denomination_id', res.data.class_denomination_id[0].value);
            setValue('active_part_type', res.data.active_part_type);
            setValue('active_part_name', res.data.active_part_name);
            setValue('passive_part_type', res.data.passive_part_type);
          }
        });
    }
  }, [props.fluxCode]);

  const save = (form: any, cdas: any[]) => {
    const data = {
      ...{
        petitioning_information: {
          ...form,
          petitioning_cdas_attributes: cdas,
        },
      },
      flux_id: props.fluxId,
    };
    NewProcessService.createProcessToPetitioningInformations(data)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();

          addToasts({
            type: 'success',
            text: FormHelper.customToastMessage(res.id),
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          setErrors(err.data);
        }
      });
  };

  const reRegistration = (form: any, cdas: any[]) => {
    const data = {
      ...{
        petitioning_information: {
          ...form,
          petitioning_cdas_attributes: cdas,
        },
      },
      flux_id: props.fluxId,
      procedure_id: props.procedureId,
    };

    NewProcessService.reRegistrationProcessToPetitioningInformations(data)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();

          addToasts({
            type: 'success',
            text: FormHelper.customToastMessageEdit(res.id),
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          setErrors(err.data);
        }
      });
  };

  const update = (form: any, cdas: any[]) => {
    const data = {
      ...{
        petitioning_information: {
          ...form,
          petitioning_cdas_attributes: cdas,
        },
        flux_id: props.fluxId,
      },
    };

    NewProcessService.editPetitioningInformations(data)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();
          addToasts({
            type: 'success',
            text: FormHelper.customToastMessageEdit(res.id),
          });
        }
        setLoading(false);

        reloadCurrentProcedure(url);
        handleLoadTimeline();
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          setErrors(err.data);
        }
      });

    setLoadingDetails(true);
  };

  const submit = (form: any) => {
    setLoading(true);
    setErrors(null);

    if ((props.procedure?.petitioning_cdas.length === 0 || form?.petitioning_cdas_attributes.length === 0) && cdaRequired) {
      setErrors({ cdas_list: [t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.error')] });
      setLoading(false);
    } else {
      const cdas = form.petitioning_cdas_attributes ? form.petitioning_cdas_attributes.map((cda: any) => {
        const cdaValue = Format.removeCurrencyFormat(cda.value);
        return { ...cda, value: cdaValue };
      }) : props.procedure?.petitioning_cdas.map((cda: any) => {
        const cdaValue = Format.removeCurrencyFormat(cda.value);
        return { ...cda, value: cdaValue };
      });

      if (props.procedure) {
        update(form, cdas);
      } else if (props.procedureId) {
        reRegistration(form, cdas);
      } else {
        save(form, cdas);
      }
    }
  };

  const searchProcess = (val: string) => {
    if (!props.procedure === undefined) {
      setProcedureId(undefined);
      NewProcessService.searchProcessToPetitioningInformations(val)
        .then((res) => {
          const procedureIds = res?.procedure_ids?.[0] || res?.procedure_id;
          if (procedureIds) {
            setProcedureId(procedureIds);
            setLoading(true);
          } else {
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  };

  const handleClass = (val: OptionType | null) => {
    const newVal = classes.find((el) => el?.value === val);
    setDefaultClass(newVal);
  };

  const handleSubject = (val: OptionType | null) => {
    const newVal = subjects.find((el) => el?.value === val);
    setDefaultSubject(newVal);
  };

  return (
    <form data-cy={'cypress-petitioning-information-form'} className={ styles.container } onSubmit={handleSubmit(submit)}>
      <Header text={t('procedureBox.newProcedure.newPetitioningInformations.form.data')}/>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.processNumber.label')}
                onBlur={(e) => searchProcess(e.target.value)}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.processNumber.placeholder')}
                required
                dataCy={'process-number'}
              />
            )}
          />
          {FormHelper.renderErrorField('process_number', errors)}
          {procedureId && (
            <ErrorField text={
              <Trans
                i18nKey='procedureBox.newProcedure.newPetitioningInformations.existingProcess'
                components={{ a: <a /> }}
                values={ { procedureId } }
              />
            } />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="receipt_date"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.receipt_date.label')}
                value={props.procedure?.receipt_date}
                required
                dataCy={'receipt-date'}
              />
            )} />
          {FormHelper.renderErrorField('receipt_date', errors)}
        </Grid>
        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="class_denomination_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.class_denomination_id.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.class_denomination_id.placeholder')}
                options={classes}
                defaultValue={ props.procedure?.class_denomination || defaultClass }
                onChange={(e) => {
                  handleClass(e);
                  field.onChange(e);
                }}
                returnValue
                dataCy={'class-denomination'}
                required />
            )} />
          {FormHelper.renderErrorField('class_denomination_id', errors)}
        </Grid>
        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="origin_procedure_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.origin_procedure_id.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.origin_procedure_id.placeholder')}
                options={origins}
                returnValue
                defaultValue={ props.procedure?.origin }
                dataCy={'origin-procedure'}
                required />
            )} />
          {FormHelper.renderErrorField('origin_procedure_id', errors)}
        </Grid>
        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="subject_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.subject_id.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.subject_id.placeholder')}
                options={[]}
                defaultValue={ props.procedure?.subject || defaultSubject }
                urlToUpdate={NewProcessService.getSubjectByKeyList}
                onChange={(e) => {
                  handleSubject(e);
                  field.onChange(e);
                }}
                returnValue
                dataCy={'subject'}
                required />
            )} />
          {FormHelper.renderErrorField('subject_id', errors)}
        </Grid>
      </Grid>
      <Header text={t('procedureBox.newProcedure.newPetitioningInformations.form.partActive.title')} />
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="active_part_type"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.active_part_type.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.active_part_type.placeholder')}
                defaultValue={activePartType}
                dataCy={'active-part-type'}
                required />
            )} />
          {FormHelper.renderErrorField('active_part_type', errors)}
        </Grid>

        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="active_part_name"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.active_part_name.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.active_part_name.placeholder')}
                defaultValue={activePartName}
                dataCy={'active-part-name'}
                required />
            )} />
          {FormHelper.renderErrorField('active_part_name', errors)}
        </Grid>

        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="active_part_document"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.active_part_document.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.active_part_document.placeholder')}
                dataCy={'active-part-document'}
              />
            )} />
          {FormHelper.renderErrorField('active_part_document', errors)}
        </Grid>
      </Grid>
      <Header text={t('procedureBox.newProcedure.newPetitioningInformations.form.partPassive.title')} />
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="passive_part_type"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.passive_part_type.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.passive_part_type.placeholder')}
                defaultValue={passivePartType}
                dataCy={'passive-part-type'}
              />
            )} />
          {FormHelper.renderErrorField('passive_part_type', errors)}
        </Grid>

        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="passive_part_name"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.passive_part_name.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.passive_part_name.placeholder')}
                dataCy={'passive-part-name'}
                required />
            )} />
          {FormHelper.renderErrorField('passive_part_name', errors)}
        </Grid>

        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={control}
            name="passive_part_document"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.passive_part_document.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.passive_part_document.placeholder')}
                dataCy={'passive-part-document'}
              />
            )} />
          {FormHelper.renderErrorField('passive_part_document', errors)}
        </Grid>
      </Grid>
      <Show if={cdaRequired}>
        <Header text={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.title')} />
        <Grid
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            xs={1}
            sm={5}
            md={4}
            className={styles.input}
          >
            <Controller
              control={control}
              name="cda_tax_type_id"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.cda_tax_type_id.label')}
                  placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.cda_tax_type_id.placeholder')}
                  options={cdaTypes}
                  defaultValue={props.procedure?.cda_tax_type?.value ? props.procedure?.cda_tax_type?.value : { label: '', value: '' }}
                  returnValue
                  dataCy={'cda-tax-type'}
                  required />
              )} />
            {FormHelper.renderErrorField('cda_tax_type_id', errors)}
          </Grid>
        </Grid>
        <Cdas
          control={control}
          setValue={setValue}
          errors={errors}
          unregister={unregister}
          data={props.procedure?.petitioning_cdas || []}
        />
        {FormHelper.renderErrorField('cdas_list', errors)}
      </Show>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={1}
          sm={10}
          md={12}
          className={styles.input}
        >
          <Controller
            control={control}
            name="observation"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.observation.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.observation.placeholder')}
                dataCy={'observation'}
              />
            )} />
          {FormHelper.renderErrorField('observation', errors)}
        </Grid>
      </Grid>
      <div className={styles.submit}>
        <Button
          title={t(`procedureBox.newProcedure.${props.procedure ? 'edit' : 'submit'}`)}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
          dataCy={'petitioning-information-submit'}
        />
      </div>
    </form>
  );
}
