import { useState } from 'react';
import { FaChevronDown, FaTag } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@emotion/react';
import { Menu } from '@mui/material';
import { MarkersFixedValues } from '../MarkersFixedValues';
import { menuTheme } from '../../../styles/customThemes/menuTheme';
import styles from './styles.module.scss';

export function ResponsiveMenu() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={`${styles.container}`}
    >
      <ThemeProvider theme={menuTheme}>
      <div className={`${styles.title}`} onClick={open ? handleClose : handleClick}>
        <FaTag />
        <span>{t('procedureBox.markersSelect.title')}</span>
        <FaChevronDown className={ styles.chevron } />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--neutral0);',
          },
        }}
      >
        <div>
          <MarkersFixedValues
            type='priority'
            open
          />
          <MarkersFixedValues
            type='pendency'
            open
          />
          <MarkersFixedValues
            type='custom'
            open
          />
        </div>
      </Menu>
      </ThemeProvider>
    </div>
  );
}
