import { Draggable } from 'react-beautiful-dnd';
import { FaGripVertical } from 'react-icons/fa';
import { t } from 'i18next';
import { MultipleAction } from '../../../../../@types/backgroundActions';
import styles from './styles.module.scss';
import Show from '../../../../Show';

type ItemProps = {
  index: number;
  item: MultipleAction;
  isSelected?: boolean;
};

export function Item(props: ItemProps) {
  return (
    <Draggable
      draggableId={props.item.id.toString()}
      index={props.index}
    >
      {(provided, snapshot) => (
        <div className={styles.container}>
          <Show if={Boolean(props.isSelected)}>
            <p className={styles.badge}>{props.index + 1}</p>
          </Show>
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={`${styles.item} ${snapshot.isDragging ? styles.drag : ''}`}
          >
            <FaGripVertical className={styles.icon}/>
            {props.item.icon}
            <p>{t(props.item.label)}</p>
          </div>
        </div>
      )}
    </Draggable>
  );
}
