import { useState, useEffect, useContext } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
  FaTimes,
  FaChevronDown,
  FaRegQuestionCircle,
} from 'react-icons/fa';
import {
  CssBaseline,
  ThemeProvider,
  createFilterOptions,
  AutocompleteGetTagProps,
  Autocomplete,
  TextField,
  Checkbox,
} from '@mui/material/';
import styles from './styles.module.scss';
import { globalTheme } from './globalTheme';
import { QueryContext } from '../../context/queryContext';
import CustomTooltip from '../CustomTooltip';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

type MarkerColor = {
  value: any;
  color: string;
};

type SelectFilterProps = {
  id?: string;
  options: OptionType[];
  placeholder: string;
  label?: string;
  tooltip?: string;
  markersColors?: MarkerColor[];
  subject?: boolean;
  handleSelectedItems?: (itens: number[]) => void;
  defaultValue?: boolean;
  limitTags?: number;
};

export type OptionType = {
  value: any;
  label: string;
  selectAll?: boolean;
};

function Tag(props: TagProps) {
  const { label, onDelete } = props;
  return (
    <div className={styles.tag}>
      <span>{label}</span>
      <FaTimes onClick={onDelete} className={styles.faTimes} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>();

export function SelectFilter(props: SelectFilterProps) {
  const { t } = useTranslation();
  const idSelectAll = 'select-all';
  const [quantity, setQuantity] = useState<number>(1);
  const [checkSelected, setCheckSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const filter = createFilterOptions<OptionType>();
  const noOptions = quantity === 0;

  const { updateAdvancedFilter, setSubjectsSelects, hasAnyFilter } = useContext(QueryContext);

  const handleToggleOption = (selectedOptionsTemp: OptionType[]) => {
    const hasSelectAllOption = (options: OptionType[]) => options.some((option) => option.selectAll);

    const hasSelectAllInCurrent = hasSelectAllOption(selectedOptions);
    const hasSelectAllInTemp = hasSelectAllOption(selectedOptionsTemp);

    const allNonSelectAllSelected = props.options.filter((option) => !option.selectAll).length
      === selectedOptionsTemp.filter((option) => !option.selectAll).length;

    if (hasSelectAllInCurrent && allNonSelectAllSelected) {
      setSelectedOptions([]);
    } else if ((!hasSelectAllInCurrent && hasSelectAllInTemp) || allNonSelectAllSelected) {
      setSelectedOptions(props.options);
    } else if (hasSelectAllInTemp && hasSelectAllInCurrent && props.options.length !== selectedOptionsTemp.length) {
      setSelectedOptions(selectedOptionsTemp.filter((option) => !option.selectAll));
    } else {
      setSelectedOptions(selectedOptionsTemp);
    }
  };

  useEffect(() => {
    if (props.options?.[0]?.selectAll) {
      setSelectedOptions(props.options);
    }
  }, [props.options]);

  useEffect(() => {
    if (hasAnyFilter === false) {
      setSelectedOptions([]);
    }
  }, [hasAnyFilter]);

  useEffect(() => {
    if (props.options.length > 0 && props.defaultValue) {
      setSelectedOptions(props.options[0]?.selectAll ? props.options : [props.options[0]]);
    }
  }, [props.defaultValue]);

  const handleChange = (event :any, selectedOptionsTemp :OptionType[], reason :string, details :any) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (checkSelected) {
        let result = [];
        result = props.options.filter((el :any) => el.value !== details.option.value);
        handleToggleOption(result);
      } else {
        handleToggleOption(selectedOptionsTemp);
      }
      setCheckSelected(false);
    }
    if (reason === 'clear') {
      setSelectedOptions([]);
    }
  };

  const optionRenderer = (others :any, option :OptionType, { selected } :any) => {
    const optionSelectAll = option.value === idSelectAll;
    const disabled = optionSelectAll && quantity !== props.options.length;
    return (
      <>
        <li {...others} aria-disabled={disabled}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            style={{
              padding: '2px',
              marginRight: '8px',
            }}
            checked={checkSelected ? true : selected}
            disabled={disabled}
          />
          {props.markersColors ? <div className={styles.square} style={ { backgroundColor: (props.markersColors.filter((marker) => marker.value === option.value))[0].color } }></div> : <></>}
          {option.label}
        </li>
        {
          noOptions
            ? <li className={styles.noOptions}>
                {t('general.noOptions')}
              </li>
            : null
        }
      </>
    );
  };

  const tagRenderer = (tagValue: OptionType[], getTagProps: any) => {
    const tags = tagValue.map((option :OptionType, index :number) => (
      <CustomTooltip title={ option.label }>
        <div className={styles.containerTag}>
          <StyledTag {...getTagProps({ index })}
          label={props.markersColors ? <div className={styles.tagMarker}>
            <div className={styles.square} style={ { backgroundColor: (props.markersColors.filter((marker) => marker.value === option.value))[0].color } }>
            </div> {option.label}
          </div> : option.label} />
        </div>
      </CustomTooltip>
    ));
    return tags;
  };

  const handleSelected = () => {
    if (props.id !== undefined && !props.subject) {
      updateAdvancedFilter(props.id, selectedOptions.map((a) => a.value));
    } else {
      setSubjectsSelects(selectedOptions);
    }
    if (props.handleSelectedItems) {
      props.handleSelectedItems(selectedOptions.map((a) => a.value));
    }
  };
  return (
    <div className={styles.container}>
      <div className={ styles.info }>
        {props.label && <label>{props.label}</label>}
        {props.tooltip
          ? <CustomTooltip title={ props.tooltip }>
            <div className={styles.containerTag}>
              <FaRegQuestionCircle />
            </div>
            </ CustomTooltip>
          : null}
      </div>
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <Autocomplete
          popupIcon={<FaChevronDown className={styles.icon} />}
          isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
          limitTags={props.limitTags ? props.limitTags : 2}
          multiple
          options={props.options || []}
          value={selectedOptions}
          disableCloseOnSelect
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            setQuantity(filtered.length);
            return [...filtered];
          }}
          classes={{
            listbox: styles.listbox,
            noOptions: styles.noOptions,
          }}
          noOptionsText={t('general.noOptions')}
          onChange={handleChange}
          onBlur={() => handleSelected()}
          getOptionLabel={(option) => option.label}
          renderOption={optionRenderer}
          renderTags={tagRenderer}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.placeholder || ''}
            />
          )}
        />
      </ThemeProvider>
    </div>
  );
}
