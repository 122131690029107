import { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Item } from './Item';
import { MultipleAction } from '../../../../@types/backgroundActions';
import { initialData } from '../../data';
import Show from '../../../Show';
import { EmptyList } from '../../EmptyList';
import { Button } from '../../../Button';
import MultipleActionsService from '../../../../services/multipleActionsService';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { isVisible } from '../../../../helpers/actionsMenu';
import { Procedure } from '../../../../@types/model';

type SectionProps = {
  closeModal: () => void;
  procedure?: Procedure;
};

export function Create(props: SectionProps) {
  const { proceduresSeleted, openMultipleActions } = useContext(ProcedureActionsContext);
  const [sourceActions, setSourceActions] = useState<MultipleAction[]>([]);
  const [selectedActions, setSelectedActions] = useState<MultipleAction[]>([]);
  const [disabled, setDisabled] = useState(false);
  const i18nkey = 'procedureBox.actions.multipleActions.sections.customize';

  useEffect(() => {
    const data = initialData.filter((obj) => {
      if (proceduresSeleted.length > 1 && (obj.id === 'spu' || obj.id === 'registrationSpu')) {
        return false;
      }
      return isVisible(
        props.procedure?.box || proceduresSeleted[0].box,
        obj.id,
      );
    });
    setSourceActions(data);
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    let add;
    const active = sourceActions;
    const complete = selectedActions;

    if (source.droppableId === 'sourceList') {
      add = active[source.index];
      active.splice(source.index, 1);
    } else {
      add = complete[source.index];
      complete.splice(source.index, 1);
    }

    if (destination.droppableId === 'sourceList') {
      active.splice(destination.index, 0, add);
    } else {
      complete.splice(destination.index, 0, add);
    }
    setDisabled(complete.length < 1 || complete.length > 5);
    setSelectedActions(complete);
    setSourceActions(active);
  };

  const closeModal = () => {
    props.closeModal();
    openMultipleActions(undefined, 'customize');
  };

  const create = () => {
    const ids = selectedActions.map((action) => action.id);
    MultipleActionsService.create(ids).then((res) => {
      if (res) {
        closeModal();
      }
    });
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={ styles.container }>
          <p className={styles.message}>{t(`${i18nkey}.message1`)}</p>
          <Droppable droppableId="sourceList">
            {(provided, snapshot) => (
              <div
                className={`${styles.dragBox} ${snapshot.isDraggingOver ? styles.dragactive : ''}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sourceActions?.map((item: MultipleAction, index: number) => (
                  <Item
                    index={index}
                    item={item}
                    key={item.id}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        <p className={styles.message}>{t(`${i18nkey}.message2`)}</p>
        <Droppable droppableId="selectedList">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${styles.dragBox}  ${snapshot.isDraggingOver ? styles.dragcomplete : ''}`}
            >
              <Show if={selectedActions.length > 0}>
                {selectedActions?.map((item: MultipleAction, index: number) => (
                  <Item
                    index={index}
                    item={item}
                    key={item.id}
                    isSelected
                  />
                ))}
                {provided.placeholder}
              </Show>
              <Show if={selectedActions.length < 1}>
                <EmptyList />
                {provided.placeholder}
              </Show>
            </div>
          )}
        </Droppable>
        </div>
      </DragDropContext>
      <div className={styles.footer}>
        <Button
          buttonType={'default'}
          round
          size='flat'
          title={t(`${i18nkey}.cancel`)}
          onClick={closeModal}
        />
        <Button
          buttonType={'primary'}
          round
          size='flat'
          disabled={disabled}
          title={t(`${i18nkey}.save`)}
          onClick={create}
        />
      </div>
    </div>
  );
}
