import { Tooltip as MuiTooltip } from '@mui/material';
import { ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Screen from '../../helpers/screen';
import styles from './styles.module.scss';
import { showBackground } from '../../helpers/showBackground';

type TooltipProps = {
  title: ReactNode;
  classes?: any;
  className?: any;
  children?: ReactNode;
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
};

export function Tooltip(props: TooltipProps) {
  const [open, setOpen] = useState(false);
  const isMobile = Screen.isMobile();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles({
    tooltip: {
      backgroundColor: showBackground(),
    },
  });

  const classes = useStyles();

  return (
    <MuiTooltip
      followCursor={isMobile}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      placement={props.placement}
      classes={props.classes ? {
        tooltip: props.classes.customTooltip,
      } : classes
      }
      title={<span style={{ whiteSpace: 'pre-line' }}>{props.title}</span>}
    >
      <div
        className={`${styles.icon} ${open ? styles.iconHover : ''} ${props.className && props.className}`}
        onClick={() => setOpen(!open)}
      >
        {props.children}
      </div>
    </MuiTooltip>
  );
}
