import { t } from 'i18next';

import { ReactNode } from 'react';
import styles from './styles.module.scss';
import Show from '../Show';
import CustomTooltip from '../CustomTooltip';

type RectangularButtonProps = {
  dataCy?: string;
  text?: string;
  tooltip?: string;
  size?: 'small' | 'large' | 'medium' | 'micro';
  iconSize?: 'iconSmall' | 'iconMedium' | 'iconLarger';
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  disabledText?: String;
  options: Option[];
  icon?: ReactNode;
};

export type Option = {
  title: string;
  action?: () => void;
  disabled?: boolean;
  disabledText?: string;
};

export function RectangularButton(props: RectangularButtonProps) {
  const disabledText = (option: Option) => {
    if (option.title === 'updateOficialLetter') {
      return t('procedureBox.actions.working');
    }
    if (option.disabledText) {
      return (option.disabledText);
    }
    return t('procedureBox.actions.disabled', { name: t(`procedureBox.actions.${props.text}.${option.title}`) });
  };

  const tooltipText = () => {
    return props.tooltip || '';
  };

  const handleOnClick = (option: Option) => {
    if (!option.disabled && option.action) {
      option.action();
    }
  };

  const renderContent = () => {
    if (props.icon) {
      return (
        <div
          data-cy={props.dataCy}
          className={styles.iconContainer}
          onClick={ props.disabled ? () => {} : props.onClick }
        >
          <CustomTooltip
            arrow={props.disabled}
            classes={{ popper: `${props.disabled ? 'tooltipDisabled' : ''}` }}
            title={`${props.disabled ? props.disabledText : props.tooltip || ''}`}
          >
            <div
              className={styles.iconContent}
            >
              <span className={`${props.iconSize ? styles[props.iconSize] : styles.small}`}>
                { props.icon }
              </span>
            </div>
          </CustomTooltip>
        </div>
      );
    }
    return (
      <CustomTooltip
        classes={{ popper: `${props.disabled ? 'tooltipDisabled' : ''}` }}
        arrow={props.disabled}
        title={`${props.disabled ? tooltipText() : ''}`}
      >
        <span
          className={styles.button}
          data-cy={`cypress-${props.text}-menu`}
        >
          {t(`procedureBox.actions.${props.text}.title`)}
        </span>
      </CustomTooltip>
    );
  };

  return (
    <div
      className={`${styles.container} ${props.size ? props.size : styles.medium} ${props.className} ${props.disabled ? styles.disabledButton : ''}`}
    >
      <div
        className={`${styles.dropdown}`}
      >
        {renderContent()}
        <Show if={!props.disabled}>
          <div className={styles.content}>
            {props.options.map((option: Option, index) => (
              <div
                key={index}
                data-cy={`${props.dataCy}-${option.title}`}
                className={`${styles.item} ${option.disabled ? styles.disabledItem : ''}`}
                onClick={() => handleOnClick(option)}
              >
                <CustomTooltip
                  classes={{ popper: `${option.disabled ? 'tooltipDisabled' : ''}` }}
                  arrow={option.disabled}
                  title={`${option.disabled ? disabledText(option) : ''}`}
                  placement={'right-end'}
                >
                  <p>
                    {t(`procedureBox.actions.${props.text}.${option.title}`)}
                  </p>
                </CustomTooltip>
              </div>
            ))}
          </div>
        </Show>
      </div>
    </div>
  );
}
