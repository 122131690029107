import {
  useContext, useEffect, useState,
} from 'react';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import { Button } from '../Button';
import { Table } from '../Table';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../services/procedureBoxService';
import { InputText } from '../InputText';
import Format from '../../helpers/format';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { ResponseResendRelease, Item, Debt } from '../../@types/resendRelease';

type ResendProps = {
  inscriptions: number[];
  setLoading: () => void;
};

export function Resend(props: ResendProps) {
  const { proceduresSeleted, addToasts, setModal } = useContext(ProcedureActionsContext);
  const [inscriptions, setInscriptions] = useState<ResponseResendRelease[]>([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [values, setValues] = useState<Item[]>([]);
  const keyI18n = 'modalResend';

  const loadData = async () => {
    setLoading(true);
    await ProcedureBoxService.inscriptionToResend(props.inscriptions).then((res) => {
      setInscriptions(res);
      const debts: Item[] = [];
      res.map((inscription: ResponseResendRelease) => {
        inscription.debts.map((deb: Debt) => {
          debts.push({ id: deb.id, value: '', valueFormated: '' });
        });
      });
      setValues(debts);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderLoading = () => {
    return (
      <Skeleton variant={'rectangular'} className={ styles.row }/>
    );
  };

  const handleResend = async (debts: any) => {
    const filteredArray = debts.filter((item: any) => item.valueFormated !== '');
    await ProcedureBoxService.updateDebtBalance(proceduresSeleted[0].id, filteredArray).then((res) => {
      addToasts({
        type: 'success',
        text: res.message,
      });
      setModal(undefined);
      props.setLoading();
    }).catch((error) => {
      addToasts({
        type: 'error',
        text: error.data.message,
      });
    });
  };

  const changeValue = (id: number, value: string) => {
    const isExist = values.find((v: Item) => v.id === id);
    const remove = values.filter((v) => v.id !== isExist?.id);
    const removeMask = Format.removeCurrencyFormat(value);
    setValues([...remove, { ...isExist, value: removeMask, valueFormated: value }]);
  };

  const disabledButton = () => {
    const value = values.find((item: Item) => item.valueFormated !== '');
    setDisabled(!value);
  };

  const renderList = () => {
    return (
      <>
        {
          inscriptions?.map((inscription: ResponseResendRelease) => (
            <>
              <div className={styles.title}>
                {t(`${keyI18n}.cda_number`, { number: inscription.cda_number })}
              </div>
              <Table>
                <thead>
                  <tr>
                    <th>{t(`${keyI18n}.table.type`)}</th>
                    <th>{t(`${keyI18n}.table.administrative`)}</th>
                    <th>{t(`${keyI18n}.table.mainValue`)}</th>
                    <th>{t(`${keyI18n}.table.paidValue`)}</th>
                    <th>{t(`${keyI18n}.table.newValue`)}</th>
                    <th>{t(`${keyI18n}.table.sendDate`)}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    inscription?.debts?.map((debt: Debt) => (
                      <tr>
                        <td>{inscription.receipt_type_description}</td>
                        <td>{inscription.administrative_inscription}</td>
                        <td>{Format.currency(parseFloat(debt.main_value))}</td>
                        <td>{Format.currency(parseFloat(debt.paid_value))}</td>
                        <td>
                          <InputText
                            value={values.find((v: Item) => v.id === debt.id)?.valueFormated}
                            onChange={(e) => { changeValue(debt.id, e.currentTarget.value); }}
                            onBlur={() => disabledButton()}
                            dataCy={`cypress-resend-input-${debt.id}`}
                            currency
                          />
                        </td>
                        <td>{Format.formatDateWithHours(debt.updated_at)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </>
          ))
        }
      </>
    );
  };

  return (
    <div className={styles.container}>
      { loading ? renderLoading() : renderList() }
      <footer>
        <Button
          dataCy={'cypress-resend-submit'}
          onClick={() => handleResend(values)}
          title={t(`${keyI18n}.submit`)}
          buttonType='primary' round size='small'
          disabled={disabled}
        />
      </footer>
    </div>
  );
}
