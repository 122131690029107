import {
  useEffect,
  useState,
} from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { FaSlidersH } from 'react-icons/fa';
import EmptySearchDocument from '../../../../assets/emptySearchDocument.svg';
import styles from './styles.module.scss';
import Show from '../../../../components/Show';

export function EmptySearchDocuments() {
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 700px)').matches,
  );
  useEffect(() => {
    window
      .matchMedia('(max-width: 800px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);
  return (
    <div className={styles.noSearch}>
      <Show if={!matches}>
        <img src={EmptySearchDocument} alt={t('general.noOptions')} className={styles.img}/>
      </Show>
      <div>
        <p className={styles.labelTitle}>{
          <Trans
            i18nKey='documentSearch.empty.title'
            components={{ bold: <strong /> }}
          />}
        </p>
        <p className={styles.labelMessage}>{
          <Trans
            i18nKey='documentSearch.empty.labelOne'
            components={{ bold: <strong /> }}
          />}
        </p>
        <p className={styles.labelMessage}>{
          <Trans
            i18nKey='documentSearch.empty.labelTwo'
            components={{ bold: <strong />, i: <FaSlidersH/> }}
          />}
        </p>
        <p className={styles.labelMessage}>{
          <Trans
            i18nKey='documentSearch.empty.labelThree'
            components={{ bold: <strong />, a: <a/> }}
          />}
        </p>
      </div>
    </div>
  );
}
