export const renderColor = (days: number) => {
  if (days > 31) {
    return 'var(--success4)';
  }

  if (days >= 1 && days <= 30) {
    return 'var(--warning6)';
  }

  return 'var(--danger4)';
};
