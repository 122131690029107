import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { t } from 'i18next';
import { Button } from '../Button';
import styles from './styles.module.scss';
import addFilesImg from '../../assets/addFiles.svg';

type DropFilesProps = {
  onDrop?: (files: File[]) => void;
  isMobile: boolean;
};

export function DropFiles(props: DropFilesProps) {
  const onDrop = useCallback((file) => {
    if (props.onDrop) {
      props.onDrop(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className={ styles.container } { ...getRootProps() }>
      <input {...getInputProps()} data-cy={'cypress-drop-file'}/>
      <img src={addFilesImg} className={styles.img}/>
      <Button
        title={t('procedureBox.dropFile.buttom')}
        buttonType='primary'
        size={props.isMobile ? undefined : 'flat'}
        round
      />
      {!props.isMobile && <span className={styles.description}>{t('procedureBox.dropFile.description')}</span>}
    </div>
  );
}
