import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputDate } from '../../../../components/InputDate';
import { InputText } from '../../../../components/InputText';
import FormHelper from '../../../../helpers/form';
import NewProcessService from '../../../../services/newProcessService';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Select from '../../../../components/Select';
import { ProcedureType } from '../../../../@types/processRegistration';
import Format from '../../../../helpers/format';
import { QueryContext } from '../../../../context/queryContext';
import { ProcedureDetailsContext } from '../../../../context/procedureDetailsContextProvider';

type NewPJARecordProps = {
  fluxId?: number;
  closeModal: () => void;
  procedure: ProcedureType | undefined;
};

export function NewPJARecord(props: NewPJARecordProps) {
  const { handleSubmit, control, setValue } = useForm();
  const { addToasts } = useContext(ProcedureActionsContext);
  const { reloadCurrentProcedure } = useContext(QueryContext);
  const { handleLoadTimeline } = useContext(ProcedureDetailsContext);

  const { pathname: url } = useLocation();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [subjects, setSubjects] = useState([]);

  const getNewPjaRecordData = () => {
    NewProcessService.getNewPJARecordData()
      .then((res) => {
        setSubjects(res.data.subject);
      });
  };

  useEffect(() => {
    getNewPjaRecordData();

    if (props.procedure) {
      const pjaData = props.procedure.pja_record;
      setValue('process_number', pjaData.process_number);
      setValue('interested_part', pjaData.interested_part);
      setValue('cpf', pjaData.cpf);
      setValue('job_title', pjaData.job_title);
      setValue('enrollment', pjaData.enrollment);
      setValue('enrollment_extra', pjaData.enrollment_extra);
      setValue('phone_number', pjaData.phone_number);
      setValue('origin', pjaData.origin);
      setValue('subject', pjaData.subject.value);
      setValue('requirement_date', pjaData.requirement_date);
    }
  }, []);

  const submit = (form: any) => {
    setLoading(true);
    const data = { ...{ pja_record: form }, flux_id: props.fluxId };
    const editData = {
      ...{ pja_record: form },
      flux_id: props.procedure && props.procedure.flux_id,
      procedure_id: props.procedure?.pja_record.procedure_id,
    };
    if (props.procedure) {
      NewProcessService.editPJAProcess(editData)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: FormHelper.customToastMessageEdit(res.id),
            });

            reloadCurrentProcedure(url);
            handleLoadTimeline();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            setErrors(err.response.data);
          }
        });
    } else {
      NewProcessService.createPJARecord(data)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();

            addToasts({
              type: 'success',
              text: FormHelper.customToastMessage(res.id),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            setErrors(err.response.data);
          }
        });
    }
  };

  return (
    <form className={ styles.container } onSubmit={handleSubmit(submit)}>
      <Header text={t('procedureBox.newProcedure.newPJARecord.form.data')}/>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.processNumber.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.processNumber.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('process_number', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="interested_part"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.interested_part.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.interested_part.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('interested_part', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="cpf"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.cpf.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.cpf.placeholder')}
                mask="999.999.999-99"
                required
              />
            )}
          />
          {FormHelper.renderErrorField('cpf', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="job_title"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.job_title.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.job_title.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('job_title', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="enrollment"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.enrollment.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.enrollment.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('enrollment', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="enrollment_extra"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.enrollment_extra.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.enrollment_extra.placeholder')}
              />
            )}
          />
          {FormHelper.renderErrorField('enrollment_extra', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.phone_number.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.phone_number.placeholder')}
                mask="(99) 99999-9999"
              />
            )}
          />
          {FormHelper.renderErrorField('phone_number', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="origin"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.origin.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.origin.placeholder')}
              />
            )}
          />
          {FormHelper.renderErrorField('origin', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="subject"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.subject.label')}
                placeholder={t('procedureBox.newProcedure.newPJARecord.form.subject.placeholder')}
                options={subjects}
                returnValue
                defaultValue={props.procedure?.pja_record.subject}
              />
            )}
          />
          {FormHelper.renderErrorField('subject', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="requirement_date"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.newProcedure.newPJARecord.form.requirement_date.label')}
                required
                value={ props.procedure ? Format.formatDate(`${props.procedure?.pja_record.requirement_date}`) : '' }
              />
            )}
          />
          {FormHelper.renderErrorField('requirement_date', errors)}
        </Grid>
      </Grid>

      <div className={styles.submit}>
        <Button
          title={t('procedureBox.newProcedure.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
          dataCy={'submit-consultancy-process'}
        />
      </div>
    </form>
  );
}
