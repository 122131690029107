import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Menu } from '@mui/material';
import { t } from 'i18next';
import { CheckBox } from '../../../components/CheckBox';
import styles from './styles.module.scss';

type SelectListProps = {
  selectAllFromPage: () => void;
  selectAll: () => void;
  clear: () => void;
  partially?: boolean;
  value: boolean;
};

export function SelectList(props: SelectListProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const itens = [
    {
      id: 'allFromPage',
      label: t('reports.general.selectAllFromPage'),
    },
    {
      id: 'all',
      label: t('reports.general.selectAll'),
    },
    {
      id: 'clear',
      label: t('reports.general.clearSelect'),
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (val: string) => {
    handleClose();
    if (val === 'all') {
      props.selectAll();
    } else if (val === 'allFromPage') {
      props.selectAllFromPage();
    } else {
      props.clear();
    }
  };
  return (
    <div className={ styles.selectionBoxContainer }>
      <div className={styles.itemContainer}>
        <CheckBox
          value={props.value}
          partially={props.partially}
          onClick={() => (props.value ? props.clear() : props.selectAll())}
          color='var(--primary4)'
        />
        <a className={`${styles.menuContent} ${open ? styles.itemContainerOpen : ''}`} onClick={open ? handleClose : handleClick}>
          { open ? <FaChevronUp className={ styles.chevron } /> : <FaChevronDown className={ styles.chevron } /> }
        </a>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: 'var(--neutral0);',
            },
          }}
        >
          {itens.map((i) => (
            <a
              className={styles.menuItem}
              onClick={() => handleChange(i.id)}
            >
              <p>{i.label}</p>
            </a>
          ))}
        </Menu>
      </div>
    </div>
  );
}
