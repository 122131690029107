import {
  FormControlLabel, FormLabel, Grid, Radio, RadioGroup, ThemeProvider, Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { t } from 'i18next';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { customTheme } from './CustomTheme';
import { CalculationInfoData } from '../../../@types/requestCalculation';
import { MainCalculateInfos } from './MainCalculateInfos';
import { AttorneyCalculate } from './AttorneyCalculate';
import { Procedure } from '../../../@types/model';
import styles from './styles.module.scss';

type CalculationDataProps = {
  content?: CalculationInfoData;
  dependencies: any;
  subProcedure: Procedure;
  prevStep: () => void;
};

export function CalculationData(props: CalculationDataProps) {
  const [status, setStatus] = useState<'mainCalculate' | 'attorneyCalculate'>('mainCalculate');

  let renderContent;

  switch (status) {
    case 'mainCalculate':
      renderContent = (
        <MainCalculateInfos
          dependencies={props.dependencies}
          subProcedure={props.subProcedure}
          prevStep={() => props.prevStep()}
        />
      );
      break;
    case 'attorneyCalculate':
      renderContent = (
        <AttorneyCalculate
          subProcedure={props.subProcedure}
          prevStep={() => props.prevStep()}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Grid className={styles.container}>
      <ThemeProvider theme={customTheme}>
        <section className={styles.calculateType}>
          <Tooltip placement='left-end' title={t('procedureBox.actions.eletronicCalculation.modal.tooltips.calculationTypeTooltip') || ''}>
            <FormLabel className={styles.label} id='radioGroup'>
              {t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.calculationType')}
              <FaRegQuestionCircle fontSize='11px' color='var(--neutral3)' style={{ marginLeft: 5 }} />
            </FormLabel>
          </Tooltip>

          <RadioGroup
            aria-labelledby='radioGroup'
            name='controlledRadioGroup'
            row
            onChange={(e) => {}}
            className={styles.radioGroup}
          >
            <FormControlLabel
              value={true}
              onChange={() => setStatus('mainCalculate')}
              checked={status === 'mainCalculate'}
              control={<Radio className={styles.radio} />}
              label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.title').toString()}
            />

            <FormControlLabel
              value={false}
              onChange={() => setStatus('attorneyCalculate')}
              checked={status === 'attorneyCalculate'}
              control={<Radio className={styles.radio} />}
              label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.attorneyCalculate.title').toString()}
            />
          </RadioGroup>
        </section>

        <section>
          <div className={styles.infos}>
            <span>{t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.informations')}</span>
          </div>

          { renderContent }
        </section>
      </ThemeProvider>
    </Grid>
  );
}
