import { useEffect, useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { Procedure } from '../../../@types/model';
import i18n from '../../../config/i18n';
import { CDAs } from '../CDAs';
import { Data } from '../Data';
import { Documents } from '../Documents';
import { SamePart } from '../SamePart';
import { PjeExtract } from '../PjeExtract';
import styles from './styles.module.scss';
import { Tab } from './Tab';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { SamePartInfos } from '../../../@types/config';
import { sendEvent } from '../../../helpers/googleAnalytics';
import AlterationOwnership from '../AlterationOwnership';
import { CurrentUser } from '../../../@types/currentUser';

type TabsProps = {
  procedure: Procedure;
};

type TabData = {
  name: string;
  icon?: JSX.Element;
  notification?: boolean;
  content: JSX.Element;
  counter?: number;
};

type LisPendensRelation = {
  hasActive: number;
  hasNotActive: number;
};

export function Tabs(props: TabsProps) {
  const [selected, setSelected] = useState(0);
  const [data, setData] = useState<Record<string, any> | undefined>();
  const [load, setLoad] = useState(false);
  const [counter, setCounter] = useState<LisPendensRelation>();
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);

  const getData = async () => {
    try {
      const res = await ProcedureBoxService.getSamePartInfos(props.procedure.id);
      const { result } = res;

      setData(result);

      setCounter(
        {
          hasActive: result.filter((lis: SamePartInfos) => lis.has_active_relation_with === true).length,
          hasNotActive: result.filter((lis: SamePartInfos) => lis.has_active_relation_with === null).length,
        },
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoad(true);
  };

  const tabs: TabData[] = [
    {
      name: i18n.t('procedureBox.processExtract.tabs.data'),
      content: <Data procedure={props.procedure} />,
    },
    {
      name: i18n.t('procedureBox.processExtract.tabs.documents'),
      content: <Documents procedure={props.procedure} />,
    },
    {
      name: i18n.t(
        'procedureBox.processExtract.tabs.withMe',
        {
          samePartLength: counter?.hasNotActive !== undefined && counter?.hasNotActive > 0
            ? ` (${counter?.hasNotActive})`
            : '',
        },
      ),
      content: <SamePart procedure={props.procedure} listPendens={data} load={load} onChange={getData} />,
      counter: counter?.hasActive,
    },
    {
      name: i18n.t('procedureBox.processExtract.tabs.cdas', { cdas: props.procedure.total_inscriptions }),
      content: <CDAs procedure={props.procedure}/>,
    },
  ];

  if (
    props.procedure.flux.code === 'FLU0034'
    && currentUser?.functions_code?.includes('FUN0049')
    && props.procedure.individual_id === currentUser.id
  ) {
    tabs.push({
      name: i18n.t('procedureBox.ownership.title'),
      content: <AlterationOwnership />,
    });
  }

  if (props.procedure?.pje) {
    const pjeExtractTab = {
      name: i18n.t('procedureBox.processExtract.tabs.pjeExtract'),
      icon: <FaEnvelope />,
      notification: false,
      content: <PjeExtract procedureId={props.procedure.id} />,
    };

    tabs.push(pjeExtractTab);
  }

  const handleTabClick = (index: number) => {
    sendEvent({
      event_name: 'show_procedure_tab',
      label: tabs[index].name,
    });
    setSelected(index);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            name={tab.name}
            icon={tab.icon}
            notification={tab.notification}
            selected={selected === index}
            counter={tab.counter}
            onClick={() => handleTabClick(index)}
          />
        ))}
      </div>

      <div>{tabs[selected].content}</div>
    </div>
  );
}
