import { t } from 'i18next';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './styles.module.scss';
import ReportsService from '../../../../services/reportsService';

type ExportButtonProps = {
  paymentOrderIds: number[];
  dataCy?: string;
};

export function ExportButton(props: ExportButtonProps) {
  const handleExport = (type: string) => {
    if (props.paymentOrderIds.length > 0) {
      if (type === 'pdf') {
        ReportsService.getExportPaymentOrderPdf(props.paymentOrderIds).then((res) => {
          if (res.file) {
            window.open(res.file, '_blank');
          }
        });
      } else if (type === 'xls') {
        ReportsService.getExportPaymentOrderXls(props.paymentOrderIds).then((res) => {
          if (res.file) {
            window.open(res.file, '_blank');
          }
        });
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type: string) => {
    setAnchorEl(null);
    handleExport(type);
  };

  return (
    <div className={styles.container}>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.button}
        data-cy={'cypress-payment-order-export-buttom'}
      >
        <span>{t('reports.paymentOrder.export.title')}</span>
        { open ? <FaChevronUp /> : <FaChevronDown /> }
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          className: styles.menuItem,
        }}
        PaperProps={{
          className: styles.paper,
        }}
      >
        <MenuItem
          onClick={() => handleClose('pdf')}
          data-cy={'cypress-export-pdf-button'}
        >
          {t('reports.paymentOrder.export.pdf')}
        </MenuItem>
        <MenuItem
          onClick={() => handleClose('xls')}
          data-cy={'cypress-export-xls-button'}
        >
          {t('reports.paymentOrder.export.xls')}
        </MenuItem>
      </Menu>
    </div>
  );
}
