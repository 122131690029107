import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { Document, GetVariablesToSignRequest } from '../../@types/config';
import { AGILIS_API_URL, EPGM_URL, LOCAL_JAVA_SIGNER_SERVICE } from '../../config/constants';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { SelectedProcedures } from '../SelectedProcedures';
import { DocumentList } from './DocumentList';
import styles from './styles.module.scss';
import { Login } from '../Petition/Login';

type SignerProps = {
  multipleActions?: MultipleActionStep;
};
export function Signer(props: SignerProps) {
  const {
    setAlert, proceduresSeleted, removeProcedures, setModal,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [connectionToJava, setConnectionToJava] = useState<boolean>(false);

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.sign_document.alertConfirmation.titleManyProcedures'
          components={{ bold: <strong /> }}
          values={{ number: proceduresSeleted.length, count: documents.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.sign_document.alertConfirmation.title'
        components={{ bold: <strong /> }}
        values={{ number: proceduresSeleted[0].process_number, count: documents.length }}
      />
    );
  };

  const renderText = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.sign_document.alertConfirmation.textManyProcedures'
          components={{ bold: <strong /> }}
          values={{ number: proceduresSeleted.length, count: documents.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.sign_document.alertConfirmation.text'
        components={{ bold: <strong /> }}
        values={{ number: proceduresSeleted[0].process_number, count: documents.length }}
      />
    );
  };

  const handleConfirm = (password?: String) => {
    const data: GetVariablesToSignRequest = { procedures: [] };
    proceduresSeleted.map((procedure) => {
      const procedureDocuments = documents.filter((doc) => doc.procedure_id === procedure.id);
      data.procedures.push({
        procedure_id: procedure.id,
        progress_id: procedureDocuments[0].progress_id,
        attachments: procedureDocuments.map((doc) => {
          return {
            attachment_url: doc.url,
            attachment_id: doc.id,
            attachment_signed_url: doc.options.data.url_signed_file,
            control_code: (Math.random() + 1).toString(36).substring(4).toUpperCase(),
          };
        }),
      });
    });

    let body = {};
    if (connectionToJava && password !== undefined) {
      body = {
        password,
        pathfile: documents.map((doc) => doc.url).join(),
        idfile: documents.map((doc) => doc.id).join(),
        hostupload: `${AGILIS_API_URL}/api/activity/attachment_selections/upload_signed_file`,
        progressid: documents.map((doc) => doc.progress_id).join(),
        procedureid: documents.map((doc) => doc.procedure_id).join(),
        controlcode: documents.map(() => (Math.random() + 1).toString(36).substring(4).toUpperCase()).join(),
        classname: documents.map((doc) => doc.class_name).join(),
      };
    } else {
      body = {
        codebase: EPGM_URL,
        path_files: documents.map((doc) => doc.url).join(),
        id_files: documents.map((doc) => doc.id).join(),
        host_upload: `${AGILIS_API_URL}/api/activity/attachment_selections/upload_signed_file`,
        progress_id: documents.map((doc) => doc.progress_id).join(),
        procedure_id: documents.map((doc) => doc.procedure_id).join(),
        control_codes: documents.map(() => (Math.random() + 1).toString(36).substring(4).toUpperCase()).join(),
        class_name: documents.map((doc) => doc.class_name).join(),
        direction: 'from-page',
      };
    }

    const procedures = proceduresSeleted;
    const type = 'signer';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: documents.length,
      successList: [],
      failedList: [],
      body: {
        data: body,
        documents,
        documentsToRequest: data,
      },
      multipleActions: props.multipleActions,
      localSigner: connectionToJava,
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }

    if (!connectionToJava) {
      window.postMessage(batch.body.data, '*');
    }

    setAlert(undefined);
    setModal(undefined);
  };

  const handleConfirmAlert = (password?: String) => {
    setAlert({
      visible: true,
      title: renderTitle(),
      text: renderText(),
      handleConfirm: () => handleConfirm(password),
    });
  };

  const handleSubmit = () => {
    if (connectionToJava) {
      setModal({
        visible: true,
        open: true,
        size: 'small',
        title: t('procedureBox.actions.signer.signer'),
        children: <Login
                    signer
                    submit={(password) => handleConfirmAlert(password)}
                    goBack={() => setModal(undefined)}
                  />,
        handleClose: () => setModal(undefined),
      });
    } else {
      handleConfirmAlert();
    }
  };

  useEffect(() => {
    fetch(`${LOCAL_JAVA_SIGNER_SERVICE}/signer/api/hello`).then((resp) => {
      setConnectionToJava(resp.status === 200);
    });
  }, []);

  return (
    <div className={ styles.container }>
      <SelectedProcedures />

      <DocumentList onChange={(val) => setDocuments(val)}/>

      <div className={styles.submit}>
        <Button
          dataCy={'cypress-submit-signer-button'}
          title={t('procedureBox.actions.sign_document.modal.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          disabled={documents.length === 0}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
