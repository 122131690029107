import Axios from '../config/api';

export default class SearchService {
  public static async documents(query: string, filter: string, page: number) {
    return Axios.get(`/api/search_documents?q=${query || ''}&page=${page}&${filter}`).then((res) => res.data);
  }

  public static async getFilters() {
    return Axios.get('/api/search_documents/get_filters').then((res) => res.data);
  }
}
