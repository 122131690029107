import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { Document } from '../../@types/config';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { SelectedProcedures } from '../SelectedProcedures';
import { DocumentList } from './DocumentList';
import styles from './styles.module.scss';
import { AuthA1 } from './AuthA1';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import A1Service from '../../services/a1Service';
import { SignerParams } from '../../@types/a1';
import { Procedure } from '../../@types/model';

type SignerA1Props = {
  multipleActions?: MultipleActionStep;
};

export function SignerA1(props: SignerA1Props) {
  const {
    setModal,
    proceduresSeleted,
    removeProcedures,
    addToasts,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [documents, setDocuments] = useState<Document[]>([]);

  const handleConfirmLink = (
    docs: any,
    controlId: number,
    procedure: Procedure,
    documentsToProcedure: Document[],
  ) => {
    const body = {
      documents: docs,
      controlId,
    };
    const procedures = [procedure];
    const type = 'signerA1';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: documentsToProcedure.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    setModal(undefined);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
  };

  const handleSignerA1 = (password: string = '') => {
    proceduresSeleted.map((procedure) => {
      const documentsToProcedure = documents.filter((doc: Document) => doc.procedure_id === procedure.id);

      const result = documentsToProcedure.map((doc: Document) => {
        return {
          file_class: doc.class_name,
          file_id: doc.id,
          procedure_id: doc.procedure_id,
          control_code: (Math.random() + 1).toString(36).substring(4).toUpperCase(),
        };
      });

      const params: SignerParams = {
        procedure_id: procedure.id,
        documents: result,
        password,
      };

      A1Service.signer(params)
        .then((res) => {
          if (res && res.id) {
            handleConfirmLink(result, res.id, procedure, documentsToProcedure);
          }
        })
        .catch((error) => {
          if (error.data.expired) {
            addToasts({
              type: 'error',
              text: (
                <Trans
                  i18nKey='general.modalImportA1.cert.error'
                  components={{ b: <b /> }}
                />),
            });

            setModal(undefined);
          } else {
            addToasts({
              type: 'error',
              text: error.data.error,
            });
          }
        });
    });
  };

  const openModalAuthA1 = () => {
    setModal({
      visible: true,
      open: true,
      size: 'small',
      title: t('procedureBox.actions.signerA1.title'),
      children: <AuthA1
                  submit={(password) => handleSignerA1(password)}
                  goBack={() => setModal(undefined)} />,
      handleClose: () => setModal(undefined),
    });
  };

  return (
    <div className={styles.container}>
      <SelectedProcedures />

      <DocumentList onChange={(val) => setDocuments(val)} />

      <div className={styles.submit}>
        <Button
          title={t('procedureBox.actions.sign_document.modal.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          disabled={documents.length === 0}
          onClick={openModalAuthA1}
        />
      </div>
    </div>
  );
}
