import { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { FaClipboardList, FaDownload, FaRegQuestionCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF as JSPdf } from 'jspdf';
import { SearchDashboard } from '../../../components/SearchDashboard';
import NestedSelect from '../../../components/NestedSelect';
import { DashboardDigitalProcessesContext } from '../../../context/dashboardDigitalProcessesContext';
import stylesComponent from './styles.module.scss';
import styles from '../DebtDashboard/styles.module.scss';
import { Box } from '../../../components/Box';
import { getLastUpdateDate } from '../../../helpers/getLastUpdateDate';
import { ProceduresCollection } from '../../../components/ProceduresCollection';
import { PeformanceChart } from '../../../components/PeformanceChart';
import { ProductivityChart } from '../../../components/ProductivityChart';
import DashboardService from '../../../services/dashboardService';
import { OptionType, NestedOptionType, ChildrenOptionType } from '../../../@types/config';
import { ListFieldsAndIndividuals, ProcessResponse } from '../../../@types/digitalProcessesDashboard';
import { Tooltip } from '../../../components/Tooltip';
import { Unauthorized } from '../../../components/Unauthorized';
import Show from '../../../components/Show';
import AuthContext from '../../../context/authContext';

const legend = [
  {
    title: 'total.title',
    color: 'var(--neutral3)',
    description: 'total.description',
  },
  {
    title: 'exit.title',
    color: 'var(--success4)',
    description: 'exit.description',
  },
  {
    title: 'new.title',
    color: 'var(--primary4)',
    description: 'new.description',
  },
];

export function DigitalProcessesDashboard() {
  const { user } = useContext(AuthContext);

  const [exportChart, setExportChart] = useState(false);
  const [procedure, setProcedure] = useState<OptionType>();
  const [procedures, setProcedures] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const navigate = useNavigate();
  const {
    setDashboardFilter, unauthorizedProcedure, procedureTotals, totalProcedures, performance, productivity, loading,
  } = useContext(DashboardDigitalProcessesContext);
  const [fields, setFields] = useState<NestedOptionType[]>([]);
  const [individualOption, setIndividualOption] = useState<NestedOptionType | ChildrenOptionType | null>();
  const [field, setField] = useState<NestedOptionType | ChildrenOptionType | null>();
  const defaultField: NestedOptionType = {
    label: t('general.dashboard'), value: user?.id, level: 2, children: [],
  };
  const loadFields = async () => {
    const response: ListFieldsAndIndividuals = await DashboardService.getFieldsWithIndividuals();
    const fieldsWithIndividuals = response.fields.filter((el) => el.individuals_by_main_field.length !== 0);
    const mountOptions = fieldsWithIndividuals.map((f) => {
      return {
        label: `${f.acronym} - ${f.name}`,
        value: f.id,
        level: 1,
        children:
          f.individuals_by_main_field.map((i: any) => {
            return { label: i.name, value: i.id, level: 2 };
          }),
      };
    });

    if (response.full_access_agilis !== null) {
      const fullAccessAgilis = { label: response.full_access_agilis.name, value: response.full_access_agilis?.id, level: 1 };
      setFields([fullAccessAgilis, ...mountOptions]);
    } else {
      setFields(mountOptions);
    }
  };

  const handleField = (option: NestedOptionType | ChildrenOptionType) => {
    setIndividualOption(null);
    setField(null);
    if (option) {
      if (option.level && option.level === 1) {
        setField(option);
        setDashboardFilter({
          fieldId: option.value,
          individualId: undefined,
        });
      } else {
        setIndividualOption(option);
        setDashboardFilter({
          fieldId: undefined,
          individualId: option.value,
        });
      }
    }
  };

  const loadProcedures = async () => {
    setProcedures([]);
  };

  const handleProcedure = (value: OptionType) => {
    if (value.label.length > 0) {
      setProcedure(value);
      setInputValue(value.label);
    }

    if (procedure && inputValue.length > 0) {
      sessionStorage.setItem('query', `?q[procedure_numbers_num_cont]=${value.label}`);
      navigate('/procedure_collection');
    }
  };

  const loadDate = async () => {
    const response = await DashboardService.getLastUpdatedInDashboard();

    if (response.digital_processes != null) {
      setLastUpdated(t('home.dashboard.lastUpdated', {
        date: getLastUpdateDate(response.digital_processes),
        interpolation: { escapeValue: false },
      }));
    }
  };

  const handleChangeProcedures = async () => {
    if (inputValue.length > 0) {
      const response: any = await DashboardService.getProcedures(inputValue);
      setProcedures(response.map((p: ProcessResponse) => { return { label: p.num, value: p.procedure_id }; }));
    }
  };

  const searchFromEpgm = () => {
    if (procedure && inputValue.length > 0) {
      sessionStorage.setItem('query', `?q[procedure_numbers_num_cont]=${procedure.label}`);
      navigate('/procedure_collection');
    }
  };

  const createPDF = async () => {
    setExportChart(true);
    setTimeout(() => {
      document.getElementById('productivityChart')!.style.minHeight = '450px';
      document.getElementById('productivityChart')!.style.maxHeight = '9000px';
      document.getElementById('btnExport')!.style.visibility = 'hidden';
      const htmlSource = document.getElementById('digitalProcessesCharts');
      const filename = 'digitalProcessesReport';

      if (!htmlSource) {
        return;
      }

      html2canvas(htmlSource, {
        windowWidth: 1900,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        const pdf = new JSPdf('p', 'mm', 'a4');

        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save(filename);
        document.getElementById('productivityChart')!.removeAttribute('style');
        document.getElementById('btnExport')!.style.visibility = 'visible';
        setExportChart(false);
      });
    }, 500);
  };

  useEffect(() => {
    loadProcedures();
  }, []);

  useEffect(() => {
    loadDate();
    loadFields();
  }, []);

  useEffect(() => {
    handleChangeProcedures();
  }, [inputValue]);

  return (
    <div className={ styles.container }>
      <Grid
          container
          columns={{ xs: 1, sm: 1, md: 1 }}
          spacing={2}
      >
        <Grid item
          xs={1}
          md={1}
        >
          <div className={ `${unauthorizedProcedure ? styles.content : ''}` }>
            <div className={ styles.topHeader }>
            <div className={ styles.dashboardContent} >
                <div className={ styles.dashboardTitle }>
                  <span className={ styles.dashboard }>{t('home.dashboard.title')}:</span>
                  <Tooltip
                    title={ t('home.dashboard.digitalProcesses.tooltip') }
                  >
                    <FaRegQuestionCircle />
                  </Tooltip>
                </div>
                <span className={ styles.lastUpdated }>{ lastUpdated }</span>
              </div>
              <a href='/procedure_box'>{t('home.dashboard.digitalProcesses.goToBox')}</a>
            </div>
            <div id='digitalProcessesCharts'>
              <Grid
                container
                columns={{ xs: 1, sm: 1, md: 4 }}
                className={ styles.title }
                spacing={2}
              >
                <Grid item
                  xs={1}
                  sm={1}
                  md={2}
                >
                  <FaClipboardList/>
                  <span>{t('home.dashboard.digitalProcesses.title')}</span>
                </Grid>
                <Grid item
                  xs={1}
                  sm={1}
                  md={2}
                >
                  <SearchDashboard
                    options={procedures}
                    placeholder={t('dashboard.digitalProcesses.productivityChart.placeholderProcess')}
                    value={procedure}
                    inputValue={inputValue}
                    onChange={handleProcedure}
                    onChangeInput={(e: any) => setInputValue(e.target.value)}
                    onClick={searchFromEpgm}
                  />
                </Grid>
                <Grid item
                  xs={1}
                  sm={1}
                  md={3}
                >
                  <Show if={!exportChart}>
                    <NestedSelect
                      options={fields}
                      placeholder={t('dashboard.digitalProcesses.searchField.placeholder')}
                      value={ field || individualOption || defaultField}
                      defaultValue={defaultField}
                      onChange={handleField}
                    />
                  </Show>
                  <Show if={exportChart}>
                    <p className={styles.chartTitle}>{field?.label || individualOption?.label || defaultField?.label}</p>
                  </Show>
                </Grid>
                <Grid item
                  xs={1}
                  sm={1}
                  md={1}
                >
                  <Show if={
                      !loading && procedureTotals !== null && totalProcedures !== 0
                      && performance !== null && productivity !== null
                    }>
                    <div id='btnExport' className={ styles.button }>
                      <span onClick={createPDF}>{t('home.dashboard.actions.export')}</span>
                      <FaDownload />
                    </div>
                  </Show>
                </Grid>
              </Grid>
              <Grid
                container
                columns={{ xs: 2, sm: 8, md: 12 }}
                className={ stylesComponent.list }
                spacing={1}
              >
                { legend.map((option: any) => (
                  <Grid item
                    xs={1}
                    sm={2}
                    md={3}
                  >
                    <Box
                      title={t(`dashboard.digitalProcesses.processLegend.${option.title}`)}
                      color={option.color}
                      tooltip={t(`dashboard.digitalProcesses.processLegend.${option.description}`)}
                    />
                  </Grid>
                ))}
              </Grid>
              <ProceduresCollection />
              <PeformanceChart />
              <ProductivityChart />
            </div>
          </div>
        </Grid>
      </Grid>
      <Show if={unauthorizedProcedure}>
        <Unauthorized title={t('home.dashboard.digitalProcesses.unauthorized')} />
      </Show>
    </div>
  );
}
