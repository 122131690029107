import { useState } from 'react';
import { Modal as ModalMui } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import SignerImage from '../../../assets/signerA1.png';
import SearchParts from '../../../assets/searchParts.png';
import styles from './styles.module.scss';

type YouTubeThumbnailProps = {
  videoId: string;
  thumbnail?: string;
};

export function YouTubeThumbnail(props: YouTubeThumbnailProps) {
  const [open, setOpen] = useState(false);

  const youtubeVideo = () => {
    return (
    <div className={styles.modal}>
      <iframe className={styles.video}
        title="YouTube Video"
        src={`https://www.youtube.com/embed/${props.videoId}`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    );
  };

  const handleThumbnailClick = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const returnImage = (thumbnail: string) => {
    switch (thumbnail) {
      case 'searchParts':
        return SearchParts;
      case 'signerA1':
        return SignerImage;
      default:
        break;
    }

    return undefined;
  };

  return (
    <>
      <img className={styles.youtubeImg}
        src={props.thumbnail ? returnImage(props.thumbnail) : `https://img.youtube.com/vi/${props.videoId}/maxresdefault.jpg`}
        alt="Video Thumbnail"
        onClick={handleThumbnailClick}
      />
      <ModalMui
        open={open || false}
        onClose={handleCloseModal}
        disableAutoFocus={true}
      >
        <div
          className={`${styles.box} ${styles.medium}`}
        >
          <a className={styles.close} onClick={handleCloseModal}>
            <FaTimes/>
          </a>
          <div className={styles.body}>{youtubeVideo()}</div>
        </div>
      </ModalMui>
    </>
  );
}

export default YouTubeThumbnail;
