import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SearchBar } from '../../components/SearchBar';
import { Breadcumb } from '../../components/Breadcumb';
import styles from './styles.module.scss';
import ProcedureMarkersProvider from '../../context/procedureMarkersContext';
import { Modal } from '../../components/Modal';
import { LoadingBottomAlerts } from '../../components/LoadingBottomAlerts';
import { Alert } from '../../components/Alert';
import { AppContextProvider } from '../../context/appContextProvider';
import { ToastsList } from '../../components/ToastsList';
import { CollectionHome } from '../../components/CollectionHome';
import QueryProvider from '../../context/queryContext';
import ProcedureActionsProvider from '../../context/procedureActionsContext';

type ProcedureCollectionProps = {
  title?: string;
};

export function ProcedureCollection(props: ProcedureCollectionProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <>
      <ProcedureActionsProvider>
        <QueryProvider>
          <AppContextProvider>
            <ProcedureMarkersProvider>
              <ToastsList />
              <Grid
                container
                columns={{ xs: 2, sm: 12, md: 12 }}
                spacing={2}
                className={ styles.childrenContent }
              >
                <Grid item xs={12} className={ styles.title }>
                  <Grid item xs={3} sm={6}>
                    <h1>{ t('procedureCollections.title') }</h1>
                    <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <SearchBar />
                  <CollectionHome />
                </Grid>
              </Grid>
              <Modal />
              <LoadingBottomAlerts />
              <Alert />
            </ProcedureMarkersProvider>
          </AppContextProvider>
        </QueryProvider>
      </ProcedureActionsProvider>
    </>
  );
}
