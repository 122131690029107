import { useState, useContext } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  ThemeProvider,
  Grid,
} from '@mui/material';
import {
  FaRegQuestionCircle,
} from 'react-icons/fa';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Button } from '../../Button';
import { customTheme } from './customTheme';
import { Tooltip } from '../../Tooltip';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Batch } from '../../../@types/backgroundActions';
import { InputText } from '../../InputText';
import { InputDate } from '../../InputDate';
import { isValidDate } from '../../../helpers/validator';
import Show from '../../Show';
import CustomSwitch from '../../CustomSwitch';

export function OfficialData() {
  const {
    proceduresSeleted, setAlert, removeProcedures, setModal,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);

  const [checked, setChecked] = useState(false);
  const [valueOption, setValueOption] = useState<number | undefined>(undefined);
  const [dateValue, setDateValue] = useState('');

  const handleNextStep = () => {
    if (valueOption === 0) {
      return 'prepareDocument';
    }
    if (valueOption === 1) {
      return 'attachFiles';
    }
    return '';
  };

  const handleConfirm = () => {
    const body = {
      procedureId: proceduresSeleted[0].id,
      copy_interested_parts: checked,
      deadline: dateValue,
      nextStep: handleNextStep(),
      attachAfter: handleNextStep() !== '',
    };

    const procedures = proceduresSeleted;
    const type = 'subProcedure';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
    };

    addBatch(batch);
    removeProcedures(procedures);
    setAlert(undefined);
    setModal(undefined);
  };

  const handleAlert = () => {
    if (dateValue.replace(/\D/g, '').length === 8 && isValidDate(dateValue)) {
      setAlert({
        visible: true,
        title: t(
          'procedureBox.actions.subProcedure.modal.judicialOrderModal.alert.title',
          { processNumber: proceduresSeleted[0].process_number },
        ),
        text: t('procedureBox.actions.subProcedure.modal.judicialOrderModal.alert.text'),
        type: 'warning',
        cancelText: t('general.no'),
        cancelType: 'default',
        confirmText: t('general.yes'),
        confirmType: 'primary',
        handleConfirm: () => handleConfirm(),
      });
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className={styles.content}>
        <div className={styles.fields}>
          <Grid
            container
            columns={{ xs: 1, sm: 12, md: 12 }}
            className={styles.content}
          >
            <Grid item xs={1} sm={2} md={2} className={styles.input}>
              <InputText
                value={`${t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.originNumberMask')}`}
                label={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.originNumber')}
                tooltip={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.originNumber')}
                readOnly
              />
            </Grid>
            <Grid item xs={1} sm={2} md={2} className={styles.input}>
              <InputDate
                tooltip={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.tooltips.deadline')}
                label={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.fields.deadline')}
                value={dateValue}
                onChange={(newValue) => setDateValue(newValue)}
                required
              />
              <Show if={dateValue.replace(/\D/g, '').length === 8 && !isValidDate(dateValue)}>
                <p className={styles.error}>{t('general.invalidDate')}</p>
              </Show>
            </Grid>
          </Grid>
        </div>

        <div className={styles.toogle}>
          <CustomSwitch
            onClick={() => setChecked(!checked)}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <p>
            {t('procedureBox.actions.subProcedure.modal.judicialOrderModal.toggleText')}
          </p>
          <Tooltip placement='right-start' title={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.copyPartsTooltip')}>
            <FaRegQuestionCircle />
          </Tooltip>
        </div>

        <FormControl className={styles.content}>
          <FormLabel className={styles.label} id='radioGroup'>
            {t('procedureBox.actions.subProcedure.modal.judicialOrderModal.continue')}
          </FormLabel>
          <RadioGroup aria-labelledby='radioGroup' className={styles.options}>
            <FormControlLabel
              label={t(
                'procedureBox.actions.subProcedure.modal.judicialOrderModal.options.elaborate_document',
              ).toString()}
              checked={valueOption === 0}
              onChange={() => setValueOption(0)}
              control={<Radio />}
              className={styles.select}
            />
            <FormControlLabel
              label={t(
                'procedureBox.actions.subProcedure.modal.judicialOrderModal.options.attach',
              ).toString()}
              checked={valueOption === 1}
              onChange={() => setValueOption(1)}
              control={<Radio />}
              className={styles.select}
            />
          </RadioGroup>
        </FormControl>

        <div className={styles.footer}>
          <Button
            size='flat'
            title={t('procedureBox.actions.subProcedure.modal.judicialOrderModal.buttons.createSubProcess')}
            disabled={!dateValue || !isValidDate(dateValue) || valueOption === undefined}
            onClick={() => handleAlert()}
            round
            buttonType='primary'
          />
        </div>
      </div>
    </ThemeProvider>
  );
}
