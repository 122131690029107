import { ReactNode } from 'react';
import { Item } from '../Item';
import ActiveDebtLogo from '../../../assets/agilis-divida-logo.svg';
import ActiveDebtLogoLetter from '../../../assets/agilis-divida-logo-letter.svg';
import './styles.scss';

type GroupMenuProps = {
  title: string;
  isOpen: boolean;
  isFooter?: boolean;
  items: ItemsProps[];
  closeMenu: () => void;
};

type ItemsProps = {
  text: string;
  icon?: ReactNode;
  href?: string;
  subItems?: ItemsProps[];
  logo?: boolean;
};

export function GroupMenu(props: GroupMenuProps) {
  return (
    <div className={`groupMenu ${props.isOpen ? 'groupMenuOpened' : ''}`}>
      <div className='groupMenuContent'>
        { props.isOpen ? <span className={`groupTitle ${props.isFooter ? 'groupTitleOpened' : ''}`}>{props.title}</span> : null }
        { props.items.map((item) => (
          item.logo ? (
            <a
              href={item.href}
              target={'_blank'}
            >
              <img
                className={`${props.isOpen ? 'logo' : 'logoClosed'}`}
                src={props.isOpen ? ActiveDebtLogo : ActiveDebtLogoLetter}
                alt={item.text}
              />
            </a>
          ) : (
            <Item
              key={item.text}
              item={ Object(item) }
              hover={ props.isOpen }
              closeMenu={props.closeMenu}
            />
          )
        ))}
      </div>
    </div>
  );
}
