import './styles.scss';
import { useContext } from 'react';
import { ThemeContext } from '../../context/themeContext';
import logo from '../../assets/logo-agilis-process.svg';
import logoDark from '../../assets/logo-agilis-process-dark.svg';
import { BarButton } from '../Sidebar/BarButton';
import { Profile } from '../Profile';
import { Notifications } from '../Notifications';
import CustomTooltip from '../CustomTooltip';

type TopbarProps = {
  openMobile?: boolean;
  setOpenMobile: (e: boolean) => void;
};

export function Topbar(props: TopbarProps) {
  const { themeMode } = useContext(ThemeContext);

  const changeImageLogo = () => {
    if (themeMode === 'light') {
      return logo;
    }

    return logoDark;
  };

  return (
    <div className='topbarContainer' id='topbar'>
      <div className='rightItems'>
        <BarButton
          opened={ false }
          onClick={ () => props.setOpenMobile(true) }
          mobile
        />
        <CustomTooltip title='Home'>
          <a href='/'>
            <img src={changeImageLogo()} alt='e-PGM' />
          </a>
        </CustomTooltip>
      </div>
      <div className='rightItems'>
        <Notifications />
        <Profile />
      </div>
    </div>
  );
}
