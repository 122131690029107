import { ReactNode } from 'react';
import styles from './styles.module.scss';

type GroupFilterProps = {
  children?: ReactNode;
  subtitle: string;
};

export function GroupFilter(props: GroupFilterProps) {
  return (
    <div className={ styles.container }>
      <h5 className={ styles.subtitle }>{ props.subtitle }</h5>
      { props.children }
    </div>
  );
}
