import { createTheme } from '@mui/material/';

export const globalTheme = (createTheme as any)({
  palette: {
    primary: {
      main: '#1eb1e7',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: '0',
          '& .MuiChip-deleteIcon': {
            fontSize: '13px !important',
          },
          '& .MuiOutlinedInput-root': {
            padding: '6px 6px',
          },
          '& .MuiAutocomplete-input': {
            padding: '0 0 0 4px !important',
          },
          '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 10px)',
            right: '7px',
            'button:hover': {
              backgroundColor: 'transparent',
            },
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--neutral2)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--primary4) !important',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--primary4) !important',
          },
        },
        inputRoot: {
          backgroundColor: 'var(--primary1)',
          fontSize: '13px',
          '& svg': {
            color: 'var(--neutral5);',
          },
        },
        tag: {
          '& div': {
            maxWidth: '100%',
          },
        },
        listbox: {
          backgroundColor: 'var(--neutral0)',
          maxHeight: '250px',
          fontSize: '13px',
          '.MuiAutocomplete-option[aria-selected="true"]': {
            color: 'var(--primary4)',
            fontWeight: 'bold',
            backgroundColor: 'var(--neutral0)',
          },
          '.MuiAutocomplete-option[aria-selected="false"].Mui-focused': {
            backgroundColor: 'var(--neutral2)',
          },
          '.MuiAutocomplete-option:first-of-type': {
            borderBottom: '1px solid var(--neutral2)',
          },
        },
      },
    },
  },
});
