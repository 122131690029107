import { useContext, useEffect, useState } from 'react';
import {
  FaSearch,
  FaSlidersH,
  FaTimes,
} from 'react-icons/fa';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchButton } from './SearchButton';
import { FilterButton } from './FilterButton';
import styles from './styles.module.scss';
import { FilterAdvanced } from './FilterAdvanced';
import { QuickSearch } from './QuickSearch';
import { QueryContext } from '../../context/queryContext';

export function SearchBar() {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();

  const {
    setQuickSearch, clearState, hasAnyFilter, setHasAnyFilter,
  } = useContext(QueryContext);
  const [searchInput, setSearchInput] = useState('');
  const [isOpenFilters, setOpenFilters] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [onError, setOnError] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1200px)').matches,
  );

  useEffect(() => {
    window
      .matchMedia('(min-width: 1200px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  const handleSearch = async (data?: any) => {
    sessionStorage.removeItem('query');
    const formatedQuery = data?.searchInput?.replace(/[^a-z0-9]/gi, '') || '';
    if (formatedQuery?.length <= 0 && hasAnyFilter === false) {
      setOnError(true);
    } else {
      setQuickSearch(formatedQuery);
      setHasAnyFilter(true);
      setOnError(false);
    }
  };

  const handleCloseSearch = () => {
    setSearchInput('');
    clearState();
    setHasAnyFilter(false);
  };

  const handleFocused = () => {
    setOnFocus(true);
    setOnError(false);
  };

  return (
    <form onSubmit={handleSubmit(handleSearch)} data-cy={'procedure-collection-form-search'}>
      <div className={styles.containerSearch}>
        <div data-cy={'procedure-collection-form-border'} className={`${styles.searchBarContainer} ${onError ? styles.searchBarContainerError : onFocus ? styles.searchBarContainerFocused : ''}`} >
          <div className={styles.inputContainer}>
            <Controller
              control={control}
              name={'searchInput'}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  placeholder={`${matches ? t('procedureBox.searchBar.placeholder') : t('procedureBox.searchBar.placeholderShort')}`}
                  onFocus={handleFocused}
                  onBlur={() => setOnFocus(false)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                  data-cy={'procedure-collection-input-search'}
                />
              )}
            />
            { searchInput.length > 0 && (
              <button className={styles.closeSearchButton} onClick={() => handleCloseSearch()}>
                <FaTimes />
              </button>
            )}
          </div>
          <FilterButton icon={ <FaSlidersH />} onClick={ () => setOpenFilters(!isOpenFilters)} isOpen={isOpenFilters} />
          <SearchButton active={hasAnyFilter} onSearchClick={() => handleSearch()} icon={ <FaSearch />} />
        </div>
        <FilterAdvanced isOpen={isOpenFilters} closeFilter={() => setOpenFilters(false)} />
        <QuickSearch
          removeFilter={() => handleCloseSearch()}
          isOpen={hasAnyFilter} />
      </div>
    </form>
  );
}
