import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  FaCheckCircle, FaFileAlt, FaRegQuestionCircle, FaTrashAlt,
} from 'react-icons/fa';
import { Tooltip as TooltipLabel } from '../../Tooltip';
import { AttachDocument, OptionType } from '../../../@types/config';
import { ResponsiveList } from '../ResponsiveList';
import styles from './styles.module.scss';
import Select from '../../Select';
import { orderOptionType } from '../../../helpers/strings';
import ProcedureBoxService from '../../../services/procedureBoxService';
import CustomTooltip from '../../CustomTooltip';

type ListFilesProps = {
  documents: any[];
  removeFile: (files: AttachDocument[]) => void;
  handleSign: (document: AttachDocument) => void;
  handleType: (documents: AttachDocument[], type: string) => void;
  handleProceeding: (documents: AttachDocument[], proceeding: number) => void;
  isMobile?: boolean;
  joinDocuments?: boolean;
  isChecked?: boolean;
};

type DocumentOptions = {
  id: string;
  label: string;
  value: any;
};

export function ListFiles(props: ListFilesProps) {
  const [filteredData, setFilteredData] = useState<OptionType[]>([]);
  const [files, setFiles] = useState<AttachDocument[]>(props.documents);
  const [proceedingData, setProceedingData] = useState<OptionType[]>([]);

  useEffect(() => {
    ProcedureBoxService.getDocumentTypes().then((res) => {
      setFilteredData(orderOptionType(res));
    });

    ProcedureBoxService.getProceedings().then((res) => {
      const proceeding = res.map((option: any) => {
        const obj = { label: `${option.description} - ${option.score}`, value: option.id };
        return obj;
      });
      setProceedingData(proceeding);
    });
  }, []);

  const [documents, setDocuments] = useState<DocumentOptions[]>(
    props.documents.map((document: AttachDocument) => ({ id: document.id, label: '', value: '' })),
  );

  const getValue = (documentId: string) => {
    const optionDocument = documents.find((document: DocumentOptions) => document.id === documentId);

    if (optionDocument) {
      return { label: optionDocument.label, value: optionDocument.value };
    }
    return { label: '', value: '' };
  };

  const changeDocumentTypes = (documentId: string, event: OptionType) => {
    const selectedsIds = files.map((file) => file.id);
    const list = props.documents.map((document: AttachDocument) => {
      if ((selectedsIds.includes(document.id) && selectedsIds.includes(documentId)) || document.id === documentId) {
        return { id: document.id, label: event.label, value: event.value };
      }
      const documentOption = getValue(document.id);
      return { id: document.id, label: documentOption.label, value: documentOption.value };
    });
    setDocuments(list);
  };

  const getDocuments = (document: AttachDocument) => {
    const findDocument = files.find((obj) => obj.id === document.id);
    if (!findDocument) {
      return [document];
    }

    return files;
  };

  const handleDocuments = (event: any, document: AttachDocument) => {
    if (event) {
      props.handleType(getDocuments(document), event.value);
      changeDocumentTypes(document.id, event);
    }
  };

  useEffect(() => {
    ProcedureBoxService.getDocumentTypes().then((res) => {
      setFilteredData(orderOptionType(res));
    });
  }, []);

  useEffect(() => {
    if (props.isChecked) {
      setFiles(props.documents);
    }
  }, [props.documents]);

  useEffect(() => {
    if (props.isChecked) {
      setFiles(props.documents);
    } else {
      setFiles([]);
    }
  }, [props.isChecked]);

  const handleProceeding = (event: any, document: AttachDocument) => {
    if (event) {
      props.handleProceeding(getDocuments(document), event.value);
    }
  };

  return (
    <div className={ styles.container }>
      {
        !props.isMobile ? (
          <>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t('procedureBox.actions.prepareDocument.modal.form.list.fileName')}</th>
                  <th>{t('procedureBox.actions.prepareDocument.modal.form.list.type')}</th>
                  {
                    <th className={styles.proceeding}>
                      {t('procedureBox.actions.prepareDocument.modal.form.list.proceeding.label')}
                      <TooltipLabel
                        title={ t('procedureBox.actions.prepareDocument.modal.form.list.proceeding.tooltip') }
                      >
                        <FaRegQuestionCircle />
                      </TooltipLabel>
                    </th>
                  }
                  { !props.joinDocuments && <th>{t('procedureBox.actions.prepareDocument.modal.form.list.signed')}</th> }
                  <th>{t('procedureBox.actions.prepareDocument.modal.form.list.delete')}</th>
                </tr>
              </thead>
            {
              props.documents.map((document) => (
                <tbody>
                  <td>
                    <FaFileAlt />
                    <a target='_blank'>
                      {document.file.name}
                    </a>
                  </td>
                  <td>
                    <Select
                      label={''}
                      value={getValue(document.id)}
                      options={filteredData}
                      placeholder={t('procedureBox.actions.prepareDocument.modal.form.list.typePlaceholder')}
                      required
                      onChange={(e) => handleDocuments(e, document)}
                      dataCy={'cypress-list-file-select'}
                    />
                  </td>
                  <td>
                    <Select
                      label={''}
                      options={proceedingData}
                      placeholder={t('procedureBox.actions.prepareDocument.modal.form.list.proceeding.placeholder')}
                      onChange={(e) => handleProceeding(e, document)}
                    />
                  </td>
                  { !props.joinDocuments && (
                    <td>
                      { document.sign
                        ? (
                          <>
                            <span className={styles.signed}>
                              {t('procedureBox.actions.prepareDocument.modal.form.sign.signed')}
                            </span>
                            <FaCheckCircle className={styles.check} color='var(--success4)'/>
                          </>
                        )
                        : <span className={styles.notSigned}>
                            {t('procedureBox.actions.prepareDocument.modal.form.sign.notSigned')}
                          </span> }
                    </td>
                  )}
                  <td>
                    <>
                    <CustomTooltip title={t('procedureBox.actions.prepareDocument.modal.form.list.deleteTooltip') || ''}>
                      <a
                        className={styles.buttonDelete}
                        onClick={() => props.removeFile([document])}
                      >
                        <FaTrashAlt />
                      </a>
                    </CustomTooltip>
                    </>
                  </td>
                </tbody>
              ))
            }
            </table>
          </>
        ) : <ResponsiveList documents={props.documents} /> }
    </div>
  );
}
