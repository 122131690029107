import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import Show from '../../../../components/Show';
import styles from './styles.module.scss';
import EmptyResultsDocuments from '../../../../assets/EmptyResultsDocuments.svg';

export function EmptyResults() {
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 700px)').matches,
  );

  useEffect(() => {
    window
      .matchMedia('(max-width: 800px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <div className={styles.noSearch}>
      <Show if={!matches}>
        <img src={EmptyResultsDocuments} alt={t('general.noOptions')} className={styles.img}/>
      </Show>
      <div>
        <p className={styles.labelTitle}>{
          <Trans
            i18nKey='documentSearch.noResults.label'
            components={{ bold: <strong />, br: <br /> }}
          />}
        </p>
        <p className={styles.labelMessage}>
          <a href='#'>
            {<Trans
              i18nKey='documentSearch.noResults.link'
              components={{ bold: <strong /> }}
            />}
          </a>
        </p>
      </div>
    </div>
  );
}
