import { useState, useEffect } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaBars } from 'react-icons/fa';
import ProcedureBoxService from '../../services/procedureBoxService';
import {
  AttachedDocument,
  ResponseAttachedDocument,
} from '../../@types/config';
import { Button } from '../../components/Button';
import logo from '../../assets/logo-agilis-doc-visualizer.png';
import logoPje from '../../assets/logo-pje.png';
import styles from './styles.module.scss';
import { CheckBox } from '../../components/CheckBox';
import { Header } from './Header';
import { EPGM_URL, AGILIS_API_URL } from '../../config/constants';
import { AlertDigitalFolder } from './AlertDigitalFolder';

export function DigitalFolder() {
  const [documents, setDocuments] = useState<AttachedDocument[]>([]);
  const [files, setFiles] = useState<AttachedDocument[]>([]);
  const [procedure, setProcedure] = useState<any>();
  const [citationIntimation, setCitationIntimation] = useState<any>();
  const [url, setUrl] = useState<string>();
  const { id, type } = useParams();
  const i18nKey = 'procedureBox.actions.digitalFolder';
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const openDocument = (item: AttachedDocument, start_page: any) => {
    if (['.docx', '.odt'].some((ext) => item.url.toLowerCase().endsWith(ext))) {
      ProcedureBoxService.convertToPdf(item.file_id, item.file_name, item.url).then((res) => {
        setUrl(`${AGILIS_API_URL}${res}`);
      });
    } else if (procedure && type !== 'pje') {
      const currentUserToken = localStorage.getItem('currentUserToken');
      const fileClass = item.file_class.split('::')[1].replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      const params = `?type=${fileClass}&type_id=${item.file_id}&token=${currentUserToken}&page=${start_page}`;
      const urlTemp = `${EPGM_URL}/processos_digitais/processos/${procedure.id}/paginate_document_agilis/${item.file_name}${params}`;
      setUrl(urlTemp);
    } else {
      setUrl(item.url);
    }

    setSelected(item.file_id);
  };

  const loadProcedure = async () => {
    const response = await ProcedureBoxService.getProcedure(Number(id));
    setProcedure(response);
  };

  const loadCitationIntimation = async () => {
    const ci = await ProcedureBoxService.getCitationIntimation(Number(id));
    setCitationIntimation(ci);
    setDocuments(ci.documents);
  };

  const loadDocuments = () => {
    ProcedureBoxService.getAttachedDocuments(Number(id)).then((res) => {
      const response: ResponseAttachedDocument = res;
      setDocuments(response.archives);
    });
  };

  const loadPjeDocuments = () => {
    ProcedureBoxService.pjeDocuments(Number(id)).then((res) => {
      setDocuments(res);
    });
  };

  useEffect(() => {
    if (type === 'cn') {
      loadCitationIntimation();
    } else if (type === 'pje') {
      loadProcedure();
      loadPjeDocuments();
    } else {
      loadProcedure();
      loadDocuments();
    }
  }, []);

  const selectFile = (file: AttachedDocument) => {
    const archive = files.find((f) => f.file_id === file.file_id);
    if (!archive) {
      setFiles([...files, file]);
    } else {
      const filesNewState = files.filter((f) => f.file_id !== file.file_id);
      setFiles(filesNewState);
    }
  };

  const markAll = () => {
    setFiles(documents);
  };

  const unmarkAll = () => {
    setFiles([]);
  };

  const handleDownload = () => {
    const originalDocumentIds = documents.map((document) => document.file_id);

    const selectedDocuments = originalDocumentIds
      .filter((documentId) => files.some((file) => file.file_id === documentId))
      .map((documentId) => {
        const selectedFile = files.find((file) => file.file_id === documentId);
        if (selectedFile) {
          return `${selectedFile.file_id}|${selectedFile.file_class}`;
        }
        return '';
      });

    const textSend = selectedDocuments.join(', ');

    return textSend;
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const updatedDocuments = Array.from(documents);
    const [movedItem] = updatedDocuments.splice(source.index, 1);
    updatedDocuments.splice(destination.index, 0, movedItem);
    setDocuments(updatedDocuments);
  };

  return (
    <>
      <AlertDigitalFolder
        open={open}
        setOpen={setOpen}
        fileUrls={handleDownload()}
      />

      <div
        className={`${styles.container} ${
          type === 'pje' ? styles.pje : styles.default
        }`}
      >
        <div className={`${styles.menuShow} ${isMenuOpen ? styles.hide : ''}`}>
          <FaBars onClick={toggleMenu} className={styles.barsIcon} />
        </div>
        <div className={`${styles.menu} ${isMenuOpen ? '' : styles.hide}`}>
          <div className={styles.logo}>
            {type === 'pje' ? (
              <div>
                <img src={logoPje} />
                <span>{t(`${i18nKey}.pje`)}</span>
              </div>
            ) : (
              <div>
                <img src={logo} />
                <span>{t(`${i18nKey}.title`)}</span>
              </div>
            )}

            <FaBars onClick={toggleMenu} className={styles.barsIcon} />
          </div>
          <Header
            procedure={procedure}
            citationIntimation={citationIntimation}
          />
          <DragDropContext
            onDragEnd={(e) => {
              handleDragEnd(e);
            }}
          >
            <Droppable droppableId="documentList">
              {(droppableProvided, droppableSnapshot) => {
                let pages = 0;
                return (
                  <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {documents.map((item, index) => {
                      const totalPages = item.total_pages
                        ? item.total_pages
                        : 1;
                      const startPage = pages + 1;
                      pages += totalPages;
                      const endPage = pages;

                      return (
                        <Draggable
                          key={item.file_id}
                          draggableId={item.file_id.toString()}
                          index={index}
                        >
                          {(draggableProvided, draggableSnapshot) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              className={`${styles.item} ${
                                item.file_id === selected
                                  ? styles.itemSelected
                                  : ''
                              }`}
                              onClick={() => openDocument(item, startPage)}
                            >
                              <CheckBox
                                fontLarger
                                value={
                                  !!files.find(
                                    (f) => f.file_id === item.file_id,
                                  )
                                }
                                onClick={() => selectFile(item)}
                              />
                              <div className={styles.itemDetails}>
                                <span>{item?.file_name}</span>
                                {totalPages > 1 ? (
                                  <span className={styles.pagination}>
                                    {t(`${i18nKey}.page`)} {startPage} -{' '}
                                    {endPage}
                                  </span>
                                ) : (
                                  <span className={styles.pagination}>
                                    {t(`${i18nKey}.page`)} {startPage}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {droppableProvided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>

          <div className={`${styles.footer} ${isMenuOpen ? '' : styles.hide}`}>
            <div className={styles.checks}>
              <Button
                buttonType={'primary'}
                size="flat"
                title={t(`${i18nKey}.markAll`)}
                onClick={markAll}
              />

              <Button
                buttonType={'primary'}
                size="flat"
                title={t(`${i18nKey}.unmarkAll`)}
                onClick={unmarkAll}
              />
            </div>
            <Button
              buttonType={'primary'}
              size="flat"
              w100
              title={t(`${i18nKey}.download`)}
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={`doc-viewer ${styles.view}`}>
            <iframe id="doc-visualizer" className={styles.iframe} src={url} />
          </div>
        </div>
      </div>
    </>
  );
}
