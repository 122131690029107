import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ResponsiveContainer } from 'recharts';
import { SelectedProcedures } from '../SelectedProcedures';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { Procedure } from '../../@types/model';
import { Batch } from '../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { CheckBox } from '../CheckBox';
import ProcedureBoxService from '../../services/procedureBoxService';

const LOADING_ANIMATION = require('../../assets/lotties/loading.json');

export function UnattachProcedure() {
  const { proceduresSeleted, setAlert, setModal } = useContext(
    ProcedureActionsContext,
  );
  const { addBatch } = useContext(BackgroundActionsContext);
  const [loading, setLoading] = useState(true);
  const [searchProcedures, setSearchProcedures] = useState<Procedure[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const allSelected = selected.length === searchProcedures.length;
  const hasSelectedItems = selected.length > 0;

  const handleSelected = (id: number) => {
    const newArray = selected.includes(id)
      ? selected.filter((item) => item !== id)
      : [...selected, id];
    setSelected(newArray);
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      const newSelected = searchProcedures
        .map((item) => item.id)
        .filter((id) => !selected.includes(id));
      setSelected([...selected, ...newSelected]);
    }
  };

  const isDependent = () => {
    return (
      Object.keys(proceduresSeleted[0].main_subprocedures_numbers).length > 0
    );
  };

  const tableHeaderKeys: string[] = [
    'procedure',
    'flux',
    'subject',
    'current_responsbile',
    'interested_part',
  ];

  const renderCheckbox = (id: number) => {
    const func = id === 0 ? handleSelectAll : handleSelected;
    const value = id === 0 ? hasSelectedItems : selected.includes(id);
    return (
      <CheckBox
        key={id}
        onClick={() => {
          func(id);
        }}
        value={value}
        color="var(--primary4)"
        partially={id === 0 && hasSelectedItems && !allSelected}
      />
    );
  };

  const handleUnattach = () => {
    const body = {
    };
    const procedures = isDependent() ? proceduresSeleted : searchProcedures.filter((procedure) => selected.includes(procedure.id));
    const type = 'unattachProcedure';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: undefined,
    };

    addBatch(batch);
    setAlert(undefined);
    setModal(undefined);
  };

  const handleText = () => {
    return (
      <Trans
        i18nKey={`procedureBox.actions.unattach_procedure.${isDependent() ? 'dependent' : 'reference'}.alert.subtitle`}
        components={{ br: <br /> }}
        values={
          {
            dependent: isDependent() ? proceduresSeleted[0].process_number : selected.length,
            reference: isDependent() ? proceduresSeleted[0].main_subprocedures_numbers.procedure_number : proceduresSeleted[0].process_number,
          }
        }
      />
    );
  };

  const handleAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: handleUnattach,
      title: t('procedureBox.actions.unattach_procedure.dependent.alert.title'),
      text: handleText(),
    });
  };

  const renderLoading = () => {
    return (
      <div className={styles.icon}>
        <Player
          loop={true}
          autoplay
          keepLastFrame={true}
          src={LOADING_ANIMATION}
          speed={2}
          className={styles.lottie}
        >
          <Controls visible={false} />
        </Player>
      </div>
    );
  };

  const renderTitle = () => {
    const type = isDependent() ? 'dependent' : 'reference';
    return (
      <>
        <span className={styles.subtitle}>
          {t(`procedureBox.actions.unattach_procedure.${type}.title`)}
        </span>
        <span className={styles.description}>
          {t(`procedureBox.actions.unattach_procedure.${type}.subtitle`)}
        </span>
      </>
    );
  };

  const renderTableHeader = () => {
    return (
      <tr>
        {!isDependent() && <td>{renderCheckbox(0)}</td>}
        {tableHeaderKeys.map((headerKey, index) => {
          const fullKey = `procedureBox.actions.attach_procedure.modal.dependent_procedure.table.${headerKey}`;
          return <td key={index}>{t(fullKey)}</td>;
        })}
      </tr>
    );
  };

  const renderTableBody = () => {
    return searchProcedures.map((procedure) => {
      return (
        <tr>
          {!isDependent() && <td>{renderCheckbox(procedure.id)}</td>}
          <td>
            <Link
              target="_blank"
              className={styles.link}
              to={`/procedure_box/show_procedure/${procedure.id}`}
            >
              {procedure.process_number}
            </Link>
          </td>
          <td>{procedure.flux.name}</td>
          <td>{procedure.subject}</td>
          <td>{procedure.individual_name}</td>
          <td>{procedure.interested_parts}</td>
        </tr>
      );
    });
  };

  const loadData = async () => {
    setLoading(true);
    if (isDependent()) {
      const response = await ProcedureBoxService.getProcedure(
        proceduresSeleted[0].main_subprocedures_numbers.procedure_id,
      );
      setSearchProcedures([response]);
      setLoading(false);
    } else if (proceduresSeleted[0].attached_procedures) {
      Promise.all(
        proceduresSeleted[0].attached_procedures.map(
          async (attachedProcedure) => {
            const procedure = await ProcedureBoxService.getProcedure(
              attachedProcedure.procedure_id,
            );
            return procedure;
          },
        ),
      ).then((arr) => {
        setLoading(false);
        setSearchProcedures(arr);
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={styles.container}>
      <SelectedProcedures />
      <div className={styles.info}>{renderTitle()}</div>
      <div className={styles.dependentProcedure}>
        {loading ? (
          renderLoading()
        ) : (
          <ResponsiveContainer width={'99%'} minWidth={700} minHeight={100}>
            <table>
              <thead>{renderTableHeader()}</thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </ResponsiveContainer>
        )}
      </div>
      <div className={styles.button}>
        <Button
          buttonType={'primary'}
          round
          disabled={isDependent() ? false : selected.length === 0}
          size="flat"
          title={t('procedureBox.actions.unattach_procedure.button')}
          onClick={handleAlert}
        />
      </div>
    </div>
  );
}
