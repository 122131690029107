import { useContext, useState } from 'react';
import { t } from 'i18next';
import Menu from '@mui/material/Menu';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './styles.module.scss';
import { QueryContext } from '../../../../context/queryContext';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { CheckBox } from '../../../CheckBox';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import { isProcedureCollection } from '../../../../helpers/routeTranslate';
import Show from '../../../Show';
import CustomTooltip from '../../../CustomTooltip';

export type ItemType = {
  id: string;
};

const itens: ItemType[] = [
  {
    id: 'allInBox',
  },
  {
    id: 'allInPage',
  },
  {
    id: 'none',
  },
  {
    id: 'read',
  },
  {
    id: 'unread',
  },
];

export default function SelectBoxMenu() {
  const {
    addProcedures, removeAllProcedures, isEmpty, proceduresSeleted,
    setAlert,
  } = useContext(ProcedureActionsContext);
  const {
    procedures, totalProcedures, box, perPage, queryFluxes,
  } = useContext(QueryContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const proceduresVisible = procedures.filter((procedure) => procedure.visible).length > 0;
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProceduresReadOrUnread = (read :boolean) => {
    return procedures.filter((item) => item.was_seen === read && item.visible);
  };

  const selectAllProcedures = () => {
    if (proceduresSeleted.length === 0) {
      addProcedures(procedures.filter((procedure) => procedure.visible));
    }
  };

  const removeProcedures = () => {
    removeAllProcedures();
  };

  const showAlert = () => {
    const currentBox = box || 'inbox';
    setAlert({
      visible: true,
      title: t(
        'procedureBox.selectionBoxMenu.message',
        { box: t(`procedureBox.boxesSelect.${currentBox}`) },
      ),
      text: t('procedureBox.selectionBoxMenu.text'),
      type: 'loading',
    });
  };

  const handleSelectAllProcedures = async () => {
    if (totalProcedures <= Number(perPage)) {
      if (queryFluxes !== undefined && queryFluxes?.length > 0) {
        const filteredProcedures = procedures.filter((procedure) => {
          return queryFluxes.some((qf) => qf.flux_id === procedure.flux.id) && procedure.visible;
        });
        addProcedures(filteredProcedures);
      } else {
        addProcedures(procedures.filter((procedure) => procedure.visible));
      }
      addProcedures(procedures.filter((procedure) => procedure.visible));
      handleClose();
    } else {
      let query = `&page=1&per_page=${totalProcedures}`;
      queryFluxes?.map((flux) => {
        const prefix = 'flux_id_in';
        query = query.concat(`&q[${prefix}]=${flux.flux_id}`);
      });
      handleClose();
      showAlert();
      if (isProcedureCollection(window.location.pathname)) {
        const newQuery = sessionStorage.getItem('query');
        const data = await ProcedureBoxService.getProcedureCollectionList(`${newQuery || ''}${query}`);
        addProcedures(data.procedures.filter((procedure) => procedure.visible));
      } else {
        const response = await ProcedureBoxService.getProcedureList(box, query);
        addProcedures(response.procedures.filter((procedure) => procedure.visible));
      }
      setAlert(undefined);
    }
  };

  const handleChange = (item: ItemType) => {
    switch (item.id) {
      case 'allInBox':
        handleSelectAllProcedures();
        break;
      case 'none':
        removeAllProcedures();
        break;
      case 'read':
        addProcedures(getProceduresReadOrUnread(true));
        break;
      case 'unread':
        addProcedures(getProceduresReadOrUnread(false));
        break;
      case 'allInPage':
        selectAllProcedures();
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${styles.selectionBoxContainer}`}>
      <Show if={box !== 'citationIntimation'}>
        <div
          className={`${styles.itemContainer}`}
        >
          <CustomTooltip
            title={proceduresVisible ? t('procedureBox.selectionBoxMenu.title').toString() : ''}
          >
            <div
              className={styles.checkboxContent}
              onClick={proceduresVisible ? (() => (proceduresSeleted.length === 0 ? selectAllProcedures() : removeProcedures())) : () => {}}>
              <CheckBox
                dataCy={'procedure-box-checkbox'}
                value={ !isEmpty }
                uncheckedColor='var(--neutral6)'
                color='var(--primary4)'
                isMarker={false}
                partially={ proceduresSeleted.length !== procedures.length}
                disabled={!proceduresVisible}
              />
            </div>
          </CustomTooltip>
          <CustomTooltip
            title={proceduresVisible ? t('procedureBox.selectionBoxMenu.title').toString() : ''}
          >
            <div
              data-cy={'procedure-box-checkbox-list'}
              className={`${styles.menuContent} ${open ? styles.itemContainerOpen : ''}`}
              onClick={proceduresVisible ? (open ? handleClose : handleClick) : () => {}}
            >
              { open ? <FaChevronUp className={ styles.chevron } /> : <FaChevronDown className={ styles.chevron } /> }
            </div>
          </CustomTooltip>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                backgroundColor: 'var(--neutral0);',
              },
            }}
          >
            {itens.map((i) => (
              <div
                className={styles.menuItem}
                onClick={() => handleChange(i)}
                data-cy={i.id}
              >
                <p>{t(`procedureBox.selectionBoxMenu.${i.id}`)}</p>
              </div>
            ))}
          </Menu>
        </div>
      </Show>
    </div>
  );
}
