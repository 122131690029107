import { Grid } from '@mui/material';
import { t } from 'i18next';
import Select from '../../../../../components/Select';
import { ItemsResponse } from '../../../../../@types/searchDocument';
import Show from '../../../../../components/Show';

type SelectFiltersProps = {
  items: ItemsResponse | undefined;
  handleSelected: (key: string, value: string | undefined) => void;
};

export function SelectFilters(props: SelectFiltersProps) {
  const MAIN_FIELD_ENABLED = false;

  const arrayToHashList = (array: string[] | undefined) => {
    if (!array) {
      return [];
    }

    return array.map((item) => {
      if (item === '') {
        return { label: t('documentSearch.searchBar.filters.document_type.no_type'), value: '' };
      }
      return { label: item, value: item };
    });
  };

  return (
    <div>
      <Grid
        container
        columns={{ xs: 1, sm: 12, md: 12 }}
        spacing={{ xs: 2, md: 2 }}
      >
        <Show if={MAIN_FIELD_ENABLED}>
          <Grid item xs={1} sm={4} md={4}>
            <Select
              placeholder={t('documentSearch.searchBar.filters.main_field.placeholder')}
              onChange={(value) => { props.handleSelected('main_field', value?.value); } }
              label={t('documentSearch.searchBar.filters.main_field.label')}
              tooltip={t('documentSearch.searchBar.filters.main_field.tooltip')}
              options={arrayToHashList(props.items?.unique_main_field?.filter((item: string) => item !== 'Gabinete do Procurador Geral')) || []} />
          </Grid>
        </Show>
        <Grid item xs={1} sm={4} md={4}>
          <Select
            onChange={(value) => { props.handleSelected('subject', value?.value); } }
            placeholder={t('documentSearch.searchBar.filters.subject.placeholder')}
            label={t('documentSearch.searchBar.filters.subject.label')}
            tooltip={t('documentSearch.searchBar.filters.subject.tooltip')}
            options={arrayToHashList(props.items?.unique_subjects) || []} />
        </Grid>
        <Grid item xs={1} sm={4} md={4}>
          <Select
            onChange={(value) => { props.handleSelected('flux_name', value?.value); } }
            placeholder={t('documentSearch.searchBar.filters.flux_name.placeholder')}
            label={t('documentSearch.searchBar.filters.flux_name.label')}
            tooltip={t('documentSearch.searchBar.filters.flux_name.tooltip')}
            options={arrayToHashList(props.items?.unique_flux_names) || []} />
        </Grid>
        <Grid item xs={1} sm={4} md={4}>
          <Select
            onChange={(value) => { props.handleSelected('documents.keywords', value?.value); } }
            placeholder={t('documentSearch.searchBar.filters.document_type.placeholder')}
            label={t('documentSearch.searchBar.filters.document_type.label')}
            tooltip={t('documentSearch.searchBar.filters.document_type.tooltip')}
            options={arrayToHashList(props.items?.unique_keywords) || []} />
        </Grid>
      </Grid>
    </div>
  );
}
