import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaAngleDoubleLeft, FaAngleDoubleRight, FaChevronLeft, FaChevronRight,
} from 'react-icons/fa';
import { QueryContext } from '../../context/queryContext';
import { getPaginationTitle } from '../../helpers/strings';
import { ItemType, MenuSelect } from '../MenuSelect';
import { RoundButton } from '../RoundButton';
import styles from './styles.module.scss';
import { Tooltip } from '../Tooltip';
import Show from '../Show';

type PaginationMenuProps = {
  handlePagination?: (action: string, value: number) => void;
  onPerPageChange?: (value: string) => void;
  pageNumber?: number;
  itemsPerPage?: number;
  totalItems?: number;
  totalPages?: number;
  isDistributeProcess?: boolean;
};

export function PaginationMenu(props: PaginationMenuProps) {
  const {
    setPage, setPerPage, totalProcedures, totalPages, currentPage, perPage,
  } = useContext(QueryContext);

  const { t } = useTranslation();
  const itens: ItemType[] = [
    {
      id: '1',
      query: '20',
      translated: `${t('procedureBox.paginationMenu.twenty')}`,
      selected: perPage === '20',
    },
    {
      id: '2',
      query: '30',
      translated: `${t('procedureBox.paginationMenu.thirty')}`,
      selected: perPage === '30',
    },
    {
      id: '3',
      query: '50',
      translated: `${t('procedureBox.paginationMenu.fifty')}`,
      selected: perPage === '50',
    },
  ];

  const itemSelected = itens.find((i) => i.selected);

  const handlePage = (event: string) => {
    const { isDistributeProcess, pageNumber, totalItems } = props;
    switch (event) {
      case 'prevPage':
        if (
          isDistributeProcess
          && pageNumber !== undefined
          && pageNumber > 1
          && totalItems
          && totalItems > 1
          && props.handlePagination) {
          props.handlePagination('prevPage', pageNumber - 1);
        } else if (currentPage > 1 && totalPages > 1) {
          setPage(`${currentPage - 1}`);
        }
        break;
      case 'nextPage':
        if (
          isDistributeProcess
          && pageNumber !== undefined
          && pageNumber < (totalItems || 0)
          && totalItems !== undefined
          && props.handlePagination) {
          props.handlePagination('nextPage', pageNumber + 1);
        } else if (currentPage < totalPages) {
          setPage(`${currentPage + 1}`);
        }
        break;
      case 'firstPage':
        if (isDistributeProcess && props.handlePagination) {
          props.handlePagination('prevPage', 1);
        } else {
          setPage('1');
        }
        break;
      case 'lastPage':
        if (
          isDistributeProcess
          && pageNumber !== undefined
          && pageNumber < (totalItems || 0)
          && totalItems !== undefined
          && props.totalPages !== undefined
          && props.handlePagination
        ) {
          props.handlePagination('nextPage', props.totalPages);
        } else if (currentPage < totalPages) {
          setPage(`${totalPages}`);
        }
        break;
      default:
        break;
    }
  };

  const handlePerPage = (query: string) => {
    setPerPage(query);
  };

  return (
    <div className={`${styles.paginationMenuContainer}`}>
      <MenuSelect
        itens={itens}
        onChange={(item) => (props.isDistributeProcess ? props.onPerPageChange && props.onPerPageChange(item.query) : handlePerPage(item.query))}
        chevron={false}
        title={
          props.isDistributeProcess
            ? getPaginationTitle(props.pageNumber ?? 0, Number(props.itemsPerPage), props.totalItems ?? 0)
            : getPaginationTitle(currentPage, Number(itemSelected ? itemSelected?.query : 20), totalProcedures)
        }
        style={`${styles.paginationItemContainer}`}
      />
      <Show if={currentPage > 1 || (props.pageNumber !== undefined && props.pageNumber > 1)}>
        <Tooltip
          title={t('procedureBox.paginationMenu.prevFirstPage')}
          className={styles.chevron}
          children={
            <RoundButton
              className={ styles.button }
              icon={<FaAngleDoubleLeft/>}
              size='medium'
              onClick={() => handlePage('firstPage')}
            />
          }
        />
      </Show>
      <Tooltip
        title={t('procedureBox.paginationMenu.prevPage')}
        className={styles.chevron}
        children={
          <RoundButton
            className={ styles.button }
            icon={<FaChevronLeft/>}
            size='small'
            onClick={() => handlePage('prevPage')}
          />
        }
      />
      <Tooltip
        title={t('procedureBox.paginationMenu.nextPage')}
        className={styles.chevron}
        children={
          <RoundButton
            className={ styles.button }
            icon={<FaChevronRight/>}
            size='small'
            onClick={() => handlePage('nextPage')}
          />
        }
      />
      {
        currentPage !== totalPages && (
          <Tooltip
            title={t('procedureBox.paginationMenu.nextLastPage')}
            className={styles.chevron}
            children={
              <RoundButton
                className={ styles.button }
                icon={<FaAngleDoubleRight/>}
                size='small'
                onClick={() => handlePage('lastPage')}
              />
            }
          />
        )
      }
    </div>
  );
}
