import { useEffect, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { FaTimes } from 'react-icons/fa';
import {
  CssBaseline,
  ThemeProvider,
  createFilterOptions,
  AutocompleteGetTagProps,
  Autocomplete,
  TextField,
  Checkbox,
} from '@mui/material/';
import styles from './styles.module.scss';
import { globalTheme } from '../../styles/globalTheme';
import DashboardService from '../../services/dashboardService';
import CustomTooltip from '../CustomTooltip';
import { OptionType } from '../../@types/config';
import { Label } from '../Label';
import Show from '../Show';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

type SelectFilterProps = {
  id?: string;
  placeholder: string;
  label?: string;
  tooltip?: string;
  handleSelectedItems?: (itens: number[]) => void;
};

function Tag(props: TagProps) {
  const { label, onDelete } = props;
  return (
    <div className={styles.tag}>
      <span>{label}</span>
      <FaTimes onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>();

export function AutoCompleteFilter(props: SelectFilterProps) {
  const { t } = useTranslation();
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [searchBy, setSearchBy] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [checkSelected, setCheckSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const filter = createFilterOptions<OptionType>();
  const noOptions = quantity === 0;

  const handleToggleOption = (selectedOptionsTemp :OptionType[]) => {
    setSelectedOptions(selectedOptionsTemp);
  };

  const handleChange = (event :any, selectedOptionsTemp :OptionType[], reason :string, details :any) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (checkSelected) {
        let result = [];
        result = searchedOptions.filter((el :any) => el.value !== details.option.value);
        handleToggleOption(result);
      } else {
        handleToggleOption(selectedOptionsTemp);
      }
      setCheckSelected(false);
    }
    if (reason === 'clear') {
      setSelectedOptions([]);
    }
  };

  const optionRenderer = (others :any, option :OptionType, { selected } :any) => {
    return (
      <>
        <li {...others} >
          <Checkbox
            data-cy={'cypress-checkbox-auto-complete-filter'}
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            style={{
              padding: '2px',
              marginRight: '8px',
            }}
            checked={checkSelected ? true : selected}
          />
          <span className={styles.option}>{option.label}</span>
        </li>
        {
          noOptions
            ? <li className={styles.noOptions}>
                {t('general.noOptions')}
              </li>
            : null
        }
      </>
    );
  };

  const tagRenderer = (tagValue: OptionType[], getTagProps: any) => {
    const tags = tagValue.map((option :OptionType, index :number) => (
      <CustomTooltip title={ option.label }>
        <div>
          <StyledTag
            label={option.label}
            {...getTagProps({ index })}
          />
        </div>
      </CustomTooltip>
    ));
    return tags;
  };

  const handleSelected = () => {
    if (props.handleSelectedItems) {
      props.handleSelectedItems(selectedOptions.map((a) => a.value));
    }
  };

  useEffect(() => {
    if (searchBy.length > 3) {
      DashboardService.getCdas(searchBy).then((resp) => {
        setSearchedOptions(resp);
      });
    } else if (searchedOptions.length > 0) {
      setSearchedOptions([]);
    }
  }, [searchBy]);

  return (
    <div className={styles.container}>
      <div className={ styles.info }>
        <Show if={!!props.label}>
          <Label text={props.label || ''}/>
        </Show>
      </div>
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <Autocomplete
          popupIcon={<></>}
          isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
          limitTags={1}
          multiple
          options={searchedOptions}
          value={selectedOptions}
          disableCloseOnSelect
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            setQuantity(filtered.length);
            return [...filtered];
          }}
          classes={{
            input: styles.input,
            inputRoot: styles.inputRoot,
          }}
          noOptionsText={t('general.noOptions')}
          onChange={handleChange}
          onBlur={() => handleSelected()}
          getOptionLabel={(option) => option.label}
          renderOption={optionRenderer}
          renderTags={tagRenderer}
          renderInput={(params) => {
            const option: any = params.inputProps;
            setSearchBy(option.value);
            return (
              <TextField
                {...params}
                data-cy={'cypress-text-field-auto-complete-filter'}
                placeholder={props.placeholder || ''}
              />
            );
          }}
        />
      </ThemeProvider>
    </div>
  );
}
