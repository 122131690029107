import { t } from 'i18next';
import { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Tooltip } from '../../Tooltip';
import ProcedureBoxService from '../../../services/procedureBoxService';
import CustomSwitch from '../../CustomSwitch';

type SwitchNotesProps = {
  noteId?: number;
  value: boolean;
  setValue: (e: boolean) => void;
};

export function SwitchNotes({ noteId, value, setValue }: SwitchNotesProps) {
  const [checked, setChecked] = useState(value);
  const renderLabel = () => {
    switch (checked) {
      case true:
        return t('procedureBox.actions.notes.modal.exibition.public');
      case false:
        return t('procedureBox.actions.notes.modal.exibition.private');
      default:
        return '';
    }
  };

  const handleUpdate = (e: any) => {
    setValue(!e);
    setChecked(e);
    if (noteId) {
      ProcedureBoxService.putVisibility(noteId, !e);
    }
  };

  return <div className={ styles.container }>
    <CustomSwitch
      className={styles.switch}
      onClick={(e) => { handleUpdate(e.currentTarget.value); }}
      onChange={(e) => { handleUpdate(e); }}
      checked={checked}
    />
    <span className={styles.label}>{renderLabel()}</span>
    <Tooltip title={ t('procedureBox.actions.notes.modal.exibition.tooltip') }>
      <FaRegQuestionCircle className={styles.tooltip} />
    </Tooltip>
  </div>;
}
