import {
  TimelineOppositeContent,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import {
  FaGrinWink, FaLock, FaStickyNote, FaTimes,
} from 'react-icons/fa';
import { useState } from 'react';
import moment from 'moment';
import { t } from 'i18next';
import {
  Box,
  Popper,
  Snackbar, SnackbarContent,
} from '@mui/material';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { Note } from '../../../../@types/model';
import { CurrentUser } from '../../../../@types/currentUser';
import { SwitchNotes } from '../../SwitchNotes';
import { MoreVerticalMenu } from '../../../MoreVerticalMenu';
import { TextArea } from '../../../TextArea';
import { Button } from '../../../Button';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import Show from '../../../Show';
import { SelectedProcedures } from '../../../SelectedProcedures';
import { QueryContext } from '../../../../context/queryContext';

type ItemProps = {
  note: Note;
  procedure_id: number;
};

export function Item({ note, procedure_id }: ItemProps) {
  const { pathname } = useLocation();
  const { reloadCurrentProcedure } = React.useContext(QueryContext);
  const [hide, setHide] = useState(note.private);
  const [edit, setEdit] = useState(false);
  const [bulk, setBulk] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState(note.note);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const deleteNote = () => {
    if (open) {
      ProcedureBoxService.deleteNote(note.id, procedure_id, bulk);
      setOpen(false);
      reloadCurrentProcedure(pathname);
    }
  };

  const currentUserString = localStorage.getItem('currentUser');
  const currentUser: CurrentUser = JSON.parse(currentUserString!);

  const renderIcon = () => {
    if (hide) {
      return <FaLock size={20} color='var(--neutral4)' className={styles.icon} />;
    }
    return <FaStickyNote size={20} color='var(--neutral0)' className={styles.icon} />;
  };

  const getDotColor = () => {
    if (hide) {
      return styles.grey;
    }
    return styles.primary;
  };

  const renderBulkProcedures = () => {
    if (note.procedures) {
      return (
        <div className={styles.procedureBulk}>
          <p onClick={(e) => { setAnchorEl(e.currentTarget); }}>
            {t('procedureBox.actions.notes.modal.bulk', { number: note.procedures.length })}
          </p>
        </div>
      );
    }
    return <></>;
  };

  const getTitle = () => {
    if (note.individual_id === currentUser.id) {
      return <p className={styles.title}><strong>Eu</strong> ({currentUser?.name})</p>;
    }
    return <strong className={styles.title}>{note.individual_name}</strong>;
  };

  const getNoteCreatedAt = () => {
    return <span className={styles.title}>{moment(note.created_at).format('DD [de] MMMM [de] YYYY, HH:mm')}</span>;
  };

  const getSubTitle = () => {
    if (edit) {
      return (
        <TextArea
          className={styles.textArea}
          onChange={(e) => { setDescription(e.currentTarget.value); }}
          value={description}
          placeholder={t('procedureBox.actions.notes.modal.observation.placeholder')} />);
    }
    return <span className={styles.subTitle}>{description}</span>;
  };

  const renderButtons = () => {
    if (edit) {
      return (
        <>
          <Button
            title={t('procedureBox.actions.notes.modal.buttons.cancel')}
            textCenter
            round
            buttonType='default'
            size='flat'
            onClick={() => { setEdit(false); setDescription(note.note); }}
          />
          <Button
            title={t('procedureBox.actions.notes.modal.buttons.save')}
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={!description}
            onClick={() => { setEdit(false); ProcedureBoxService.updateNote(note.id, description, bulk, procedure_id); }}
          />
        </>
      );
    }
    return <></>;
  };

  const action = (handleOpen: any, handleUndo: any) => {
    return (
      <div className={styles.snack}>
        <span>
          {t('procedureBox.actions.notes.modal.snack.deleted')}
        </span>
        <span className={styles.undo} onClick={handleUndo}>
          {t('procedureBox.actions.notes.modal.snack.undo')}
        </span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => { handleOpen(false); }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const getMenu = () => {
    if (note.individual_id === currentUser?.id) {
      return (
      <MoreVerticalMenu
        menuItems={[
          {
            text: t('procedureBox.actions.notes.modal.options.edit'),
            onClick: () => { setEdit(true); setBulk(false); },
          },
          {
            text: t('procedureBox.actions.notes.modal.options.edit_in_bulk'),
            onClick: () => { setEdit(true); setBulk(true); },
            hidden: !(note.procedures?.length > 1),
          },
          {
            text: t('procedureBox.actions.notes.modal.options.delete'),
            onClick: () => { setDeleted(true); setBulk(false); setOpen(true); },
          },
          {
            text: t('procedureBox.actions.notes.modal.options.delete_in_bulk'),
            onClick: () => { setDeleted(true); setBulk(true); setOpen(true); },
            hidden: !(note.procedures?.length > 1),
          },
        ]}
      />);
    }
    return <></>;
  };

  if (currentUser?.id === note.individual_id || !note.private) {
    return (
      <div className={styles.container}>
        <Popper open={!!anchorEl} anchorEl={anchorEl} className={styles.popper} disablePortal={true}
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: anchorEl,
              },
            },
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: false,
                rootBoundary: 'viewport',
                padding: 8,
              },
            },
          ]}>
          <Box className={styles.box}>
            <div className={styles.close} onClick={() => setAnchorEl(null)}>
              <FaTimes />
            </div>
            <SelectedProcedures procedures={note.procedures} />
          </Box>
        </Popper>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={() => { deleteNote(); }}
          >
        <SnackbarContent
          className={ styles.snackContent }
          message={<FaGrinWink size={'16px'} color={'var(--neutral4)'} />}
          action={action(deleteNote, () => { setOpen(false); setDeleted(false); })}
        />
      </Snackbar>
        <Show if={!deleted}>
          <TimelineItem className={styles.item}>
            <TimelineOppositeContent style={{ maxWidth: '1px', paddingLeft: '0px', paddingRight: '0px' }} />
            <TimelineSeparator>
              <TimelineDot classes={{ filled: `${getDotColor()}` }}>
                {renderIcon()}
              </TimelineDot>
              <TimelineConnector className={styles.connector} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <div className={styles.header}>
                <div className={styles.name}>
                  {getTitle()}
                  {renderBulkProcedures()}
                </div>
                <div className={styles.options}>
                  {getNoteCreatedAt()}
                  {getMenu()}
                </div>
              </div>
              <div>
                {getSubTitle()}
              </div>
              <div className={styles.switch}>
                { note.individual_id === currentUser?.id && <SwitchNotes noteId={note.id} value={!hide} setValue={(e) => { setHide(e); }} /> }
                  <div className={styles.buttons}>
                  {renderButtons()}
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Show>
      </div>
    );
  }
  return <></>;
}
