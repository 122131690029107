import { FaRegQuestionCircle } from 'react-icons/fa';
import { Tooltip } from '../../Tooltip';
import styles from './styles.module.scss';

type TabsProps = {
  tabs: {
    key: string;
    label: string;
    onClick: () => void;
    tooltip?: string;
  }[];
  selected: string;
};

export function Tabs(props: TabsProps) {
  return (
    <div className={ styles.container }>
      {
        props.tabs.map((tab) => (
          <a
            onClick={tab.onClick}
            className={`${tab.key === props.selected && styles.selected}`}
            data-cy={`cypress-tab-${tab.key}`}
          >
            {tab.label}
            <div className={ styles.info }>
              {tab.tooltip
                ? <Tooltip title={ tab.tooltip }><FaRegQuestionCircle /></Tooltip>
                : null
              }
            </div>
          </a>
        ))
      }
    </div>
  );
}
