import { t } from 'i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcumb } from '../../../../components/Breadcumb';
import styles from './styles.module.scss';
import { loadTitle } from '../../../../helpers/changeTitle';

export function IFrameView() {
  const { pathname } = useLocation();

  useEffect(() => {
    loadTitle(t('sidebar.modules.active_debt.debt_box'));
  }, []);

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        <h1>{t('sidebar.modules.active_debt.debt_box')}</h1>
        <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
      </div>
    </div>
  );
}
