import { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import styles from './styles.module.scss';
import Show from '../Show';

export type ItemType = {
  id: string;
  query: string;
  translated: string;
  selected: boolean;
};

type MenuSelectProps = {
  itens: ItemType[];
  onChange: (item: ItemType) => void;
  chevron: boolean;
  title?: string;
  style?: any;
  box?: string;
};

export function MenuSelect(props: MenuSelectProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<ItemType>(props.itens[0]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (item: ItemType) => {
    setSelected(item);
    handleClose();
    props.onChange(item);
  };

  useEffect(() => {
    setSelected(props.itens[0]);
  }, [props.box]);

  return (
    <div
      className={`${props.style ? props.style : styles.sortItemContainer} ${open ? styles.sortItemContainerOpen : ''}`}
      onClick={open ? handleClose : handleClick}
    >
      <span data-cy={'filterSelection'} className={`${styles.selectedItem}`}>{props.title ? props.title : selected?.translated}</span>
      <Show if={props.chevron}>
        { open ? <FaChevronUp className={ styles.chevron } /> : <FaChevronDown className={ styles.chevron } /> }
      </Show>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--neutral0);',
          },
        }}
      >
        {props.itens.map((i) => (
          <div
            data-cy={'filterItem'}
            className={`${i.id === selected?.id ? styles.searchItemOptionSelected : styles.searchItemOption}`}
            onClick={i.id === selected?.id ? () => setAnchorEl(null) : () => handleChange(i)}
          >
            <p data-cy={i.id}>{i.translated}</p>
          </div>
        ))}
      </Menu>
    </div>
  );
}
