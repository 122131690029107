import { useState } from 'react';
import { Collapse } from '@mui/material';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import { ActionItems } from '../../../../@types/activeDebt/cdaActions';

type DropdownProps = {
  items: ActionItems[];
};

export default function Dropdown(props: DropdownProps) {
  const [open, setOpen] = useState(false);

  const renderOption = (item: ActionItems) => {
    return (
      <div
        onClick={item.disabled ? () => {} : item.onClick}
        className={`${styles.item} ${item.disabled ? styles.disabledItem : ''}`}
        data-cy={item.dataCy}
      >
        <span className={styles.title}>{item.label}</span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Button
        className={open ? styles.opened : ''}
        dataCy='cypress-dropdown-actions'
        onClick={() => setOpen(!open)}
        title={t('procedureBox.processExtract.cdas.dropdown')}
        buttonType='primary' round size='small'
        icon={open ? <FaChevronUp size={11}/> : <FaChevronDown size={11}/>}
        disabled={props.items.filter((item: ActionItems) => !item.disabled).length === 0}
      />
      <Collapse
        className={styles.items}
        in={open}
      >
        {props.items.map((item: ActionItems) => renderOption(item))}
      </Collapse>
    </div>
  );
}
