import { useState } from 'react';
import { t } from 'i18next';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { RequestData } from '../../../@types/requestCalculation';
import { Button } from '../../Button';
import styles from './styles.module.scss';
import CustomTooltip from '../../CustomTooltip';
import CustomSwitch from '../../CustomSwitch';

type OrderDataProps = {
  content?: RequestData;
  nextStep: (val: any) => void;
  handleForm: (val: any) => void;
};

export function OrderData(props: OrderDataProps) {
  const [generateNumber, setGenerateNumber] = useState(Boolean(props.content?.generateNumber));

  const handleNextStep = () => {
    const data: RequestData = {
      generateNumber,
    };
    props.handleForm(data);
    props.nextStep(data);
  };

  return (
    <div className={styles.container}>
        <div className={styles.generateNumber}>
          <CustomSwitch
            onClick={() => setGenerateNumber(!generateNumber)}
            checked={generateNumber}
            onChange={() => setGenerateNumber(!generateNumber)}
          />
          <CustomTooltip placement='left-end' title={t('procedureBox.actions.eletronicCalculation.modal.tooltips.generateProcessNumberTooltip') || ''}>
            <p>{t('procedureBox.actions.eletronicCalculation.modal.orderDataContent.processNumber.generateProcessNumber')} <FaRegQuestionCircle className={styles.icon} /></p>
          </CustomTooltip>
        </div>
      <footer>
        <Button
          dataCy={'cypress-button-next-step'}
          title={t('procedureBox.actions.eletronicCalculation.modal.orderDataContent.buttonText')}
          onClick={() => handleNextStep()}
          size='flat'
          buttonType='primary'
          round
        />
      </footer>
    </div>
  );
}
