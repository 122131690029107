import { createTheme } from '@mui/material/';

export const customTheme = (createTheme as any)({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '4px 9px',

          '&.Mui-checked': {
            color: 'var(--primary4)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'var(--neutral4)',
          },
        },
      },
    },
  },
});
