import { Tramit } from '../@types/backgroundActions';
import { Boxes } from '../@types/boxes';
import {
  DocumentTemplatesResponse,
  CheckDocumentSignedRequest,
  OrderFieldsRequest,
  ResponseDocuments,
  ResponseProcedure,
  AttachedDocument,
  AttachedDocumentResquet,
  FinishSignerProgressRequest,
  GetVariablesToSignRequest,
  Description,
  ResponseAttachedDocument,
  ResponseDocumentsInPreparation,
  SamePartData,
  CustomizeColumnsRequest,
} from '../@types/config';
import { DocumentMethodResponse } from '../@types/createDocument';
import { Individual, Note } from '../@types/model';
import { FluxTab, MarkerTab } from '../@types/tab';
import Axios from '../config/api';
import { AdvancedFilters } from '../@types/advancedFilters';
import { ConsultProcedure } from '../@types/pje';
import { ResponseResendRelease } from '../@types/resendRelease';

const qs = require('qs');

export default class ProcedureBoxService {
  public static async getProcedureList(box?: string, query?: string) {
    return Axios.get<ResponseProcedure>(`api/procedures?box=${box || 'inbox'}${query}`)
      .then((res) => res.data);
  }

  public static async updateOrderFields(orderFields: OrderFieldsRequest) {
    return Axios.put('api/procedures/order_fields', orderFields)
      .then((res) => res.data);
  }

  public static async postCustomizeFields(orderFields: CustomizeColumnsRequest) {
    return Axios.post('api/procedures/customize_columns', orderFields)
      .then((res) => res.data);
  }

  public static async updateTabSelected(orderTab?: Number) {
    return Axios.put('api/procedures/order_tab', { order_tab: orderTab })
      .then((res) => res.data);
  }

  public static async getTabSelected() {
    return Axios.get('api/procedures/order_tab')
      .then((res) => res.data);
  }

  public static async getProcedureAdvancedFilters() {
    return Axios.get<AdvancedFilters>('/api/procedures/advanced_filters')
      .then((res) => res);
  }

  public static async removeMarkersProcedure(markerId: number, procedureIds: number[]) {
    return Axios.delete(`/api/markers/${markerId}/linked_marker_procedures`, { params: { procedure_ids: procedureIds } });
  }

  public static async getProcedureBoxesInfo() {
    return Axios.get<Boxes>('/api/procedures/box_counters')
      .then((res) => res.data);
  }

  public static async getFluxTabList(box?: string, query?: string) {
    return Axios.get<FluxTab[]>(`api/procedures/flux/counters?box=${box || 'inbox'}${query}`)
      .then((res) => res.data);
  }

  public static async getFluxes() {
    return Axios.get('/api/procedures/all_fluxes')
      .then((res) => res.data);
  }

  public static async getMarkerTabList(box?: string, query?: string) {
    return Axios.get<MarkerTab>(`api/procedures/marker/counters?box=${box || 'inbox'}${query}`)
      .then((res) => res.data);
  }

  public static async getFluxCollection(query?: string) {
    return Axios.get<FluxTab[]>(`api/procedures/flux/search/counters${query}`)
      .then((res) => res.data);
  }

  public static async getMarkerCollection(query?: string) {
    return Axios.get<MarkerTab>(`api/procedures/marker/search/counters${query}`)
      .then((res) => res.data);
  }

  public static async getReasonsArchiveProcedure() {
    return Axios.get('api/procedures/reasons')
      .then((res) => res.data);
  }

  public static async archiveProcedures(id: number, reason: number, description: string) {
    return Axios.post('api/procedures/archive', { id, reason, description })
      .then((res) => res.data);
  }

  public static async deleteProcedure(id: number) {
    return Axios.delete(`api/procedures/${id}`)
      .then((res) => res.data);
  }

  public static async forceTramit(id: number, observation: string) {
    return Axios.post('/api/activity/force_tramit_for_me', { id, observation })
      .then((res) => res.data);
  }

  public static async markAsInstructionFinished(tramitId: number, finished: boolean) {
    return Axios.put(`/api/activity/${tramitId}/finished_tramit`, { finished });
  }

  public static async getTramitSuggestions() {
    return Axios.get<Individual[]>('/api/procedures/most_frequent_tramits')
      .then((res) => res.data);
  }

  public static async getInstitutions() {
    return Axios.get('/api/institutions')
      .then((res) => res.data);
  }

  public static async getFieldsByInstitution(id: number) {
    return Axios.get(`/api/fields/fields_by_institution?institution_id=${id}`)
      .then((res) => res.data);
  }

  public static async getIndividualsByMainFieldTramit(id: number) {
    return Axios.get(`/api//individuals/individuals_by_main_field_tramit?field_id=${id}`)
      .then((res) => res.data);
  }

  public static async addDeadlineToProcedures(deadline: any) {
    return Axios.post('api/procedure_deadlines/', deadline)
      .then((res) => res);
  }

  public static async updateDeadlineProcedures(deadlineId :number, deadline: any) {
    return Axios.put(`api/procedure_deadlines/${deadlineId}`, deadline)
      .then((res) => res);
  }

  public static async tramitProcedure(procedureId :number, tramit: Tramit) {
    return Axios.post(`api/activity/${procedureId}/tramit`, tramit)
      .then((res) => res);
  }

  public static async getDocumentsByProcedure(procedures: number[]) {
    return Axios.post<ResponseDocuments>('api/activity/documents_to_sign', { procedure_ids: procedures })
      .then((res) => res.data);
  }

  public static async getDocumentsInPreparation(procedureId: number) {
    return Axios.get<ResponseDocumentsInPreparation>(`/api/procedures/upload_files/${procedureId}`)
      .then((res) => res.data);
  }

  public static async updateDocumentsInPreparation(procedures: any[], procedure_id: number) {
    return Axios.put(`/api/procedures/upload_file_concluded/${procedure_id}`, { procedures })
      .then((res) => res.data);
  }

  public static async getAttachedDocuments(id: number) {
    return Axios.get(`/api/procedures/destroy_documents?id=${id}`)
      .then((res) => res.data);
  }

  public static async getAttachedDocumentsInBulk(procedure_ids :number[]) {
    return Axios.get('api/procedures/destroy_documents_in_bulk/', { params: { procedure_ids } })
      .then((res) => res.data);
  }

  public static async removeDeadlineProcedures(procedureIds: number[], procedureDeadlineId: number | undefined) {
    return Axios.delete('/api/procedure_deadlines', { params: { procedure_ids: procedureIds, procedure_deadline_id: procedureDeadlineId } });
  }

  public static async getInitialPetitionData(id: number) {
    return Axios.get(`/api/pje/${id}/initial_petition_data`)
      .then((res) => res.data);
  }

  public static async sendInitialPetition(password: string, procedureId: number, documents: number[]) {
    return Axios.post('/api/pje/initial_petition', { password, procedure_id: procedureId, attach_document_ids: documents })
      .then((res) => res.data);
  }

  public static async verifyPetitionInPje(procedureId: number[]) {
    return Axios.get('/api/pje/check_process_has_petiton', { params: { procedure_ids: procedureId } })
      .then((res) => res.data);
  }

  public static async hasRegistrationInSpu(id: number) {
    return Axios.get(`/api/activity/spu/check_send_spu/${id}`)
      .then((res) => res.data);
  }

  public static async pjeDocuments(procedureId: number) {
    return Axios.get('/api/pje/documents', { params: { id: procedureId } })
      .then((res) => res.data);
  }

  public static async getIntermediatePetitionData(procedureIds: number) {
    return Axios.get(`api/pje/${procedureIds}/intermediate_petition_data`)
      .then((res) => res.data);
  }

  public static async sendIntermediatePetitionData(password: string, procedure_id: number, documents: number[]) {
    return Axios.post('api/pje/intermediate_petition', { password, procedure_id, attach_document_ids: documents })
      .then((res) => res.data);
  }

  public static async getDocumentTemplates() {
    return Axios.get<DocumentTemplatesResponse>('/api/activity/document_templates')
      .then((res) => res.data);
  }

  public static async selectDocumentToSign() {
    return Axios.post('/api/activity/documents_selected_to_sign')
      .then((res) => res.data);
  }

  public static async getProcedureForceTramit(procedures: number[]) {
    return Axios.get('/api/activity/check_force_tramit', { params: { procedure_ids: procedures } })
      .then((res) => res.data);
  }

  public static async checkDocumentSigned(documents: CheckDocumentSignedRequest[]) {
    return Axios.get('/api/activity/signed', {
      params: {
        attachment_selections: documents,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    }).then((res) => res.data);
  }

  public static async deleteDocument(procedureId: number, file: AttachedDocument) {
    return Axios.delete(`/api/procedures/${procedureId}/destroy_documents?files[]=${file.file_class}, ${file.file_id}`);
  }

  public static async deleteDocumentInBulk(procedureIds: string, file: AttachedDocument) {
    return Axios.delete(
      `/api/procedures/destroy_documents_in_bulk?file_name=${file.file_name}&file_class=${file.file_class}${procedureIds}`,
    );
  }

  public static async downloadDocument(file: AttachedDocument) {
    return Axios.get(`/api/procedures/download_document?files[]=${file.file_class}, ${file.file_id}`, {
      responseType: 'blob',
    });
  }

  public static async getCategoryInProcess(id: number) {
    return Axios.get(`/api/process_categories?id=${id}`)
      .then((res) => res.data);
  }

  public static createCategoryInProcess(
    id: number,
    category: string,
    keyword: string,
    final_process_category_id?: number,
  ) {
    return Axios.post('/api/process_categories', {
      procedure_id: id,
      category,
      keyword,
      final_process_category_id,
    })
      .then((res) => res.data);
  }

  public static editCategoryInProcess(id: number, category: string, keyword: string) {
    return Axios.put('/api/process_categories', {
      id,
      category,
      keyword,
    })
      .then((res) => res.data);
  }

  public static updateCategoryInProcess(category_id: number, procedure_id: number) {
    return Axios.put('/api/process_categories/set_category', {
      id: category_id,
      procedure_id,
    })
      .then((res) => res.data);
  }

  public static async downloadDocuments(files: string, type?: string) {
    return Axios.post('/api/procedures/download_documents', {
      type,
      files,
    }, { responseType: 'blob' });
  }

  public static async attachFiles(data: AttachedDocumentResquet) {
    const formData = new FormData();
    formData.append('id', data.id.toString());
    formData.append('in_bulk', data.in_bulk ? 'true' : 'false');
    data.attach_documents.map((doc) => {
      formData.append('attach_documents[][archive]', doc.archive);
      formData.append('attach_documents[][status]', doc.status.toString());
      formData.append('attach_documents[][signed]', doc.signed.toString());
      formData.append('attach_documents[][proceeding_id]', doc.proceeding_id?.toString());
    });
    return Axios.post(
      '/api/procedures/attachments_generic',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ).then((res) => res.data);
  }

  public static async joinDocuments(data: AttachedDocumentResquet) {
    const formData = new FormData();
    formData.append('id', data.id.toString());
    formData.append('in_bulk', data.in_bulk ? 'true' : 'false');
    data.attach_documents.map((doc) => {
      formData.append('attach_documents[][archive]', doc.archive);
      formData.append('attach_documents[][status]', doc.status.toString());
      formData.append('attach_documents[][signed]', doc.signed.toString());
      formData.append('attach_documents[][proceeding_id]', doc.proceeding_id?.toString());
    });
    return Axios.post(
      '/api/procedures/perform_attachments',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ).then((res) => res.data);
  }

  public static async checkDocuments(id: number, signed: boolean) {
    return Axios.get<ResponseAttachedDocument>('/api/procedures/documents', { params: { id, signed } })
      .then((res) => res.data);
  }

  public static async getDocumentMethods(id: number) {
    return Axios.get<DocumentMethodResponse>(`/api/activity/document_templates/variables_for_document?procedure_id=${id}`)
      .then((res) => res.data);
  }

  public static async getInterestedParts(id: number) {
    return Axios.get(`api/procedures/subprocedure/${id}`)
      .then((res) => res);
  }

  public static async createSubProcedure(procedureId: number, copy_interested_parts: boolean, deadline: string) {
    return Axios.post(`/api/procedures/create_subprocedure/${procedureId}`, { copy_interested_parts, deadline })
      .then((res) => res.data);
  }

  public static async checkSignedUrl(url: string) {
    return Axios.get(url)
      .then((res) => res);
  }

  public static async finishSignerProgress(data: FinishSignerProgressRequest) {
    return Axios.post('/api/activity/attachment_selections', data)
      .then((res) => res.data);
  }

  public static async checkDocumentUrl(url: string) {
    return Axios.get(`/api/activity/check_document?path=${url}`)
      .then((res) => res.data);
  }

  public static async getVariablesToSign(procedures: GetVariablesToSignRequest) {
    return Axios.post('/api/activity/sign_attachement_variables', procedures)
      .then((res) => res.data);
  }

  public static async getTimeline(procedureId: number) {
    return Axios.get(`/api/procedures/timeline/${procedureId}`)
      .then((res) => res.data);
  }

  public static async getProgress(progressId: number) {
    return Axios.get(`/api/progresses/activity_details?progress_id=${progressId}`)
      .then((res) => res.data);
  }

  public static async getCDAs(procedureId: number) {
    return Axios.get(`/api/procedures/cdas/${procedureId}`)
      .then((res) => res.data);
  }

  public static async getOwnershipRequests(id: number) {
    return Axios.get(`api/ownership_requests/${id}`)
      .then((res) => res.data);
  }

  public static async getOwnershipAlteration(id: number) {
    return Axios.get(`api/ownership_requests/alteration_search/${id}`)
      .then((res) => res.data);
  }

  public static async postAlterOwnership(id: number, type: string) {
    return Axios.post('api/ownership_requests/alter_ownership', { procedure_id: id, type })
      .then((res) => res);
  }

  public static async getDependenciesPaymentOrders(procedureId: number) {
    return Axios.get(`/api/activities/payment_orders/dependencies/${procedureId}`)
      .then((res) => res.data);
  }

  public static async sendPaymentOrders(procedureId: number, deadline: string, paymentOrder: any) {
    return Axios.post('/api/activities/payment_orders', {
      procedure_id: procedureId,
      deadline,
      payment_order: paymentOrder,
    })
      .then((res) => res);
  }

  public static async sendPaymentRequests(procedureId: number, requestCalculations: any) {
    return Axios.post('/api/activities/request_calculations/', {
      procedure_id: procedureId,
      request_calculations: requestCalculations,
    })
      .then((res) => res);
  }

  public static async startSubProcedure(subprocedure: Object) {
    return Axios.post('/api/procedures/start_subprocedure', subprocedure)
      .then((res) => res.data);
  }

  public static async getDependenciesPaymentRequests(procedureId: number) {
    return Axios.get(`/api/activities/request_calculations/dependencies/${procedureId}`)
      .then((res) => res);
  }

  public static async createInterestedPart(procedureId: number, parts: any) {
    return Axios.post(
      '/api/activities/request_calculations/create_interested_part',
      {
        procedure_id: procedureId,
        request_calculation_part: parts,
      },
    )
      .then((res) => res);
  }

  public static async createInterestedPartOrder(procedureId: number, parts: any) {
    return Axios.post(
      '/api/activities/payment_orders/create_interested_part',
      {
        procedure_id: procedureId,
        payment_order_part: parts,
      },
    )
      .then((res) => res);
  }

  public static async getDocumentTemplate(documentId: number, procedureId :number) {
    return Axios.get(
      'api/activity/document_templates/document_template_selected/',
      {
        params: {
          id: documentId,
          procedure_id: procedureId,
        },
      },
    ).then((res) => res.data);
  }

  public static async getDocumentModel(id: number) {
    return Axios.get(`api/activity/document_templates/${id}`).then((res) => res.data);
  }

  public static async prepareDocument(title: string, content: string, type_document: number, procedure_ids: number[], in_bulk: boolean, proceeding_id: number) {
    return Axios.post('api/text_editors', {
      title,
      content,
      type_document,
      procedure_ids,
      in_bulk,
      proceeding_id,
    })
      .then((res) => res.data);
  }

  public static async saveModel(title: string, content: string) {
    return Axios.post('api/activity/document_templates', {
      name: title,
      content,
    })
      .then((res) => res.data);
  }

  public static async getProcedureCollectionList(query?: string) {
    return Axios.get<ResponseProcedure>(`api/procedures/search${query}`)
      .then((res) => res.data);
  }

  public static async fowardToIpm(procedureId: number, description: Description, archives: any[]) {
    return Axios.post('api/ipm', { procedure_id: procedureId, description, archives });
  }

  public static async getSamePartInfos(id: number) {
    return Axios.get<SamePartData>(`api/procedures/lis_pendensable/${id}`)
      .then((res) => res.data);
  }

  public static async updateSamePart(id: number, lis_pendens: boolean, procedure_id: number, other_procedure_id: number) {
    return Axios.put(`api/procedures/lis_pendens?lis_pendens_process_id=${id}&lis_pendens=${lis_pendens}&id=${procedure_id}&other_procedure_id=${other_procedure_id}`)
      .then((res) => res.data);
  }

  public static async wasListedByFlux(box: string, query: string) {
    return Axios.put(`api/procedures/was_listed_by_flux?box=${box}${query}`);
  }

  public static async wasSeenUpdate(procedure_id: string, wasSeen: Boolean) {
    return Axios.put(`/api/procedures/was_seen?id=${procedure_id}`, { was_seen: wasSeen });
  }

  public static async deleteDocumentTemplate(templateId: number) {
    return Axios.delete(`/api/activity/document_templates/${templateId}`);
  }

  public static async updateDocumentTemplate(name: string, content: string, id: number) {
    return Axios.put(`api/activity/document_templates/${id}`, {
      name,
      content,
    })
      .then((res) => res.data);
  }

  public static async getSpuInformations() {
    return Axios.get('api/activity/official_letter_spu_informations').then((res) => res.data);
  }

  public static async getSpuFields(code: any) {
    return Axios.get(`api/activity/official_letter_spu_informations/target_sectors?code=${code}`).then((res) => res.data);
  }

  public static async getSpuCities(code: any) {
    return Axios.get(`api/activity/official_letter_spu_informations/cities?code=${code}`).then((res) => res.data);
  }

  public static async getSpuNeighborhoods(code: any) {
    return Axios.get(`api/activity/official_letter_spu_informations/neighborhoods?code=${code}`).then((res) => res.data);
  }

  public static async getSpu(id: number) {
    return Axios.get(`api/activity/official_letter_spu_informations/${id}`).then((res) => res.data);
  }

  public static async sendToSpu(procedure_id: number, spu: any, documents: AttachedDocument[]) {
    return Axios.post('/api/activity/official_letter_spu_informations', { procedure_id, spu, documents })
      .then((res) => res);
  }

  public static async postRegistrationSpu(spu: any) {
    return Axios.post('/api/activity/official_letter_spu_informations/registration', spu)
      .then((res) => res);
  }

  public static async setSendToSpu(documentId: number, className: string) {
    return Axios.put('api/procedures/set_send_to_spu_documents', { id: documentId, class_name: className })
      .then((res) => res.data);
  }

  public static async getSpuOriginNumber(id: number) {
    return Axios.get(`api/activity/official_letters/show?procedure_id=${id}`).then((res) => res.data);
  }

  public static async getUnarchivePermission(ids: number[]) {
    return Axios.get('api/procedures/can_unarchive', { params: { procedure_ids: ids } }).then((res) => res.data);
  }

  public static async postUnarchive(id: number, observation: string) {
    return Axios.post(`api/procedures/unarchive/${id}?observation=${observation}`).then((res) => res.data);
  }

  public static async emitExtract(inscriptions: string) {
    return Axios.get(`api/procedures/emit_extract?${inscriptions}`).then((res) => res.data);
  }

  public static async emitCdas(inscriptions: number[], procedure_id: number, attach?: boolean) {
    return Axios.post('api/procedures/emit_cdas', {
      inscriptions,
      procedure_id,
      attach,
    }, { responseType: 'blob' }).then((res) => res.data);
  }

  public static async inscriptionToResend(inscriptions: number[]) {
    return Axios.get<ResponseResendRelease[]>(
      'api/procedures/inscriptions_to_resend',
      { params: { inscriptions } },
    ).then((res) => res.data);
  }

  public static async updateDebtBalance(id: number, debts: any) {
    return Axios.put('api/procedures/debt_balance', { id, debts }).then((res) => res.data);
  }

  public static async postAttachExtract(id: number) {
    return Axios.post(`api/procedures/attach_extract?id=${id}`).then((res) => res.data);
  }

  public static async getNewProurmaDeedData() {
    return Axios.get('api/prourma_deeds/new')
      .then((res) => res.data);
  }

  public static async createProurmaDeed(procedure: any) {
    return Axios.post('api/prourma_deeds', procedure)
      .then((res) => res.data);
  }

  public static async NewNote(note: string, individual_id: number, hide: boolean, procedure_id: any, procedure_ids: any) {
    return Axios.get<Note>('api/procedures/notes/new', {
      params: {
        note, individual_id, private: hide, procedure_id, procedure_ids,
      },
    }).then((res) => res.data);
  }

  public static async getTimelineNote(procedure_id: number) {
    return Axios.get<Note[]>(`api/procedures/notes/timeline?procedure_id=${procedure_id}`).then((res) => res.data);
  }

  public static async putVisibility(note_id: number, hide: boolean) {
    return Axios.put<Note>(`api/procedures/notes/visibility?note_id=${note_id}&private=${hide}`).then((res) => res.data);
  }

  public static async deleteNote(note_id: number, procedure_id: number, in_bulk: boolean) {
    return Axios.delete<Note>(`api/procedures/notes/delete?note_id=${note_id}&procedure_id=${procedure_id}&in_bulk=${in_bulk}`).then((res) => res.data);
  }

  public static async updateNote(note_id: number, description: string, in_bulk: boolean, procedure_id: number) {
    return Axios.put<Note>('api/procedures/notes/edit', {
      note_id, note: description, in_bulk, procedure_id,
    }).then((res) => res.data);
  }

  public static async getNewProcedureIntimationData() {
    return Axios.get('api/intimations/new')
      .then((res) => res.data);
  }

  public static async createProcedureIntimation(procedure: any) {
    return Axios.post('api/intimations', procedure)
      .then((res) => res.data);
  }

  public static async getNewSuspensionCancellationRequestData() {
    return Axios.get('api/suspension_cancellation_requests/new')
      .then((res) => res.data);
  }

  public static async createSuspensionCancellationRequest(data: any, flux_id: number | undefined) {
    return Axios.post('api/suspension_cancellation_requests', { suspension_cancellation_request: data, flux_id })
      .then((res) => res.data);
  }

  public static async getContributors(value: string) {
    return Axios.get(`api/suspension_cancellation_requests/get_contributors?search=${value}`)
      .then((res) => res.data);
  }

  public static async getInscriptions(data: any) {
    return Axios.get('api/suspension_cancellation_requests/get_inscriptions', { params: { data } })
      .then((res) => res.data);
  }

  public static async requestChangeFlux(procedure: number, flux_id: number, observation?: string) {
    return Axios.post('api/flux_histories/change_flux', { procedure, flux_id, observation })
      .then((res) => res);
  }

  public static async getProcedure(procedure_id: number) {
    return Axios.get(`api/procedures/${procedure_id}`)
      .then((res) => res.data);
  }

  public static async verifySignedArchive(data: any) {
    const formData = new FormData();
    formData.append('file', data);
    return Axios.post(
      '/api/procedures/verify_signed_archive',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ).then((res) => res.data);
  }

  public static async getDeadlineReason(procedureId: number, box: string) {
    return Axios.get('/api/procedure_deadlines/new', { params: { procedure_id: procedureId, box } })
      .then((res) => res.data);
  }

  public static async canViewDeadline(procedureId: number, box: string) {
    return Axios.get('/api/procedure_deadlines/check_same_area', { params: { procedure_id: procedureId, box } })
      .then((res) => res.data);
  }

  public static async canViewSupensionCancellationTramitFunction(procedureId: number) {
    return Axios.get(`/api/procedures/check_suspension_cancellation_permission_tramit/${procedureId}`)
      .then((res) => res.data);
  }

  public static async canViewSupensionCancellationRequestFunction(procedureId: number) {
    return Axios.get(`/api/procedures/check_suspension_cancellation_permission/${procedureId}`)
      .then((res) => res.data);
  }

  public static async getShowProcedurePje(id: number) {
    return Axios.get<ConsultProcedure>(`/api/pje/consult_procedure/${id}`).then((res) => res.data);
  }

  public static async updateProcedurePje(id: number) {
    return Axios.get<ConsultProcedure>(`/api/pje/update_procedure/${id}`).then((res) => res.data);
  }

  public static async downloadDocumentsPje(procedure_id: number, documents: number[]) {
    return Axios.get('/api/pje/download_documents/', {
      responseType: 'blob',
      params: { procedure_id, documents },
    });
  }

  public static async requestToLegalAction(inscriptionIds?: number[], quantity?: number) {
    return Axios.post('api/inscriptions/request_to_legal_action', { inscription_ids: inscriptionIds })
      .then((res) => res.data);
  }

  public static async sendToLegalAction(inscriptionIds?: number[], quantity?: number) {
    return Axios.post('api/inscriptions/send_to_legal_action', { inscription_ids: inscriptionIds })
      .then((res) => res.data);
  }

  public static async postAttachProcedure(main_procedure_id: number, procedure_id: number) {
    return Axios.post('api/attached_procedures', { main_procedure_id, procedure_id })
      .then((res) => res.data);
  }

  public static async deleteAttachProcedure(child_procedure_id: number) {
    return Axios.delete(`api/attached_procedures/${child_procedure_id}`)
      .then((res) => res.data);
  }

  public static async getDistributeParams() {
    return Axios.get('/api/process_distributions/new')
      .then((res) => res.data);
  }

  public static async getCurrentUserDistributeParams(id: number) {
    return Axios.get('/api/process_distributions/current_user_distribute_params', { params: { id } })
      .then((res) => res.data);
  }

  public static async getCollaboratorsList(unit_field_id: [], job_titles: []) {
    return Axios.get('/api/process_distributions/individuals', {
      params: { unit_field_id, job_titles },
    }).then((res) => res.data);
  }

  public static async createManualDistribution(procedure_id: any, process_distribution: any, shared_ids: any) {
    return Axios.post('/api/process_distributions', { procedure_id, process_distribution, shared_ids }).then((res) => res.data);
  }

  public static async createAutomaticDistribution(process_distribution: any, shared_ids: any) {
    return Axios.post('/api/process_distributions/in_bulk', { process_distribution, shared_ids }).then((res) => res.data);
  }

  public static async automaticDistribuitionRedisStatus(key: string) {
    return Axios.get(`/api/redis?key=${key}`).then((res) => res.data);
  }

  public static async communicationContent(password: string, notice_id: number, procedure_id: number) {
    return Axios.post('/api/pje/communication_content/', { password, notice_id, procedure_id })
      .then((res) => res.data);
  }

  public static async getAttorneys(flux_id: number) {
    return Axios.get('/api/procedure_deadlines/attorney', { params: { flux_id } })
      .then((res) => res.data.attorneys);
  }

  public static async getCourts() {
    return Axios.get('/api/procedure_deadlines/courts')
      .then((res) => res.data.courts);
  }

  public static async shareProcedure(procedure_id :number, individual_ids: number[], observation?: string) {
    return Axios.post('api/shared_procedures/share', {
      procedure_id,
      individual_ids,
      observation,
    })
      .then((res) => res);
  }

  public static async removeSharedPeople(procedureId: number, individualId: number) {
    return Axios.delete('/api/shared_procedures/delete', {
      params: { procedure_id: procedureId, individual_id: individualId },
    });
  }

  public static async notifyRemoveSharedPeople(procedure_id: number, individual_id: number) {
    return Axios.post('/api/notifications/remove_shared', { procedure_id, individual_id });
  }

  public static async markAsSeen(sharedId: number, individualId: number) {
    return Axios.put(`/api/shared_procedures/${sharedId}`, { individual_id: individualId });
  }

  public static async getDocumentsToCosignByProcedure(procedures: number[]) {
    return Axios.post<ResponseDocuments>('api/activity/documents_to_cosign', { procedure_ids: procedures })
      .then((res) => res.data);
  }

  public static async getAllCitationIntimation(query?: string) {
    return Axios.get(`/api/citation_intimations${query || ''}`).then((res) => res.data);
  }

  public static async getCitationIntimation(id?: number) {
    return Axios.get('/api/citation_intimations/documents/', {
      params: { citation_intimation_id: id },
    }).then((res) => res.data);
  }

  public static async tramitCitationIntimation(citation_intimation_id: number, area_field_id: number) {
    return Axios.put(`/api/citation_intimations/tramit/${citation_intimation_id}`, { area_field_id })
      .then((res) => res);
  }

  public static async attachFilesCitationIntimation(data: AttachedDocumentResquet) {
    const formData = new FormData();
    formData.append('id', data.id.toString());
    formData.append('in_bulk', data.in_bulk ? 'true' : 'false');
    data.attach_documents.map((doc) => {
      formData.append('attach_documents[][archive]', doc.archive);
      formData.append('attach_documents[][status]', doc.status.toString());
      formData.append('attach_documents[][signed]', doc.signed.toString());
    });
    return Axios.post(
      '/api/citation_intimations/attachments',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ).then((res) => res.data);
  }

  public static async getDocumentTypes() {
    return Axios.get('/api/document_types/').then((res) => res.data.result);
  }

  public static async getProceedings() {
    return Axios.get('/api/proceedings/').then((res) => res.data);
  }

  public static async createDocumentType(name: string) {
    return Axios.post('/api/document_types/', { name }).then((res) => res.data);
  }

  public static async updateSeenNotSeenProcedures(procedures_ids: number[]) {
    return Axios.patch('/api/procedures/update_was_seen', { procedure_ids: procedures_ids })
      .then((res) => res);
  }

  public static async verifiySpuRegistration(procedure_id: number) {
    return Axios.post('/api/procedures/verify_spu_registration', { procedure_id })
      .then((res) => res.data);
  }

  public static async tramitAndSuspendOrCancelInscriptions(procedure_id: number) {
    return Axios.post('/api/activity/suspension_cancellation_request_tramit', { procedure_id })
      .then((res) => res.data);
  }

  public static async suspendOrCancelInscriptions(id: number) {
    return Axios.post('/api/procedures/suspension_cancellation_request', { id })
      .then((res) => res.data);
  }

  public static async getCheckDocumentsSigned(
    path_file: string,
    procedure_id: string,
    progress_id: string,
    control_code: string,
    class_name: string,
    id_file: string,
  ) {
    return Axios.post('/api/activity/attachment_selections/check_a3_documents', {
      path_file, procedure_id, progress_id, control_code, class_name, id_file,
    })
      .then((resp) => resp.data);
  }

  public static async convertToPdf(id: number, file_name: string, path: string) {
    return Axios.post('/api/procedures/convert_pdf', { id, file_name, path })
      .then((res) => res.data);
  }

  public static async getIntermediatePetitionSubmissions(ids: number[]) {
    return Axios.post('/api/pje/intermediate_petition_submissions', { procedure_ids: ids })
      .then((resp) => resp.data);
  }

  public static async tramitOnSpu(procedure_id: number, spu: any) {
    return Axios.post('/api/activity/official_letter_spu_informations/spu_tramit', { procedure_id, spu })
      .then((res) => res.data);
  }
}
