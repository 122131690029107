import { useContext, useEffect, useState } from 'react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import {
  FaBullhorn, FaCalendarAlt, FaFileAlt, FaPaperclip,
} from 'react-icons/fa';
import { t } from 'i18next';
import { Table } from '../Table';
import styles from './styles.module.scss';
import pjeLogo from '../../assets/pje_logo.png';
import logoAnimation from '../../assets/lotties/loading.json';
import ProcedureBoxService from '../../services/procedureBoxService';
import { QueryContext } from '../../context/queryContext';

type SnapshotObject = {
  movimentation: String;
  movimentation_date: String;
  protocol: String;
  receipt_document: String[];
  sent_documents: String[];
};

type IntermediaryPetitionConfirmationProps = {
  procedures: number[];
};

export function IntermediaryPetitionConfirmation(props: IntermediaryPetitionConfirmationProps) {
  const { setSentProcedures } = useContext(QueryContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<SnapshotObject[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (props.procedures.length > 0) {
      ProcedureBoxService.getIntermediatePetitionSubmissions(props.procedures).then(((resp) => {
        if (resp.length > 0) {
          setData(resp.map((res: any) => res.snapshot));
        }
      })).catch((err) => {
        setErrorMessage(`(${err.status}) ${err.data}`);
      }).finally(() => {
        setLoading(false);
        setSentProcedures([]);
      });
    }
  }, [props.procedures]);

  const conditionalTableContent = () => {
    if (data.length > 0 && !loading && errorMessage === '') {
      return (
        <>
          <div className={styles.header}>
            <p>{t('procedureBox.pje.protocolDate', { date: data[0].movimentation_date })}</p>
            <span>{t('procedureBox.pje.info')} <a href="https://pje.tjce.jus.br" target="_blank">{t('procedureBox.pje.link')}</a></span>
          </div>
          <Table>
            <thead>
              <th>{t('procedureBox.pje.table.protocol')}</th>
              <th>{t('procedureBox.pje.table.movimentation')}</th>
              <th>{t('procedureBox.pje.table.movimentationDate')}</th>
              <th>{t('procedureBox.pje.table.documents')}</th>
            </thead>
              {
                data.map((petition: SnapshotObject) => (
                  <tbody data-cy={'cypress-tbody-table-intermediary-petition'}>
                    <td><FaFileAlt />{petition.protocol}</td>
                    <td><FaBullhorn />{petition.movimentation}</td>
                    <td><FaCalendarAlt />{petition.movimentation_date}</td>
                    <td><FaPaperclip />{petition.sent_documents.length} {t('procedureBox.pje.file')}</td>
                  </tbody>
                ))
              }
          </Table>
        </>
      );
    }

    if (errorMessage !== '' && !loading) {
      return (
        <div className={styles.error}>
          <p>{t('procedureBox.pje.table.error')}</p>
          <span>{errorMessage}</span>
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={pjeLogo} alt='pje_logo'/>
      </div>
      {
        loading ? (
          <Player
            loop={true}
            autoplay
            keepLastFrame={true}
            src={logoAnimation}
            speed={2}
            className={styles.loading}
          >
            <Controls visible={false} />
          </Player>
        ) : conditionalTableContent()
      }
    </div>
  );
}
