import { FaBell, FaSun, FaMoon } from 'react-icons/fa';
import { Badge, Menu } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { RoundButton } from '../RoundButton';
import styles from './styles.module.scss';
import { Header } from './Header';
import GeneralService from '../../services/generalService';
import { Item } from './Item';
import { NotificationType } from '../../@types/home';
import { Footer } from './Footer';
import { HomeContext } from '../../context/homeContext';
import { ThemeContext } from '../../context/themeContext';

export function Notifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [items, setItems] = useState<NotificationType[]>([]);
  const [reload, setReload] = useState(Math.random());
  const { notificationsCount, setNotificationsCount } = useContext(HomeContext);
  const { themeMode, setThemeMode } = useContext(ThemeContext);

  const readAll = () => {
    GeneralService.updateNotifications().then(() => {
      GeneralService.getNotifications().then((res) => {
        setItems(res.data);
        setNotificationsCount(res.data.filter((item: NotificationType) => !item.read).length);
        setReload(Math.random());
      });
    });
  };

  const renderIcon = () => {
    return (
      <Badge
        className={styles.badge}
        badgeContent={notificationsCount}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: 'var(--danger4)',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <FaBell/>
      </Badge>
    );
  };

  const handleChangeTheme = () => {
    setThemeMode(themeMode === 'dark' ? 'light' : 'dark');
  };

  useEffect(() => {
    GeneralService.getNotifications().then((res) => {
      setItems(res.data);
      setNotificationsCount(res.data.filter((item: NotificationType) => !item.read).length);
    });
  }, []);
  return (
    <>
      <RoundButton
        icon={ themeMode === 'dark' ? <FaSun /> : <FaMoon /> }
        className={'button'}
        tooltip={ t(`home.theme.${themeMode === 'dark' ? 'light' : 'dark'}`)}
        onClick={handleChangeTheme}
      />
      <RoundButton
        icon={ renderIcon() }
        className='button'
        tooltip={t('home.notifications.title')}
        onClick={(e) => { setAnchorEl(e.currentTarget); }}
      />
      <Menu
        id="notifications-menu"
        open={!!anchorEl}
        onClose={() => { setAnchorEl(null); }}
        anchorEl={anchorEl}
        classes={{ list: styles.menuMUI }}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: 'var(--neutral0);',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'var(--neutral0)',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className={styles.container} key={reload}>
          <Header readAll={readAll} />
          <div className={styles.items}>
            {items.map((i) => (
              <Item notification={i} />
            ))}
            <Footer />
          </div>
        </div>
      </Menu>
    </>
  );
}
