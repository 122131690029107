import { useContext, useState } from 'react';
import { t } from 'i18next';
import { AutoCompleteFilter } from '../AutoCompleteFilter';
import styles from './styles.module.scss';
import { Button } from '../Button';
import ActionsService from '../../services/actionsService';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';

type AssociateCDAProps = {
  setLoaded: (condition: boolean) => void;
};

export default function AssociateCDA(props: AssociateCDAProps) {
  const [optionSelected, setOptionsSelected] = useState<number[]>([]);
  const { proceduresSeleted, setModal, addToasts } = useContext(ProcedureActionsContext);

  const handleAssociateCda = () => {
    ActionsService.associateInscriptionsToProcedure(proceduresSeleted[0].id, optionSelected).then((resp) => {
      if (resp.status === 200) {
        addToasts({
          type: 'success',
          text: resp.message,
        });
        setModal(undefined);
        props.setLoaded(false);
      }
    }).catch((err) => {
      const errorMessage = `${err.data.error} ${err.data.exception}`;
      addToasts({
        type: 'error',
        text: errorMessage,
      });
    });
  };

  return (
    <div className={styles.container}>
      <AutoCompleteFilter
        label={t('procedureBox.processExtract.cdas.associate.title')}
        placeholder={t('procedureBox.processExtract.cdas.associate.placeholder')}
        handleSelectedItems={(event: number[]) => setOptionsSelected(event)}
      />
      <div className={styles.button}>
        <Button
          dataCy={'cypress-submit-button-associate-cda'}
          title={t('procedureBox.processExtract.cdas.associate.button')}
          disabled={optionSelected.length === 0}
          size={'small'}
          onClick={handleAssociateCda}
          buttonType={'primary'}
          round
        />
      </div>
    </div>
  );
}
