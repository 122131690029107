import { useContext } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Action, MultipleActionStep } from '../../../@types/backgroundActions';
import { Button } from '../../Button';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Procedure } from '../../../@types/model';

type SubmitProps = {
  closeModal: () => void;
  procedure?: Procedure;
  selectedActions: any[];
};

type CheckActionsList = {
  stepName: string;
  error: boolean;
};

export function Submit(props: SubmitProps) {
  const { proceduresSeleted, setAlert, handleMultipleActions } = useContext(ProcedureActionsContext);
  const checkActionsPositon = (): CheckActionsList => {
    const selectedActionsSize = props.selectedActions.length;
    const checkTramit = props.selectedActions.find((i) => i.id === 'tramit');
    if (checkTramit) {
      if (props.selectedActions.indexOf(checkTramit) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkTramit.label),
          error: true,
        };
      }
    }
    const checkArchive = props.selectedActions.find((i) => i.id === 'archive');
    if (checkArchive) {
      if (props.selectedActions.indexOf(checkArchive) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkArchive.label),
          error: true,
        };
      }
    }
    const checkForceTramit = props.selectedActions.find((i) => i.id === 'forceTramit');
    if (checkForceTramit) {
      if (props.selectedActions.indexOf(checkForceTramit) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkForceTramit.label),
          error: true,
        };
      }
    }
    const checkUnarchive = props.selectedActions.find((i) => i.id === 'unArchive');
    if (checkUnarchive) {
      if (props.selectedActions.indexOf(checkUnarchive) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkUnarchive.label),
          error: true,
        };
      }
    }
    return {
      stepName: '',
      error: false,
    };
  };

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.multipleActions.modal.titleManyProcedures'
          values={{ number: proceduresSeleted.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.multipleActions.modal.title'
        values={{ number: proceduresSeleted[0].process_number }}
      />
    );
  };

  const renderText = () => {
    const result = props.selectedActions.map((a) => t(a.label));
    return (
      <Trans
        i18nKey='procedureBox.actions.multipleActions.modal.text'
        values={{ actions: result.join(' > ') }}
      />
    );
  };

  const handleConfirm = () => {
    const data: Action[] = props.selectedActions.map((i) => {
      const nextAction = props.selectedActions[props.selectedActions.indexOf(i) + 1];
      return {
        name: i.id,
        nextStep: nextAction ? nextAction.id : '',
      };
    });
    const currentStep = props.selectedActions[0].id;
    const multiAction: MultipleActionStep = {
      data,
      currentStep,
    };
    setAlert(undefined);
    props.closeModal();
    handleMultipleActions(multiAction, currentStep);
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleConfirm(),
      title: renderTitle(),
      text: renderText(),
    });
  };

  const showAlertError = (action: string) => {
    setAlert({
      visible: true,
      type: 'error',
      title: t('procedureBox.actions.multipleActions.modal.error'),
      text: <Trans
        i18nKey={'procedureBox.actions.multipleActions.modal.errorMsg'}
        components={{ bold: <strong /> }}
        values={{ action }}
      />,
    });
  };

  const handleSubmit = () => {
    const verify = checkActionsPositon();
    if (verify.error) {
      showAlertError(verify.stepName);
    } else {
      showAlert();
    }
  };

  return (
    <Button
      title={t('procedureBox.actions.multipleActions.submit')}
      textCenter
      round
      buttonType='primary'
      size='flat'
      disabled={props.selectedActions.length === 0}
      onClick={() => handleSubmit()}
      dataCy={'multiple-actions-submit'}
    />
  );
}
