import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { BoxIcon } from '../../BoxIcon';
import { Alert } from '../Alert';

export function TramitSpuAlert() {
  const { batches } = useContext(BackgroundActionsContext);
  return (
    <div>
      { batches?.filter((el) => el.status !== 'closed' && el.type === 'tramitSpu').map((batch) => (
        <Alert
          icon={<BoxIcon text={t('procedureBox.actions.spu.title')} />}
          textIcon
          title={t('procedureBox.actions.spu.tramitOfficialLetter')}
          text={<Trans i18nKey='procedureBox.actions.tramitSpu.modal.alertLoading.loadingMessage' />}
          batch={batch}
        />
      ))}
    </div>
  );
}
