import { t } from 'i18next';
import { Tooltip } from '../../../../../components/Tooltip';
import styles from './styles.module.scss';

type DocumentRateProps = {
  rate: number;
};

export function DocumentRate(props: DocumentRateProps) {
  return (
    <div className={ styles.container }>
      <Tooltip title={t('documentSearch.relevance')}>
        <div className={ styles.stars } style={{ '--rating': props.rate } as React.CSSProperties}>
          <div className={ styles.bg }>⭐⭐⭐⭐⭐</div>
        </div>
      </Tooltip>
    </div>
  );
}
