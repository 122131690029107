import { FaChevronCircleUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { RoundButton } from '../../../../../components/RoundButton';

type PaginationProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  topPage?: boolean;
};

export function Pagination(props: PaginationProps) {
  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const decreasePage = () => {
    if (1 * (props.page - 1) > 0) {
      props.setPage(props.page - 1);
    }
  };

  const increasePage = () => {
    if ((props.page + 1) * 20 < props.total + 20) {
      props.setPage(props.page + 1);
    }
  };

  return (
    <div className={ styles.container }>
      {props.topPage && (
        <div className={styles.topPage} onClick={scrollToTop}>
          {t('documentSearch.documents.back_top')} <FaChevronCircleUp />
        </div>
      )}
      <b>
       {(20 * props.page) - 19} - {20 * props.page > props.total ? props.total : 20 * props.page} de {props.total}
      </b>
      <span>
        <RoundButton
          className={ styles.button }
          icon={<FaChevronLeft />}
          onClick={() => { decreasePage(); }}
          size='small'
        />
      </span>
      <span>
        <RoundButton
          className={ styles.button }
          icon={<FaChevronRight />}
          onClick={() => { increasePage(); }}
          size='small'
        />
      </span>
    </div>
  );
}
