import { useEffect, useState } from 'react';
import {
  FaSearch,
  FaSlidersH,
  FaTimes,
} from 'react-icons/fa';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchButton } from './SearchButton';
import { FilterButton } from './FilterButton';
import styles from './styles.module.scss';
import { FilterAdvanced } from './FilterAdvanced';
import SearchService from '../../../../services/searchService';
import { ItemsResponse, ResponseDocumentItemType } from '../../../../@types/searchDocument';
import { SearchOperators } from './SearchOperators';
import { GroupFilter } from '../../../../components/SearchBar/FilterAdvanced/GroupFilter';
import { SelectFilters } from './SelectFilters';
import { Button } from '../../../../components/Button';

type SearchBarDocumentProps = {
  setDocuments: React.Dispatch<React.SetStateAction<ResponseDocumentItemType | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  page: number;
  searchActive: boolean;
};

export function SearchBarDocument(props: SearchBarDocumentProps) {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();

  const [searchInput, setSearchInput] = useState('');
  const [items, setItems] = useState<ItemsResponse | undefined>();
  const [isOpenFilters, setOpenFilters] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [onError, setOnError] = useState(false);
  const [selected, setSelected] = useState({});

  const handleSelected = (key: string, value: string | undefined) => {
    setSelected({
      ...selected,
      [key]: value,
    });
  };

  const handleSearch = async (data?: any) => {
    const queryString = Object.entries(selected)
      .filter(([_, v]) => v !== undefined)
      .map(([k, v]) => `filter[${k}]=${v}`)
      .join('&');

    if (props.query.trim()) {
      props.setLoading(true);
      await SearchService.documents(props.query.trim(), queryString, props.page).then((res) => {
        props.setDocuments(res);
        props.setLoading(false);
      });
    }
  };

  const handleCloseSearch = () => {
    setSearchInput('');
  };

  const handleFocused = () => {
    setOnFocus(true);
    setOnError(false);
  };

  useEffect(() => {
    handleSearch();
  }, [props.page]);

  useEffect(() => {
    SearchService.getFilters().then((res) => {
      setItems(res);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(() => { handleSearch(); props.setPage(1); })} data-cy={'procedure-collection-form-search'}>
      <div className={styles.containerSearch}>
        <div data-cy={'procedure-collection-form-border'} className={`${styles.searchBarContainer} ${onError ? styles.searchBarContainerError : onFocus ? styles.searchBarContainerFocused : ''}`} >
          <div className={styles.inputContainer}>
            <Controller
              control={control}
              name={'searchInput'}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  placeholder={t('documentSearch.searchBar.placeholder')}
                  onFocus={handleFocused}
                  onBlur={() => setOnFocus(false)}
                  onChange={(e) => {
                    props.setQuery(e.currentTarget.value);
                  }}
                  value={props.query}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      props.setPage(1);
                      handleSearch();
                    }
                  }}
                  data-cy={'procedure-collection-input-search'}
                />
              )}
            />
            { searchInput.length > 0 && (
              <button className={styles.closeSearchButton} onClick={() => handleCloseSearch()}>
                <FaTimes />
              </button>
            )}
          </div>
          <FilterButton icon={ <FaSlidersH />} onClick={ () => setOpenFilters(!isOpenFilters)} isOpen={isOpenFilters} />
          <SearchButton active={props.searchActive} onSearchClick={() => handleSearch()} icon={ <FaSearch />} />
        </div>
        <FilterAdvanced isOpen={isOpenFilters} closeFilter={() => setOpenFilters(!isOpenFilters)}>
          <SearchOperators query={props.query} setQuery={props.setQuery} />
          <GroupFilter subtitle={t('procedureBox.filterAdvanced.data')} >
            <SelectFilters items={items} handleSelected={handleSelected} />
          </GroupFilter>
          <div className={styles.consultButtons}>
            <Button
              title={t('documentSearch.clear')}
              textCenter
              round
              buttonType='default'
              size='flat'
              disabled={false}
              onClick={() => { window.location.reload(); }} />
            <Button
              title={t('documentSearch.filter')}
              textCenter
              round
              buttonType='primary'
              size='flat'
              disabled={false}
              onClick={handleSearch} />
          </div>
        </FilterAdvanced>
      </div>
    </form>
  );
}
