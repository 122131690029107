import { FaSignOutAlt, FaEdit, FaPenNib } from 'react-icons/fa';
import { Divider, Menu } from '@mui/material';
import { useContext, useState } from 'react';
import { t } from 'i18next';
import AuthService from '../../services/authService';
import A1Service from '../../services/a1Service';
import { EPGM_URL, EPGM_LOGIN_SCREEN } from '../../config/constants';
import { Avatar } from '../ProcedureList/Avatar';
import { CurrentUser } from '../../@types/currentUser';
import styles from './styles.module.scss';
import { HomeContext } from '../../context/homeContext';
import { ChangePassword } from './ChangePassword';
import { ChangePicture } from './ChangePicture';
import { ImportA1 } from './ImportA1';
import { renderColor } from '../../helpers/a1';
import { A1Validate } from '../../@types/a1';

export function Profile() {
  const { setModal, setAlert } = useContext(HomeContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const open = Boolean(anchorEl);
  const [a1Validate, setA1Validate] = useState<A1Validate | null>(null);

  const validateCert = () => {
    A1Service.validateCert().then((res) => {
      setA1Validate(res);
    }).catch(() => {
      setA1Validate(null);
    });
  };

  const renderDaysToExpired = () => {
    if (a1Validate === null || !a1Validate.validate_in_days) { return <></>; }

    const days = a1Validate.validate_in_days;
    const formatDays = days <= 0 ? (days * -1) : days;

    return (
      <span style={{ color: renderColor(days) }} className={styles.days}>
        {t(`general.modalImportA1.cert.${days <= 0 ? 'expired' : 'valid'}`, { days: formatDays })}
      </span>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    validateCert();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearUserSession = () => {
    localStorage.clear();
    document.body.classList.remove('darkModeTheme');
  };

  const loadCurrentUserLogout = async () => {
    await AuthService.logout();
    clearUserSession();
    window.location.href = '/';
  };

  const handleLogout = () => {
    if (EPGM_LOGIN_SCREEN) {
      clearUserSession();
      window.location.href = `${EPGM_URL}/usuarios/logout_agilis`;
    } else {
      loadCurrentUserLogout();
    }
    setAlert(undefined);
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleLogout(),
      title: t('general.logout.text'),
      cancelText: t('general.cancel'),
      confirmText: t('general.logout.label'),
    });
  };

  const openChangePassword = (e: any) => {
    setModal({
      visible: true,
      title: t('general.changePassword'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'medium',
      children: <ChangePassword closeModal={() => setModal(undefined)} />,
    });
    handleClose();
    e.stopPropagation();
  };

  const openImportA1 = (e: any) => {
    setModal({
      visible: true,
      title: t('general.importA1'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'small',
      children: <ImportA1 a1Validate={a1Validate} closeModal={() => setModal(undefined)} />,
    });
    handleClose();
    e.stopPropagation();
  };

  const openChangePicture = (e: any) => {
    setModal({
      visible: true,
      title: t('general.changePicture.title'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'medium',
      children: <ChangePicture closeModal={() => setModal(undefined)} />,
    });
    handleClose();
    e.stopPropagation();
  };

  return (
    <div
      className={styles.container}
      onClick={open ? handleClose : handleClick}
      id="button-avatar"
    >
      <Avatar
        name={ currentUser.name }
        color={currentUser.color}
        profile_picture={currentUser.profile_picture}
        size='medium'
        tooltip
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: 'var(--neutral0);',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'var(--neutral0)',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className={styles.header}>
          <Avatar
            name={ currentUser.name }
            color={currentUser.color}
            profile_picture={currentUser.profile_picture}
            onClick={(e) => openChangePicture(e)}
            size='large'
            tooltip
            badge
          />
          <div className={styles.info}>
            <span className={styles.name}>{currentUser.name}</span>
            <span className={styles.email}>{currentUser.email}</span>
          </div>
        </div>
        <Divider />
        <div className={styles.actions}>
          <div className={styles.changePassword} onClick={(e) => openImportA1(e)}>
            <FaPenNib fontSize="medium" />
            <span className={styles.text}>
              {t('general.importA1')}
            </span>
            { renderDaysToExpired() }
          </div>
          <div className={styles.changePassword} onClick={(e) => openChangePassword(e)}>
            <FaEdit fontSize="medium" />
            <span className={styles.text}>{t('general.changePassword')}</span>
          </div>
          <div
            className={styles.logout}
            onClick={showAlert}
            id="button-logout"
          >
            <FaSignOutAlt fontSize="medium" />
            <span className={styles.text}>{t('general.logout.label')}</span>
          </div>
        </div>
      </Menu>
    </div>
  );
}
