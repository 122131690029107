import { useState } from 'react';
import { getBarBorderRadius, getBarHeight } from '../../../helpers/barStyles';
import styles from './styles.module.scss';
import CustomTooltip from '../../CustomTooltip';

type BarItemProps = {
  title?: string;
  tooltip: string;
  color: string;
  hoverColor: string;
  width: number;
  percentage_number?: string,
};

export function Bar(props: BarItemProps) {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const fillerStyles = {
    height: getBarHeight(),
    width: props.width > 100 ? '100%' : `${props.width}%`,
    backgroundColor: `${props.color}`,
    borderTopRightRadius: getBarBorderRadius(),
    borderBottomRightRadius: getBarBorderRadius(),
  };

  const fillerStylesHover = {
    height: getBarHeight(),
    width: props.width > 100 ? '100%' : `${props.width}%`,
    backgroundColor: `${props.hoverColor}`,
    borderTopRightRadius: getBarBorderRadius(),
    borderBottomRightRadius: getBarBorderRadius(),
  };

  let mainStyle = {
    borderTopRightRadius: getBarBorderRadius(),
    borderBottomRightRadius: getBarBorderRadius(),
    display: 'flex',
  };

  if (!props.percentage_number) {
    mainStyle = {
      ...mainStyle,
      width: props.width > 0 ? '100%' : `${props.width}%`,
    } as any;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={ styles.container }>
      {props.percentage_number ? <div style={{ background: props.color }} className={ styles.square }></div> : <></>}
      {props.title && <span className={ styles.title }>{props.title}:</span>}
      <CustomTooltip
        followCursor
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        placement="right-start"
        title={ props.tooltip }>
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={ mainStyle }
        >
          {props.percentage_number ? <div className={styles.valueContainer}>
              <p className={styles.percentageStyle}>{props.percentage_number}%</p>
              <p className={styles.valueStyle}>{props.tooltip}</p>
            </div> : <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setOpen(!open)}
            style={ hover ? fillerStylesHover : fillerStyles}
          />}
        </div>
      </CustomTooltip>
    </div>
  );
}
